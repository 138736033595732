import { Component, OnInit, ViewChild } from '@angular/core';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { combineLatest, forkJoin, Observable } from 'rxjs';

import { reduce, map } from 'rxjs/operators';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import * as L from 'leaflet';
import { Map } from 'leaflet';

import { environment } from '../../environments/environment';
import { FirelineComponent } from '../fireline/fireline.component';

import { FireServiceService } from '../services/fire-service.service';

import { PipelinesService } from '../services/pipelines.service';
import { AusgrideService } from '../services/ausgride.service';
import { PreviousRouteService } from '../services/previous-route.service';
import { MapService } from '../services/map.service';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-editmap',
  templateUrl: './editmap.component.html',
  styleUrls: ['./editmap.component.css']
})
export class EditmapComponent implements OnInit {
  previousUrl: string;
  public saveTemplates: boolean = false;
  @ViewChild(FirelineComponent) private firelineChild: FirelineComponent;

  constructor(private mapParamsFormFG: FormBuilder,
    private commonService: CommonService,
    private mapService: MapService,
    private ngxService: NgxUiLoaderService,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private router: Router,
    private previousRouteService: PreviousRouteService,
    public _fireservice: FireServiceService,
    private _pipelines: PipelinesService,
    private _ausGrideservice: AusgrideService) { }

  fileToUpload: any = '';
  enableCreate: boolean = true;
  showMap: boolean = false;
  getMapParams: any = {};
  editMapInfoForm: FormGroup;

  clientData = JSON.parse(localStorage.getItem("client"));

  fromMymaps: any;
  //dropdown
  isCollapsed = false;
  defaultIncidentTypes: any;
  selectedIncidents: any;
  mapViewList: any = [];
  selectedMapView = [];
  mapTypeList: any = [];
  selectedMapType = [];
  mapName: any;
  mapId: any;
  mapDesc: any;
  showReportForm: boolean = false;
  mapAction: any;
  mapHeaderBg: any;
  fromTemplates: any;
  ngOnInit(): void {
    this.ngxService.start();
    this.editMapInfoForm = this.mapParamsFormFG.group({
      description: [""],
      mapName: ["", Validators.required],
      incidentTypes: [null, [Validators.required]],
      mapViewTypes: [null, [Validators.required]],
      mapType: [null, [Validators.required]],
      logo: new FormControl('', Validators.required),
      headerBg: ["#4a7d9e"],
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.fromTemplates = params['ft'];
    });

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      // alert(!isNaN(parseFloat(params.get("slugName"))));
      // Get Map Details
      if (!isNaN(parseFloat(params.get("slugName"))) == false) {
        // alert("slug");
        this.mapService.getSingleMapList(params.get("slugName")).subscribe((res) => {
          if (res) {
            // alert("suc")
            this.showMap = true;
            this.setEditMapFormDetails(res);
            this.getMapParams = {
              "incidentTypes": res.data_types,
              "mapType": res.meta.mapTypes,
              "logo": (res.meta.logo == "" ? environment.baseUrl + this.clientData.client.logo : res.meta.logo),
              "headerBg": res.meta.headerBg,
              "mapViewTypes": res.meta.mapViewTypes
            }
            this.ngxService.stop();
          } else {
            console.log("error in the response", res);
          }
        });
      } else {
        // alert("id");
        this.saveTemplates = true;
        this.mapService.getSingleTempList(params.get("slugName")).subscribe((res) => {
          if (res) {
            // alert("suc")
            this.showMap = true;
            this.setEditMapFormDetails(res);
            this.getMapParams = {
              "incidentTypes": res.data_types,
              "mapType": res.meta.mapTypes,
              "logo": (res.meta.logo == "" ? environment.baseUrl + this.clientData.client.logo : res.meta.logo),
              "headerBg": res.meta.headerBg,
              "mapViewTypes": res.meta.mapViewTypes
            }
            this.ngxService.stop();
          } else {
            console.log("error in the response", res);
          }
        });
      }
    });

    this.defaultIncidentTypes = this.clientData.data_types;

    this.mapViewList = [
      { value: "Terrain", label: 'Terrain' },
      { value: "Street", label: 'Street' },
      { value: "Satillite", label: 'Satillite' }
    ];

    this.mapTypeList = [
      { value: 'Google Map', label: 'Google Map', disabled: true },
      { value: 'MapBox', label: 'MapBox' },
      { value: 'Open Street Map', label: 'Open Street Map', disabled: true }
    ];
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.fileToUpload = event.target.result;
      }
    }
  }

  generateMap(): void {
    // console.log(this.editMapInfoForm.value);
  }

  onMapViewChange($event: any) {
    this.selectedMapView = $event;
  }

  setEditMapFormDetails(mapData) {
    this.mapName = mapData.name;
    this.mapId = mapData.slug;
    this.selectedMapView = mapData.meta.mapViewTypes;
    this.selectedMapType = mapData.meta.mapType;
    this.fileToUpload = (mapData.meta.logo == "" ? environment.baseUrl + this.clientData.client.logo : mapData.meta.logo);
    this.mapDesc = mapData.meta.description;
    this.mapHeaderBg = mapData.meta.headerBg;
    this.selectedIncidents = mapData.data_types;//.map((item: any) => { return item.name });

    let selectedTypes = [...this.selectedIncidents];
    if (selectedTypes.map((item: any) => { return item.slug }).indexOf('live-outages') !== -1) {
      this.showReportForm = true;
    }
  }
  async editMapDetails(action) {
    this.ngxService.start();
    const published = action == 'publish' ? true : false;
    let filterSlugType = this.editMapInfoForm.value.incidentTypes.map(list => ({ 'name': list.name, 'slug': list.slug, 'transformation_type': list.transformation_type }));


    // Get Map thumbnail Image
    let thumbImg = await this.firelineChild.mapScreenShortEvent().then(res =>
      res
    ).then(tImg => {
      return tImg;
    }).catch(e => {
      console.error(e)
    });

    const setMapObj = Object.assign({
      meta: this.editMapInfoForm.value,
      name: this.editMapInfoForm.controls.mapName.value,
      mapThumbImg: thumbImg,
      client: this.clientData.client.id,
      is_public: published
    });

    setMapObj['meta']['incidentTypes'] = filterSlugType;
    setMapObj['meta']['logo'] = this.fileToUpload;
    this.getMapParams['incidentTypes'] = filterSlugType;
    let slugNameFrmUrl = '';
    this.activatedRoute.url.subscribe((url) => { slugNameFrmUrl = url[1].path });
    // return;
    this.mapService.putMapDetails(setMapObj, this.mapId).subscribe(
      (data) => {
        console.log('suc');
        // return;
        this.previousRouteService.reloadMyMap({ click: true, slugName: slugNameFrmUrl });
        this.router.navigateByUrl('/myMaps');
      },
      (error) => {
        console.log('error');
      }
    );
  }

  saveMapDetails(action) {
    const published = action == 'publish' ? true : false;
    let filterSlugType = this.editMapInfoForm.value.incidentTypes.map(list => ({ 'name': list.name, 'slug': list.slug, 'transformation_type': list.transformation_type }));
    const setMapObj = Object.assign({
      meta: this.editMapInfoForm.value,
      name: this.editMapInfoForm.controls.mapName.value,
      client: this.clientData.client.id,
      is_public: published
    });
    setMapObj['meta']['incidentTypes'] = filterSlugType;
    setMapObj['meta']['logo'] = this.fileToUpload;
    // return;
    this.mapService.postMapDetails(setMapObj).subscribe(
      (data) => {
        // return;
        this.router.navigateByUrl('/myMaps');
      },
      (error) => {
        console.log('error');
      }
    );
  }

}
