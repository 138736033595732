import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../app-config';

@Injectable({
  providedIn: 'root'
})
export class HistoricalService {

  constructor(private httpClient: HttpClient) { }

  public getHistoricalData = (date, mapType): Observable<any> => {
    return this.httpClient.get<any>(AppConfig.maps+""+mapType+"/?date="+date+"");
  }

}
