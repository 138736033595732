import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable, from, of } from 'rxjs';

import { map } from 'rxjs/operators';

import * as L from 'leaflet';

import * as esri from 'esri-leaflet';

import 'leaflet.markercluster';

declare let TopoJSON;


@Injectable({
  providedIn: 'root'
})
export class RisingMainService {

  constructor(private httpClient: HttpClient) { }

  selectedLayer: any;
  transData: any;
  topData: any;


  getGeometryCenter(geometry) {
        var coordinates = geometry.coordinates;

        if (geometry.type === 'LineString') {
	
    		var midpointIndex = Math.floor(coordinates.length / 2);
            var midpoint = [0, 0];

            // Calculate the midpoint by averaging the coordinates of consecutive points
            if (coordinates.length % 2 === 0) {
                // If the number of coordinates is even, average the two middle points
                midpoint[0] = (coordinates[midpointIndex][0] + coordinates[midpointIndex - 1][0]) / 2;
                midpoint[1] = (coordinates[midpointIndex][1] + coordinates[midpointIndex - 1][1]) / 2;
            } else {
                // If the number of coordinates is odd, use the middle point
                midpoint[0] = coordinates[midpointIndex][0];
                midpoint[1] = coordinates[midpointIndex][1];
            }

            var centerX = midpoint[0];
            var centerY = midpoint[1];

            return [centerX, centerY];

        } else if (geometry.type === 'MultiLineString') {
            // For MultiLineString, calculate the center of all LineStrings
            var totalPoints = 0;
            var centroidX = 0;
            var centroidY = 0;

            for (var j = 0; j < coordinates.length; j++) {
                var lineStringCoords = coordinates[j];

                for (var k = 0; k < lineStringCoords.length; k++) {
                    var point = lineStringCoords[k];
                    centroidX += point[0];
                    centroidY += point[1];
                    totalPoints++;
                }
            }

            centroidX /= totalPoints;
            centroidY /= totalPoints;

            return [centroidX, centroidY];
        }

        return null; // Unknown or unsupported geometry type
    }


      createRisingMain(apiUrl) {
        return this.httpClient.get(apiUrl).pipe(
          map(
            res => {
              this.topData = res;
	      this.selectedLayer = L.layerGroup();
	      var lineLayerGroup = L.layerGroup();
              var markers = new L.MarkerClusterGroup();
	      const outerThis = this;
              this.selectedLayer  =  L.geoJSON(this.topData, {
		onEachFeature: function onEachFeature(feature, layer) { 
                if (feature.properties.data) {
                    layer.bindPopup("<div class=\"essential\">" + feature.properties.sewer_name + "</div>" );
                	}
    		},
              });

	if (this.selectedLayer) {
  		this.selectedLayer.eachLayer(function (layer) {
    		const gm = layer.feature.geometry;
    		const d = outerThis.getGeometryCenter(gm);
    		const pointFeature: GeoJSON.Feature = {
      			type: "Feature",
      			geometry: {
        			type: "Point",
        			coordinates: d
      				},
      			properties: {} // You can add properties here if needed
    			};

		var pointLayer = L.geoJSON(pointFeature, {
      			pointToLayer: function (feature, latlng) {
				var iconUrl;
				if (layer.feature.properties.color === "008000") {
					iconUrl = "https://api.kartasoft.co/media/json/public/data/marker-icon-green.png";
				} else if (layer.feature.properties.color === 'FFA500') {
					iconUrl = "https://api.kartasoft.co/media/json/public/data/marker-icon-orange.png";
				} else {
					iconUrl = "https://api.kartasoft.co/media/json/public/data/marker-icon-red.png";
				}
				var final_Icon = new L.Icon({
                			iconSize: [27, 27],
                			iconAnchor: [10, 32],
                			popupAnchor: [1, -12],
                			iconUrl: iconUrl
              			});
        			var marker = L.marker(latlng, { interactive: true , icon: final_Icon });
				marker.on('click', function () {
                        		//console.log('Marker clicked:', layer.feature.geometry);
                        		var multiLineString = layer.feature.geometry;
                        		if (multiLineString) {
						var lineLayer = L.geoJSON(multiLineString, {
            						style: {
                						color: "#"+layer.feature.properties.color,
                						opacity: 1,
                						weight: 3
            							},
	    						onEachFeature: function (lineFeature, lineLayer)  {
            // Check if feature has the necessary properties for the popup
            							if (layer.feature.properties.data ) {
                							var popupContent = "<h4 class=\"backdrop\">" + layer.feature.properties.sewer_name + "</h4>";
               	 							popupContent += "<table class=\"pwd\">";
                							popupContent += "<thead><tr><th> Asset id</th>";

                // Extract all unique keys from the JSON objects
                var uniqueKeys = [];
                for (var key in layer.feature.properties.data) {
                    var value = layer.feature.properties.data[key];
                    if (typeof value === 'object') {
                        for (var subKey in value) {
                            if (!uniqueKeys.includes(subKey)) {
                                uniqueKeys.push(subKey);
                            }
                        }
                    }
                }

                // Add the unique keys as column headers
                uniqueKeys.forEach(function (subKey) {
                    popupContent += "<th>" + subKey + "</th>";
                });
                popupContent += "</tr></thead><tbody>";

                // Loop through your data to populate the table rows
                for (var key in layer.feature.properties.data) {
                    var value = layer.feature.properties.data[key];

                    popupContent += "<tr><td>" + key + "</td>";

                    // Add cells for each unique key
                    uniqueKeys.forEach(function (subKey) {
                        if (typeof value === 'object' && value[subKey] !== undefined) {

                            var cellContent = value[subKey];

                            // Check if the cell content is a URL
                            if (/^(ftp|http|https):\/\//.test(cellContent)) {

                                if (cellContent.toLowerCase().endsWith('.pdf')) {
                                    // If it's a PDF URL, encode the URL to handle spaces
                                    popupContent += "<td><a href='" + cellContent + "' target='_blank' style='color: blue; text-decoration: underline; font-size: inherit;'> Assessment_File </a></td>";

                                } else {
                                    // If it's a URL, wrap it in an anchor tag
                                    popupContent += "<td><a href='" + cellContent + "' target='_blank' style='color: blue; text-decoration: underline; font-size: inherit;'>" + cellContent + "</a></td>";

                                }
                                
                            } else {
                                popupContent += "<td>" + cellContent + "</td>";
                            }
                        } else {
                            popupContent += "<td></td>";
                        }
                    });

                    popupContent += "</tr>";
                }

                popupContent += "</tbody></table>";

		if (layer.feature.properties.file_location) {
    			const fileLocationString = layer.feature.properties.file_location;
    			const fileLocationLinks = fileLocationString.split(','); // Split the string into an array of links
    			popupContent += "<table class=\"pws\"><tr><td>Reports: </td><td>";
    			fileLocationLinks.forEach(link => {
        			const fileName = link.trim().split('/').pop();
        			popupContent += "<b><a href='" + link.trim() + "' style='font-size: 12px; display: block; margin-bottom: 5px;'>"+fileName+"</a></b>";
    			});
    			popupContent += "</td></tr></table>";
  		}

		lineLayer.bindPopup(popupContent);
            }
	    lineLayerGroup.addLayer(lineLayer);
	    markers.removeLayer(marker);
        }
    });

	markers.addLayer(lineLayerGroup);
                        } else {
                            console.log("No MultiLineString geometry in feature's properties.");
                        }
                    });
        return marker;
      }
    });
   markers.addLayer(pointLayer); // Add the pointLayer to the 'markers' cluster group
  });
}
	      return markers;
              console.log(res, "res");
              console.log("this.selectedLayer == ", this.selectedLayer);
              return this.selectedLayer //this.selectedLayer;
            }
          )
        );
      }


    }

