import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable, from, of } from 'rxjs';

import { map } from 'rxjs/operators';

import * as L from 'leaflet';

import * as esri from 'esri-leaflet';

declare let TopoJSON;


// import * as topojson from 'topojson-client';

@Injectable({
  providedIn: 'root'
})
export class MajorEventTransportService {

  constructor(private httpClient: HttpClient) { }

  selectedLayer: any;
  transData: any;
  topData: any;

  createMajorEventTransport(apiUrl) {
    return this.httpClient.get(apiUrl).pipe(
      map(
        res => {
          this.topData = res;
          this.selectedLayer = L.layerGroup();
          this.selectedLayer = L.geoJSON(this.topData, {
		  pointToLayer: function(geoJsonPoint,latlng){
			  return L.circleMarker(latlng, {
                        radius : 8,
                        fillColor : "#C4EF07",
                        color : "#000",
                        weight : 1,
                        opacity : 1,
                        fillOpacity : 0.8
                    });
            },
            onEachFeature: function (feature, layer) {
                if (feature.properties) {
            var strPopup = "<h4 class=\"backdrop\">NSW " + feature.properties.incidentKind + " Events</h4><table class=\"pws\">";
            if (feature.properties.displayName != "") {
                strPopup = strPopup + "<tr><td>Name: </td><td><b>" + feature.properties.displayName + "</b></td></tr>";
            }
            if (feature.properties.mainCategory != "") {
                strPopup = strPopup + "<tr><td>Category: </td><td><b>" + feature.properties.mainCategory + "</b></td></tr>";
            }
            if (feature.properties.isMajor != "") {
                strPopup = strPopup + "<tr><td>Status/Severity: </td><td><b>" + feature.properties.isMajor + "</b></td></tr>";
            }
            if (feature.properties.publicTransport != "") {
                strPopup = strPopup + "<tr><td>Public Transport: </td><td><b>" + feature.properties.publicTransport + "</b></td></tr>";
            }
            if (feature.properties.impactingNetwork != "") {
                strPopup = strPopup + "<tr><td>Network Impact: </td><td><b>" + feature.properties.impactingNetwork + "</b></td></tr>";
            }
            if (feature.properties.otherAdvice != "") {
                strPopup = strPopup + "<tr><td>Advice: </td><td><b>" + feature.properties.otherAdvice + "</b></td></tr>";
            }
            if (feature.properties.roads[0].crossStreet != "") {
                strPopup = strPopup + "<tr><td>Cross Street: </td><td><b>" + feature.properties.roads[0].crossStreet + "</b></td></tr>";
            }
            if (feature.properties.roads[0].delay != "") {
                strPopup = strPopup + "<tr><td>Delay: </td><td><b>" + feature.properties.roads[0].delay + "</b></td></tr>";
            }
            if (feature.properties.roads[0].mainStreet != "") {
                strPopup = strPopup + "<tr><td>Main Street: </td><td><b>" + feature.properties.roads[0].mainStreet + "</b></td></tr>";
            }
            if (feature.properties.roads[0].region != "") {
                strPopup = strPopup + "<tr><td>Region: </td><td><b>" + feature.properties.roads[0].region + "</b></td></tr>";
            }
            if (feature.properties.roads[0].suburb != "") {
                strPopup = strPopup + "<tr><td>Suburb: </td><td><b>" + feature.properties.roads[0].suburb + "</b></td></tr>";
            }
	    if (feature.properties.created != "" && typeof feature.properties.created !== undefined) {
                strPopup = strPopup + "<tr><td>Creation Time: </td><td><b>" + new Date(feature.properties.created) + "</b></td></tr>";
	    }
	    if (feature.properties.end != "" && typeof feature.properties.end !== undefined) {
    		strPopup = strPopup + "<tr><td>Ending Time: </td><td><b>" + new Date(feature.properties.end) + "</b></td></tr>";
	    }
            if (feature.properties.lastUpdated != "" && typeof feature.properties.lastUpdated !== undefined) {
                strPopup = strPopup + "<tr><td>Last Updated: </td><td><b>" + new Date(feature.properties.lastUpdated) + "</b></td></tr>";
            }
            strPopup = strPopup + "</table>";
            layer.bindPopup(strPopup);

        }
}

        })


          //console.log("this.selectedLayer == ", this.selectedLayer);
          return this.selectedLayer //this.selectedLayer;
        }
      )
    );
  }


}
