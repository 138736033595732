<div class="row my-3">
  <div class="col-lg-6">
    <h3>My Maps</h3>
  </div>
  <div class="col-lg-6 d-flex justify-content-end">
    <button class="btn btn-primary m-t-16 float-end" (click)="goToCreate()">Create a Map
      <span><i class="bi bi-file-earmark-plus"></i></span></button>
  </div>
  <div class="col-lg-12 my-3">
    <ul class="tabs">
      <li (click)='showTabs("all")'><a [ngClass]="{'active': visibleTab === 'all' }">My Maps</a></li>
      <li (click)='showTabs("published")'><a [ngClass]="{'active': visibleTab === 'published' }">Published</a></li>
      <li (click)='showTabs("drafts")'><a [ngClass]="{'active': visibleTab === 'drafts' }">Drafts</a></li>
    </ul>
  </div>
</div>
<div class="row mb-5" id="my_mpas">
  <div class="row" *ngIf="myMaps?.length > 0">
    <div class="col-12 col-lg-4 mt-3" *ngFor="let listItem of myMaps; let i = index">
      <div class="card">
        <img (click)="viewMap(listItem.slug);" [src]="listItem?.meta?.thumbnail" class="card-img-top" [attr.alt]="listItem.name">
        <div class="card-body">
          <h5 class="card-title">{{ listItem.name }}</h5>
          <p class="card-text">{{ listItem.created_at | date: mediumDate }}</p>
          <a *ngIf="listItem?.is_public == true" (click)="listItem?.is_public == true? viewPublicMap(listItem.slug) : ''" class="card-text">View as public<i class="p-2 bi bi-box-arrow-up-right"></i></a>
          <p class="card-text">{{ listItem.meta.description }}</p>
        </div>
        <div class="card-footer text-muted bg-transparent">
          <div class="d-block float-end">
            <button class="btn btn-primary me-2" (click)="publishMap(listItem?.slug)" *ngIf="listItem?.is_public == false">Publish</button>
            <button class="btn btn-primary me-2" (click)="unpublishMap(listItem?.slug)" *ngIf="listItem?.is_public == true">Unpublish</button>
            <button class="btn btn-primary me-2" (click)="editMaps(listItem?.slug)" [attr.id]="i">Edit</button>
            <button class="btn btn-danger px-3" (click)="deleteMaps(listItem?.slug)">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center" *ngIf="myMaps?.length == 0">
    <span><i class="bi bi-map-fill"></i></span><br />
    <span>No Maps Found</span>
  </div>
</div>