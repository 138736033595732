<div class="container">
    <div class="row my-3">
        <div class="col-lg-6">
            <h4>Current Outages</h4>
        </div>
        <div class="col-lg-6 d-flex justify-content-end">
            <h6 class="cursor-pointer" (click)="openModal('add')"><i class="bi bi-plus-circle mr-2"></i>Add an outage</h6>
        </div>
    </div>
    <div class="row">
        <!-- <div class="col-lg-3 my-3">
            <select class="form-control" [(ngModel)]="filterOutage" (change)="filterTable($event.target.value)">
                <option selected value="All">All</option>
                <option value="true">Planned</option>
                <option value="false">Un Planned</option>
            </select>
        </div> -->
        <div class="col-lg-2 my-3">
            <button class="btn btn-filled delete-btn" (click)="deleteAll();" [disabled]="checkedTickets?.length == 0"><i class="bi bi-check-lg me-1"></i>Clear All</button>
        </div>
        <div class="col-lg-4 my-3">
            <input type="text" placeholder="Search Outages by postcode, state" class="form-control" (input)="filterTable($event.target.value)" style="height:40px !important;">
        </div>
        <div class="col-lg-4 my-3">
            <select (change)="filterTable($event.target.value)" class="form-select" aria-label="Default select example">
                <option selected value="all">All</option>
                <option value="reported">Reported</option>
                <option value="planned">Planned</option>
                <option value="unplanned">Unplanned</option>
              </select>
        </div>
        <div class="col-lg-2 my-3">
            <button class="btn btn-filled delete-btn" (click)="refresh()"><i class="bi bi-arrow-clockwise me-1"></i>Refresh</button>
        </div>
        <div class="col-lg-12">              
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th>Clear</th>
                    <th scope="col">STATE</th>
                    <th scope="col">POSTAL CODE</th>
                    <th scope="col">IP ADDRESS</th>
                    <th scope="col">TYPE</th>
                    <!-- <th scope="col">
                        <select>
                            <option value="planned">Planned</option>
                            <option value="unplanned">Un Planned</option>
                        </select>
                    </th> -->
                    <th scope="col">START</th>
                    <th scope="col">LIKELY END</th>
                    <th scope="col">ACTIONS</th>
                  </tr>
                </thead>
                <tbody *ngIf="outagesList?.length > 0">
                  <tr *ngFor="let outagesList of outagesList">
                    <td class="text-center"><input type="checkbox" (change)="onCheck(outagesList?.id)" /></td>
                    <td>{{outagesList?.state}}</td>
                    <td>{{outagesList?.postcode}}</td>
                    <td>{{outagesList?.meta.ip}}</td>    
                    <td *ngIf="outagesList?.is_reported == true">Reported</td>
                    <td *ngIf="outagesList?.is_reported == false && outagesList?.is_planned == true">Planned</td>
                    <td *ngIf="outagesList?.is_reported == false && outagesList?.is_planned == false">Unplanned</td>
                    <td>{{outagesList?.start_time | date:'medium'}}</td>
                    <td>{{outagesList?.end_time | date: 'MMM dd, yyyy HH:mm'}}</td>
                    <td class="cursor-pointer" [ngClass]="{'disabledCls': checkedTickets?.length > 0}">
                        <span (click)="clearOutage(outagesList?.id);"><i class="bi bi-check-lg me-1"></i>Clear</span>
                        <span class="ms-2" [ngClass]="{'disabledCls': outagesList?.is_reported == true}" (click)="editOutage(outagesList, 'edit')"><i class="bi bi-pencil-fill me-1"></i>Edit</span>
                        <span class="ms-2" (click)="editOutage(outagesList, 'view')"><i class="bi bi-eye-fill me-1"></i>View</span>
                    </td>
                  </tr>
                </tbody>
                <tbody *ngIf="outagesList?.length == 0">
                    <tr>
                        <td colspan="8" class="text-center">No Outage Found</td>
                    </tr>
                </tbody>
              </table>
        </div>
    </div>

    <!-- <div class="row my-3">
        <div class="col-lg-6">
            <h4>Customer Reported Outages</h4>
        </div>
        <div class="col-lg-6 d-flex justify-content-end">
            <h6><i class="bi bi-plus-circle mr-2"></i>Simulate Customer Reporting</h6>
        </div>
    </div> -->
</div>
 
<!-- Add Outage -->
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Outage Details</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeModel()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
        <form [formGroup]="outageForm">
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="state" class="form-label">State</label>
                        <select class="form-control" id="state" formControlName="state" placeholder="State Or Territory">
                            <option value="ACT">ACT</option>
                            <option value="NSW">NSW</option>
                            <option value="NT">NT</option>
                            <option value="QLD">QLD</option>
                            <option value="SA">SA</option>
                            <option value="TAS" selected="">TAS</option>
                            <option value="VIC">VIC</option>
                            <option value="WA">WA</option>
                        </select>
                        <span *ngIf="outageFormInvalid && getoutageFormControl.state.errors?.required" class="error-message"><small>Please enter the state </small></span>
                        <!-- <span *ngIf="outageFormInvalid && getoutageFormControl.state.errors?.minlength || getJobFormControl.location.errors?.maxlength" class="error-message"><small> (min: 2 to max: 100 characters)</small></span> -->
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="state" class="form-label">Postal Code</label>
                        <input type="text" id="postcode" placeholder="Postal Code" class="form-control" formControlName="postcode" />
                        <span *ngIf="outageFormInvalid && getoutageFormControl.postcode.errors?.required" class="error-message"><small>Please enter the postal code </small></span>
                        <span *ngIf="getoutageFormControl.postcode.errors?.maxlength || getoutageFormControl.postcode.errors?.minlength" class="error-message m-t-8"><small>(must be 4 digits)</small></span>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="state" class="form-label">Type</label>
                        <select class="form-control"[(ngModel)]="is_planned" formControlName="is_planned">
                            <option selected value="true">Planned</option>
                            <option value="false">Unplanned</option>
                        </select>
                        <span *ngIf="outageFormInvalid && getoutageFormControl.is_planned.errors?.required" class="error-message"><small>Please select the type </small></span>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="state" class="form-label">Start Date & Time</label>
                        <input type="datetime-local" id="state" placeholder="Map name" class="form-control" formControlName="start_time" />
                        <span *ngIf="outageFormInvalid && getoutageFormControl.start_time.errors?.required" class="error-message"><small>Please select the start data </small></span>
                    </div>
                </div>
                <!-- <div class="col-lg-3">
                    <div class="form-group">
                        <label for="state" class="form-label">Start Time</label>
                        <input type="time" id="state" placeholder="Start Time" formControlName="time" class="form-control" />
                        <span *ngIf="outageFormInvalid && getoutageFormControl.time.errors?.required" class="error-message"><small>Please select the start data </small></span>
                    </div>
                </div> -->
                <div class="col-lg-12 mt-2">
                    <div class="form-group">
                        <label for="state" class="form-label">Likely Duration(Hours)</label>
                        <input type="text" id="state" placeholder="Likely Duration" formControlName="duration" class="form-control" />
                        <span *ngIf="outageFormInvalid && getoutageFormControl.duration.errors?.required" class="error-message"><small>Please enter the duration </small></span>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label for="state" class="form-label">Details</label>
                        <textarea class="form-control" rows="5" formControlName="text"></textarea>
                    </div>
                </div>
            </div>
            <div class="row mt-4" *ngIf="viewOutage">
                <div class="d-flex justify-content-end">
                    <button class="btn btn-outline" (click)="closeModel()">Cancel</button>
                    <button class="btn btn-filled" *ngIf="editOutageModal" (click)="editOutageForm();">Edit</button>
                    <button class="btn btn-filled" *ngIf="!editOutageModal" (click)="submitOutage();">Submit</button>
                </div>
            </div>
        </form>
    </div>
  </div>
</ng-template>