import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import * as L from 'leaflet';
import 'leaflet.markercluster';

import { kml } from "@tmcw/togeojson";

@Injectable({
  providedIn: 'root'
})
export class BoundariesService {

  constructor(private httpClient: HttpClient) { }

  selectedLayer: any;
  pwrData: any;

  createBoundariesLayer(apiUrl) {
    let liveData;
    return this.httpClient.get(apiUrl).pipe(
      map(
        res => {
          liveData = res;
          this.selectedLayer = L.geoJSON(liveData, {
            onEachFeature: function onEachFeature(feature, layer) {
              if (feature.properties && feature.properties.description) {
                layer.bindPopup(feature.properties.description);
              }
            },
            style:<any> function style(featureStyle: any) {
              return {
                color: featureStyle.properties['stroke'],
                weight: featureStyle.properties['stroke-width'],
                opacity: featureStyle.properties['stroke-opacity'],
                fillColor: featureStyle.properties['fill'],
                fillOpacity: featureStyle.properties['fill-opacity']
              }
            }
          });
          return this.selectedLayer;
        }
      )
    );
  }

  createGeoJsonLayer(geoJson) {
    // console.log(geoJson)
    // geoJson = geoJson == 'https://alert.tas.gov.au/data/data.geojson' ? 'assets/mapdata/au_data.geojson' : geoJson;
    // console.log(geoJson)
    let liveData;
    var markers = new L.MarkerClusterGroup();
    return this.httpClient.get(geoJson).pipe(
      map(
        res => {
          liveData = res;
          this.selectedLayer = L.geoJSON(liveData, {
            onEachFeature: function onEachFeature(feature, layer) {
              // console.log(feature)
              if (feature.properties.description || feature.properties.name || feature.properties.bodyHtml) {
                const popupText = feature.properties.description != undefined ? feature.properties.description : feature.properties.bodyHtml != undefined ? feature.properties.bodyHtml : feature.properties.name;
                layer.bindPopup(popupText);
              }
              // console.log(feature.properties.icon)
            },
            pointToLayer: function (feature, latlng) {
              console.log(feature);
              const icon = feature.properties.icon != undefined ? feature.properties.icon : 'assets/images/kml_marker.png';
              var geoJsonIcon = geoJson == 'https://api.kartasoft.co/media/json/public/data/tas_fire.json' ? 'assets/images/fire2-40.png' : geoJson == 'https://api.kartasoft.co/media/json/public/data/flights.json' ? 'assets/images/plane2.png' : icon;
              // Set Marker Icon
              var fireIconGreen = new L.Icon({
                iconSize: [27, 32],
                markerColor: 'red',
                iconAnchor: [10, 32],
                popupAnchor: [1, -12],
                iconUrl: geoJsonIcon
              });
              return markers.addLayer(L.marker(latlng, { icon: fireIconGreen }))
            },
            style:<any> function style(featureStyle: any) {
              var dict = {
                color: featureStyle.properties['stroke'],
                weight: featureStyle.properties['stroke-width'],
                opacity: featureStyle.properties['stroke-opacity'],
                fillColor: featureStyle.properties['fill'],
                fillOpacity: featureStyle.properties['fill-opacity']
              };

              if(featureStyle.properties['stroke'] == undefined){
                delete dict.color;
              }
              if(featureStyle.properties['stroke-width'] == undefined){
                delete dict.weight;
              }
              if(featureStyle.properties['stroke-opacity'] == undefined){
                delete dict.opacity;
              }
              if(featureStyle.properties['fill'] == undefined){
                delete dict.fillColor;
              }
              if(featureStyle.properties['fill-opacity'] == undefined){
                delete dict.fillOpacity;
              }
              return dict;
            }
          });
          return this.selectedLayer;
        }
      )
    );
  }

}
