import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  public onClose: Subject<boolean>;
  public confirmationDetails: Subject<any>;

  public title: string;
  public contents: string;
  public confirmAction: string;
  public saveBtnLable: string;
  public showForm: boolean;
  popUpConfirmationForm: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private confirmationFG: FormBuilder ) { }

  ngOnInit(): void {
    this.popUpConfirmationForm = this.confirmationFG.group({
      rejection_reason: ['']
    });
    this.onClose = new Subject();
    this.confirmationDetails = new Subject();
  }

  confirmSave(): void {        
    //this.onClose.next(true);
    let details = this.showForm ? { 'formDetail': this.popUpConfirmationForm.value.rejection_reason, 'confirm': true } : true;
    this.confirmationDetails.next(details);
    this.bsModalRef.hide();
  }

  SuccessAlert(): void {        
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

}
