import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common'

// Service
import { HistoricalService } from './../services/historical.service';
import { MapService } from '../services/map.service';

@Component({
  selector: 'app-historical-maps',
  templateUrl: './historical-maps.component.html',
  styleUrls: ['./historical-maps.component.css']
})
export class HistoricalMapsComponent implements OnInit {

  getMapParams: any = {};
  isCollapsed = false;
  showReportForm: boolean = false;
  showMap: boolean = true;
  mapList: any = [];
  showValidation: boolean = false;
  clientData = JSON.parse(localStorage.getItem("client"));

  constructor(
    private ngxsService: NgxUiLoaderService,
    private historicalFormGroup: FormBuilder,
    private historicalService: HistoricalService,
    private mapService: MapService,
    public datepipe: DatePipe
  ) { }


  // Outage Form Creation
  public historicalForm = this.historicalFormGroup.group({
    mapName: [null, [Validators.required]],
    date: ['', [Validators.required]],
  });

  ngOnInit(): void {
    // Get Map Details
    this.mapService.getMapList().subscribe((res) => {
      if (res) {
        console.log(res);
        this.mapList = res;
      } else {
        console.log("error in the response", res);
      }
    });
  }

  showMapDetails() {
    console.log(this.historicalForm.valid);
    // return;

    if (this.historicalForm.valid == true) {
      let dateForm = new Date(this.historicalForm.get('date').value);
      let date = this.datepipe.transform(dateForm, 'yyyy-MM-dd');
      let mapType = this.historicalForm.get('mapName').value;
      this.ngxsService.start();
      this.historicalService.getHistoricalData(date, mapType.slug).subscribe(
        (data) => {
          console.log(data);
          this.getMapParams = Object.assign({},
            {
              incidentTypes: data.data_types,
              // kmlName: this.selectedIncidentsTypes.kmlName,
              // mapType: this.mapBaseStylesForm.controls['mapType'].value,
              logo: mapType.meta.logo,
              headerBg: mapType.meta.headerBg,
              mapViewTypes: mapType.meta.mapViewTypes,
              // kmlSource: this.dataSrcUpload2,
            });
          // this.getMapParams = data.data_types;
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.showValidation = true;
    }
  }


}
