<div class="icon-bar cursor-pointer text-center" (click)="showMenu();">
    <!-- <i class="bi bi-list cursor-pointer show-menu"></i> -->
    <span><i class="bi bi-pencil-square show-report-form"></i></span>
</div>
<div class="container-fluid">
<div class="row">            
    <form [formGroup]="outageForm">
        <div class="row p-4 side-menu-form"  style="left:{{sideMenu}};">
            <div class="col-lg-12">
                <div class="d-flex justify-content-end">
                    <i (click)="hideMenu();" class="bi bi-x cursor-pointer hide-menu"></i>
                </div>
                <div class="col-lg-12">
                    <h4>Report an Issue</h4>
                    <div class="alert alert-success mt-3" role="alert" *ngIf="sucMsg != ''">
                        <span><i class="bi bi-check-circle"></i>{{sucMsg}}</span>
                      </div>                            
                </div>
                <div class="form-group mt-4">
                    <label for="state" class="form-label">State</label>
                    <select class="form-control" id="state" formControlName="state" placeholder="State Or Territory">
                        <option value="ACT">ACT</option>
                        <option value="NSW">NSW</option>
                        <option value="NT">NT</option>
                        <option value="QLD">QLD</option>
                        <option value="SA">SA</option>
                        <option value="TAS" selected="">TAS</option>
                        <option value="VIC">VIC</option>
                        <option value="WA">WA</option>
                    </select>
                    <!-- <label for="state" class="form-label">State</label>
                    <input type="text" id="state" placeholder="State" class="form-control" formControlName="state" />-->
                    <span *ngIf="outageFormInvalid && getoutageFormControl.state.errors?.required" class="error-message"><small>Please select the state </small></span>
                </div>
                <div class="form-group mt-4">
                    <label for="state" class="form-label">Postal Code</label>
                    <input type="text" id="postcode" placeholder="Postal Code" class="form-control" formControlName="postcode" />
                    <span *ngIf="outageFormInvalid && getoutageFormControl.postcode.errors?.required" class="error-message"><small>Please enter the postal code </small></span>
                    <span *ngIf="getoutageFormControl.postcode.errors?.maxlength || getoutageFormControl.postcode.errors?.minlength" class="error-message m-t-8"><small>(must be 4 digits)</small></span>
                </div>
                <div class="form-group mt-4">
                    <label for="state" class="form-label">Details</label>
                    <textarea class="form-control" rows="5" formControlName="text"></textarea>
                </div>
                <div class="d-flex justify-content-end mt-4">
                    <button class="btn btn-outline" (click)="hideMenu();">Cancel</button>
                    <button class="btn btn-filled" (click)="submitOutage();">Submit</button>
                </div>
            </div>
        </div>
    </form>
</div>
</div>