import { Component, OnInit, Renderer2, OnDestroy, ElementRef } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { PreviousRouteService } from '../services/previous-route.service';
import { Location } from '@angular/common';
// Services
import { MapService } from '../services/map.service';

@Component({
  selector: 'app-maptemplates',
  templateUrl: './maptemplates.component.html',
  styleUrls: ['./maptemplates.component.css']
})
export class MaptemplatesComponent implements OnInit {
  public myMaps: any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mapService: MapService,
    private previousRouteService: PreviousRouteService,
    private renderer2: Renderer2,
    private elRef: ElementRef) {
    this.renderer2.listen("window", "click", (e: Event) => {
      // debugger;
      // console.log("I am detecting === ", e.target);
    });
  }

  private unlistener: () => void;

  public myMapsImages = [
    {
      title: 'Published',
      list: [{ name: 'FireP', desc: 'Fire Copy Text Sample It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', date: '09-05-2222', img: '/assets/images/sample-thumb-map.png' },
      { name: 'AusgridP', desc: 'Ausgrid Copy Text It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', date: '05-05-2222', img: '/assets/images/sample-thumb-satellite.png' },
      { name: 'Endevour', desc: 'Endevour Copy Text It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', date: '04-05-2222', img: '/assets/images/sample-thumb-map.png' },
      { name: 'Essential', desc: 'Essential Copy Text It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', date: '03-05-2222', img: '/assets/images/sample-thumb-satellite.png' },
      { name: 'Water', desc: 'Water Copy Text It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', date: '02-05-2222', img: '/assets/images/sample-thumb-map.png' },
      { name: 'Gas and Oil', desc: 'Gas and Oil Copy Text It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', date: '01-05-2222', img: '/assets/images/sample-thumb-satellite.png' }]
    },
    {
      title: 'Draft',
      list: [{ name: 'Fire Draft', desc: 'Fire Copy Text It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', date: '09-05-2222', img: '/assets/images/sample-thumb-satellite.png' },
      { name: 'Ausgrid Draft', desc: 'Ausgrid Copy Text It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', date: '05-05-2222', img: '/assets/images/sample-thumb-map.png' },
      { name: 'Endevour Draft', desc: 'Endevour Copy Text It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', date: '04-05-2222', img: '/assets/images/sample-thumb-map.png' },
      { name: 'Essential Draft', desc: 'Essential Copy Text It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', date: '03-05-2222', img: '/assets/images/sample-thumb-satellite.png' },
      { name: 'Water Draft', desc: 'Water Copy Text It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', date: '02-05-2222', img: '/assets/images/sample-thumb-map.png' },
      { name: 'Gas and Oil Draft', desc: 'Gas and Oil Copy Text It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', date: '01-05-2222', img: '/assets/images/sample-thumb-satellite.png' }]
    }

  ];

  tempData: any;
  ngOnInit(): void {
    this.ngxService.start(); // start foreground spinner of the master loader with 'default' taskId

    // Get Map Details
    this.mapService.templatesList().subscribe((res) => {
      if (res) {
        // console.log(res);
        this.myMaps = res;
        this.ngxService.stop();
      } else {
        console.log("error in the response", res);
      }
    });

    this.previousRouteService.currentMessage.subscribe(passData => this.tempData = passData);
    // this.unlistener = this.renderer2.listen("document", "mousemove", event => {
    //   console.log(`I am detecting mousemove at ${event} on Document!`);
    // });
    // debugger;

    let elementList = this.elRef.nativeElement.querySelector('.btn-danger');
    // console.log('elementList = ', elementList);
    //elementList[1].addEventListener('click', this.elmentClicke.bind(this));
  }

  elmentClicke(): void {
    alert("clicked");
  }

  goToCreate(): void {
    this.router.navigate(['createMap']);
  }

  editMaps(slugName): void {
    // this.previousRouteService.sendMessage(listItem);
    // alert("test")
    // this.router.navigate(['editMaps']);
    // window.open('editMaps/'+slugName, '_blank');
    this.router.navigate(['editMaps', slugName], { queryParams: { ft: '0' } });
    // const url = this.router.createUrlTree([slugName], {relativeTo: this.activatedRoute, queryParams: {param: 1}}).toString()
    // this.location.go(url);

    // this.router.navigate(["asd","asdasd"],{relativeTo: this.activatedRoute});
  }

  ngOnDestroy() {
    //this.unlistener();
  }

  viewMap(slugName) {
    // this.previousRouteService.sendMessage(listItem);
    window.open('myMaps/' + slugName, '_blank');
    // this.router.navigate(['view-public-map']);
    // this.ngxService.start();
    // alert(id);
    // this.router.navigate([slugName],{relativeTo: this.activatedRoute});
  }

  deleteMaps(mapId) {
    if (confirm("Are you sure to delete a map")) {
      this.ngxService.start();
      // Get Map Details
      this.mapService.deleteMap(mapId).subscribe((res) => {
        // if (res) {        
        // console.log(res);
        this.ngOnInit();
        // } else {
        // console.log("error in the response", res);
        // }
      });
    }
  }
}
