<div class="container">
    <div class="row my-3">
        <div class="col-lg-6">
            <h4>Users Details</h4>
        </div>
        <div class="col-lg-6 d-flex justify-content-end">
            <h6 class="cursor-pointer" (click)="openModal(template)"><i class="bi bi-plus-circle mr-2"></i>Add User</h6>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">              
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <!-- <th scope="col">Name</th> -->
                    <th scope="col">EMAIL</th>
                    <th scope="col">ACTIONS</th>
                  </tr>
                </thead>
                <tbody *ngFor="let usersList of usersList">
                  <tr>
                    <!-- <td>{{usersList?.first_name}} {{usersList?.last_name}}</td> -->
                    <td>{{usersList?.email}}</td>
                    <td class="cursor-pointer" (click)="deleteUser(usersList?.id);"><i class="bi bi-trash-fill"></i>Clear</td>
                  </tr>
                </tbody>
              </table>
        </div>
    </div>

    <!-- <div class="row my-3">
        <div class="col-lg-6">
            <h4>Customer Reported Outages</h4>
        </div>
        <div class="col-lg-6 d-flex justify-content-end">
            <h6><i class="bi bi-plus-circle mr-2"></i>Simulate Customer Reporting</h6>
        </div>
    </div> -->
</div>
 
<!-- Add Outage -->
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">User Details</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
        <form [formGroup]="usersForm">
            <div class="row">
                <!-- <div class="col-lg-6 mt-2">
                    <div class="form-group">
                        <label for="state" class="form-label">Firstname</label>
                        <input type="text" id="first_name" placeholder="Username" class="form-control" formControlName="first_name" />
                        <span *ngIf="usersFormInvalid && getusersFormControl.first_name.errors?.required" class="error-message"><small>Please enter the firstname </small></span>                        
                        <span *ngIf="usersFormInvalid && getusersFormControl.first_name.errors?.pattern" class="error-message"><small> (Enter the valid email id)</small></span>
                    </div>
                </div>
                <div class="col-lg-6 mt-2">
                    <div class="form-group">
                        <label for="state" class="form-label">Lastname</label>
                        <input type="text" id="last_name" placeholder="Username" class="form-control" formControlName="last_name" />
                        <span *ngIf="usersFormInvalid && getusersFormControl.last_name.errors?.required" class="error-message"><small>Please enter the lastname </small></span>                        
                        <span *ngIf="usersFormInvalid && getusersFormControl.last_name.errors?.pattern" class="error-message"><small> (Enter the valid email id)</small></span>
                    </div>
                </div> -->
                <div class="col-lg-12 mt-2">
                    <div class="form-group">
                        <label for="state" class="form-label">Username(Mail Id)</label>
                        <input type="text" id="username" placeholder="Username" class="form-control" formControlName="username" />
                        <span *ngIf="usersFormInvalid && getusersFormControl.username.errors?.required" class="error-message"><small>Please enter the username </small></span>                        
                        <span *ngIf="usersFormInvalid && getusersFormControl.username.errors?.pattern" class="error-message"><small> (Enter the valid email id)</small></span>
                    </div>
                </div>
                <div class="col-lg-12 mt-2">
                    <div class="form-group">
                        <label for="state" class="form-label">Password</label>
                        <input type="text" id="password" placeholder="Password" class="form-control" formControlName="password" />
                        <span *ngIf="usersFormInvalid && getusersFormControl.password.errors?.required" class="error-message"><small>Please enter the password </small></span>
                    </div>
                </div>
                <div class="col-lg-12 mt-2">
                    <div class="form-group">
                        <label for="state" class="form-label">Re-enter Password</label>
                        <input type="text" id="re-enter-password" placeholder="Re-enter Password" formControlName="retype_pass" class="form-control" />                        
                        <span *ngIf="retype_pass" class="error-message"><small>Password make sure your password match </small></span>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="d-flex justify-content-end">
                    <button class="btn btn-outline">Cancel</button>
                    <button class="btn btn-filled" (click)="submituserData();">Submit</button>
                </div>
            </div>
        </form>
    </div>
  </div>
</ng-template>