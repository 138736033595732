<div class="row my-3">
  <div class="col-lg-6">
    <h3>
      {{ mapName
      }}<i
        class="fa fa-info-circle"
        aria-hidden="true"
        tooltip="Choose map data source from the library, select map formats then update and publish your map"
        style="margin-left: 8px"
      ></i>
    </h3>
    <!-- <span>Choose map data source from the library, select map formats then update ans publish your map</span> -->
  </div>
  <div class="col-lg-6">
    <button
      type="button"
      class="btn btn-primary float-end"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseBasic"
    >
      Click to edit the map
    </button>
  </div>
</div>

<div class="map-preview mb-3">
  <div class="row">
    <div class="col-lg-12 mt-3">
      <div id="collapseBasic" [collapse]="!isCollapsed" [isAnimated]="true">
        <form [formGroup]="editMapInfoForm">
          <div class="row mb-3">
            <div class="col-lg-6">
              <div class="form-group">
                <ng-select
                  [items]="defaultIncidentTypes"
                  [multiple]="true"
                  bindLabel="name"
                  formControlName="incidentTypes"
                  [(ngModel)]="selectedIncidents"
                  placeholder="Data Library"
                >
                </ng-select>
              </div>
            </div>

            <div class="col-lg-3">
              <div class="form-group">
                <ng-select
                  formControlName="mapViewTypes"
                  [items]="mapViewList"
                  [(ngModel)]="selectedMapView"
                  placeholder="Select Map View"
                ></ng-select>
              </div>
            </div>

            <div class="col-lg-3">
              <div class="form-group">
                <ng-select
                  formControlName="mapType"
                  [items]="mapTypeList"
                  [(ngModel)]="selectedMapType"
                  placeholder="Select Map Type"
                ></ng-select>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="map_name"
                  [(ngModel)]="mapName"
                  formControlName="mapName"
                  placeholder="Map name"
                />
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <textarea
                  class="form-control"
                  [(ngModel)]="mapDesc"
                  formControlName="description"
                  id="map_desc"
                  min="10"
                  max="200"
                  placeholder="About the map"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-3">
              <div class="form-group mt-5">
                <div
                  class="input-group upload-img-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm"
                >
                  <input
                    id="upload"
                    type="file"
                    (change)="onSelectFile($event)"
                    formControlName="logo"
                    class="form-control border-0"
                    accept="image/png, image/gif, image/jpeg"
                  />
                  <label
                    id="upload-label"
                    for="upload"
                    class="font-weight-bold text-muted"
                    >Choose file</label
                  >
                  <div class="input-group-append">
                    <label
                      for="upload"
                      class="btn btn-light m-0 rounded-pill upload-icon-append px-4"
                    >
                      <i class="bi bi-upload"></i
                    ></label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="image-area mt-4">
                <img
                  id="imageResult"
                  [src]="fileToUpload"
                  alt=""
                  class="img-fluid rounded shadow-sm mx-auto d-block"
                  height="60"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group mt-3">
                <label for="hbg-color" class="form-label"
                  >Select Company Primary Color</label
                >
                <input
                  type="color"
                  [(ngModel)]="mapHeaderBg"
                  class="form-control form-control-color"
                  id="hbg-color"
                  value="#4a7d9e"
                  title="Click to pick"
                  formControlName="headerBg"
                />
              </div>
            </div>
            <div class="col-lg-12 text-end" *ngIf="!saveTemplates">
              <button
                class="btn btn-primary"
                style="margin-right: 10px"
                (click)="editMapDetails('save')"
              >
                Update
              </button>
              <button
                class="btn btn-primary"
                (click)="editMapDetails('publish')"
              >
                Update and Publish
              </button>
            </div>
            <div class="col-lg-12 text-end" *ngIf="saveTemplates">
              <button
                class="btn btn-primary"
                style="margin-right: 10px"
                (click)="saveMapDetails('save')"
              >
                Save
              </button>
              <button
                class="btn btn-primary"
                (click)="saveMapDetails('publish')"
              >
                Save and Publish
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div
    class="row m-0 shadow-lg"
    [style.background-color]="getMapParams.headerBg"
  >
    <app-report-issue-form
      *ngIf="!isCollapsed && showReportForm"
    ></app-report-issue-form>
    <div class="col-xs-12 col-lg-12 p-0">
      <div class="client-logo-pan">
        <img [src]="getMapParams.logo" height="60" />
      </div>
    </div>
    <!-- <div class="col-xs-12 col-lg-6 d-flex justify-content-end align-items-center">
            <div class="mr-5">
                <a class="text-light m-r-5" href="javascript:void(0)" > About </a>
            </div>
            <div class="ml-5">
                <a class="text-light m-l-5" href="javascript:void(0)" > Legends </a>
            </div>
        </div> -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <app-fireline
        [mapDetails]="getMapParams"
        [fromTemplates]="fromTemplates"
        *ngIf="showMap"
      ></app-fireline>
    </div>
  </div>
</div>
