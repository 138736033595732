import { Component, OnInit, TemplateRef } from '@angular/core';
import { UsersService } from '../services/users.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  
  public usersList: any;
  modalRef?: BsModalRef;
  is_planned: any;
  public usersFormInvalid: boolean = false;
  public retype_pass: boolean = false;

  constructor(
    private usersService: UsersService,
    private modalService: BsModalService,
    private usersFormGroup: FormBuilder,
  ) { }

  // Users Form Creation
  public usersForm = this.usersFormGroup.group({
    // first_name: ['', [Validators.required]],
    // last_name: ['', [Validators.required]],
    username: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    password: ['', [Validators.required]],
    retype_pass: [''],
  });

  ngOnInit(): void {

    // Get Users Details
    this.usersService.usersList().subscribe((res) => {   
      if (res) {        
        this.usersList = res;
        this.is_planned = 'true';
      } else {
        console.log("error in the response", res);
      }
    });

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  submituserData(){
    this.usersFormInvalid = this.usersForm.invalid ? true : false; 
    const formValues = this.usersForm.controls;       
    if(formValues.password.value == formValues.retype_pass.value){
      this.retype_pass = false;
      if (this.usersForm.valid == true) { 
          this.usersService.postUsersDetails(this.usersForm.value).subscribe(
            (data) => this.onSuccess(data),
            (error) => this.handleError(error)
          );      
      }
    }else{
      this.retype_pass = true;
    }
  }

  onSuccess(res: any) {
    // console.log(res);
    this.modalService.hide();
    this.ngOnInit();
  }

  handleError(res: any) {
    console.log(res);
  }

  deleteUser(id){
    if(confirm("Are you sure to delete a user")) {
      this.usersService.DeleteUsersDetails(id).subscribe(
        (data) => {
          this.ngOnInit();
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  get getusersFormControl() { return this.usersForm.controls; }

}
