import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../app-config';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private httpClient: HttpClient) { }

  public usersList = (): Observable<any> => {
    return this.httpClient.get<any>(AppConfig.users);
  }

  public postUsersDetails = (userData): Observable<any> => {
    return this.httpClient.post<any>(AppConfig.users, userData );
  }

  public DeleteUsersDetails = (id): Observable<any> => {           
    return this.httpClient.delete<any>(AppConfig.users+id);
  }

}
