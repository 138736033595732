import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { tap, catchError } from 'rxjs/operators';
/* import 'rxjs/add/operator/do'; */
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class AuthenticationInterceptorService implements HttpInterceptor {
    isPublicMap: boolean = false;
    constructor(public authService: AuthenticationService, public router: Router) {
        this.router.events.subscribe((res) => {
            // console.log(this.router.url, "Current URL");
        })
        const currentUrl = (this.router.routerState.snapshot.url).split('/');
        this.isPublicMap = (currentUrl[1] != "public-maps") ? false : true;
    }
    token: any;
    client: any;
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {            
        // console.log(req.url);
        var url = new URL(req.url);
        // console.log(url.hostname);
        // var c =  url.hostname
        // console.log(c);
        if(url.hostname == "kartasoft-backup.s3.ap-southeast-2.amazonaws.com"){
            let headers = new HttpHeaders();
            return next.handle(
                req.clone({
                    headers: headers
                })
            )
        }
        this.token = this.authService.getToken(); //auth is provided via constructor.
        this.client = this.authService.getClient();
        if (this.token != null) {
            let headers = new HttpHeaders().set('Authorization', this.token).set('client', this.client);
            return next.handle(
                req.clone({
                    headers: headers
                })
            )
        } else {
            if (this.isPublicMap) {
                let headers = new HttpHeaders().set('client', this.client);
                return next.handle(
                    req.clone({
                        headers: headers
                    })
                )
            } else {
                return next.handle(req).pipe(
                    catchError(response => {
                        if (response.status === 401 && !req.headers.get("skip")) {
                            this.authService.loggedOut();
                            this.router.navigate(['login']);
                            if (this.router) {
                                this.router.ngOnDestroy();
                            }
                            window.location.href = environment.platformUrl;
                        }
                        return throwError(response);
                    })
                )
            }
        }
    }

}