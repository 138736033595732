import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable, from, of } from 'rxjs';

import { map } from 'rxjs/operators';

import * as L from 'leaflet';

import * as esri from 'esri-leaflet';

declare let TopoJSON;


// import * as topojson from 'topojson-client';

@Injectable({
  providedIn: 'root'
})
export class TransmissionService2 {

  constructor(private httpClient: HttpClient) { }

  selectedLayer: any;
  transData: any;
  topData: any;

  createTransmissionLayer2(apiUrl) {
    return this.httpClient.get(apiUrl).pipe(
      map(
        res => {
          this.topData = res;
          this.selectedLayer = L.layerGroup();

          this.selectedLayer = L.geoJSON(this.topData, {
                    style: function(feature){
              return {
                color: "#0000cc",
                opacity: 2,
                weight: 2,
                fillColor: '#0000cc',
                fillOpacity: 0.8
              }
            },
            onEachFeature: function (feature, layer) {
        if (feature.properties) {
            if (feature.properties.frompoint != "") {
                var strPopup = "<h4 class=\"backdrop\">" + feature.properties.frompoint + " to " + feature.properties.topoint + "</h4><table class=\"pws\">";
            }
            if (feature.properties.cabletype == "Optical Fibre") {
                var strPopup = "<h4 class=\"backdrop\">" + feature.properties.assetid + "</h4><table class=\"pws\">";
            }
            if (feature.properties.fdrnumber != "") {
                strPopup = strPopup + "<tr><td>Owner: </td><td><b>" + "TransGrid " + "</td></tr>";
            }
            if (feature.properties.fdrnumber != null) {
                strPopup = strPopup + "<tr><td>Number: </td><td><b>" + feature.properties.fdrnumber + "</td></tr>";
            }
            if (feature.properties.opvoltage != null) {
                strPopup = strPopup + "<tr><td>Capacity: </td><td><b>" + feature.properties.opvoltage + "</b></td></tr>";
            }
            if (feature.properties.STATUS != "") {
                strPopup = strPopup + "<tr><td>Status: </td><td><b>" + "Operational" + "</b></td></tr>";
            }
            strPopup = strPopup + "</table>";
            layer.bindPopup(strPopup);
        }
},
        })


          
          console.log(res, "res");
          console.log("this.selectedLayer == ", this.selectedLayer);
          return this.selectedLayer //this.selectedLayer;
        }
      )
    );
  }


}
