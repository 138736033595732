import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { Observable, from, of } from 'rxjs';

import { map } from 'rxjs/operators';

import * as L from 'leaflet';

import * as esri from 'esri-leaflet';

@Injectable({
  providedIn: 'root',
})
export class AusgrideService {
  constructor(private httpClient: HttpClient) {}

  selectedLayer: any;

  createAusGrideLayer(apiUrl) {
    //   return this.httpClient.get(apiUrl).pipe(
    //     map(
    //       res => {
    //         console.log(res, "res");
    //     // AusGride Current outages
    //         this.selectedLayer = L.layerGroup();
    //   var fireOffIcon = L.icon({
    //     iconUrl: 'https://app.kartasoft.co/images/fire2-green-40.png',
    //     iconSize: [20, 20]
    //   });

    //   var lineStyleAusPlannedCurrent = {
    //         "color": "#af02fa",
    //         "weight": 1,
    //         "opacity": 0.65,
    //         "fillColor": '#af02fa',
    //         "fillOpacity": 0.5
    //     };

    //   this.selectedLayer = L.layerGroup();
    //   //console.log('Resultdata : ', Object.entries(this.mapDetails.features));
    //     res['features'].forEach(element => {
    //     // console.log(element.type, element.geometry.coordinates);
    //     // console.log('Element : ', element['geometry'].type);
    //     try {
    //       if (element['geometry'].type == 'Point') {
    //         //debugger;
    //         L.circleMarker([element['geometry'].coordinates[1], element['geometry'].coordinates[0]],/*{icon:fireOffIcon}*/lineStyleAusPlannedCurrent).bindPopup(
    //           "<div> <div>"+ "AG" + element['properties'].type + "</div> <div>" + element['properties'].area + "</div> <div>" + element['properties'].streets+ "</div></div>"
    //         ).addTo(this.selectedLayer);
    //       } else {
    //         // Check for other types
    //       }
    //     } catch (error) {
    //       console.log('Error in loop : ', error);
    //     }
    //   });
    // // end AusGride Current outages
    //         return this.selectedLayer;
    //       }
    //     )
    //   );

    let liveData;
    return this.httpClient.get(apiUrl).pipe(
      map((res) => {
        // AusGride Current outages
        this.selectedLayer = L.layerGroup();

        var geojsonAusCurrentMarkerOptions = {
          radius: 8,
          fillColor: '#a2ebe8',
          color: '#000',
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8,
        };
        var lineStyleAusCurrent = {
          color: '#f03',
          weight: 1,
          opacity: 0.65,
          fillColor: '#f03',
          fillOpacity: 0.5,
        };
        var geojsonAusCurrentPlannedMarkerOptions = {
          radius: 8,
          fillColor: '#af02fa',
          color: '#000',
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8,
        };
        var lineStyleAusPlannedCurrent = {
          color: '#af02fa',
          weight: 1,
          opacity: 0.65,
          fillColor: '#af02fa',
          fillOpacity: 0.5,
        };
        liveData = res;
        this.selectedLayer = L.geoJSON(liveData, {
          onEachFeature: this.onEachAusgridCurrentFeature,
          pointToLayer: function (feature, latlng) {
            if (feature.properties.type == 'Unplanned') {
              return L.circleMarker(latlng, geojsonAusCurrentMarkerOptions);
            } else {
              return L.circleMarker(
                latlng,
                geojsonAusCurrentPlannedMarkerOptions
              );
            }
          },
          style: function (feature) {
            if (feature.properties.type == 'Unplanned') {
              return lineStyleAusCurrent;
            } else {
              return lineStyleAusPlannedCurrent;
            }
          },
        });
        // end AusGride Current outages
        return this.selectedLayer;
      })
    );
  }

  onEachAusgridCurrentFeature(feature, layer) {
    if (feature.properties) {
      var strPopup =
        '<h4 class="backdrop">Ausgrid ' +
        feature.properties.type +
        ' Outage</h4><table class="pws">';
      if (feature.properties.cause != '') {
        strPopup =
          strPopup +
          '<tr><td>Cause: </td><td><b>' +
          feature.properties.cause +
          '</b></td></tr>';
      }
      if (feature.properties.status != '') {
        strPopup =
          strPopup +
          '<tr><td>Status/Severity: </td><td><b>' +
          feature.properties.status +
          '</b></td></tr>';
      }
      if (feature.properties.customers != '') {
        strPopup =
          strPopup +
          '<tr><td>Customers Affected: </td><td><b>' +
          feature.properties.customers +
          '</b></td></tr>';
      }
      if (feature.properties.estRestTime != '') {
        strPopup =
          strPopup +
          '<tr><td>Est. Restoration: </td><td><b>' +
          new DatePipe('en-US').transform(
            feature.properties.estRestTime,
            'dd/MM/yyyy'
          ) +
          '</b></td></tr>';
      }
      if (feature.properties.area != '') {
        strPopup =
          strPopup +
          '<tr><td>Area: </td><td><b>' +
          feature.properties.area +
          '</b></td></tr>';
      }
      if (feature.properties.streets != '') {
        strPopup =
          strPopup +
          '<tr><td>Streets: </td><td><b>' +
          feature.properties.streets +
          '</b></td></tr>';
      }
      strPopup = strPopup + "<tr><td>Source: </td><td><b><a href='https://www.ausgrid.com.au/Outages/Current-Outages' style='font-size: 12px;'>Ausgrid Outages</a></b></td></tr>";
      strPopup = strPopup + '</table>';
      layer.bindPopup(strPopup);
    }
  }
}
