import { Component, OnInit, ViewChild } from '@angular/core';

import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService

import { Router } from '@angular/router';

// Services
import { MapService } from '../services/map.service';
import { CommonService } from '../services/common.service';
import { environment } from '../../environments/environment';
import { FirelineComponent } from '../fireline/fireline.component';

@Component({
  selector: 'app-create-map',
  templateUrl: './create-map.component.html',
  styleUrls: ['./create-map.component.css']
})
export class CreateMapComponent implements OnInit {

  
  public map;
  public incidenetTypes: any;
  public formLen: any;
  dataSorcesValidation: boolean = true;
  clientData = JSON.parse(localStorage.getItem("client"));

  constructor(private mapParamsFormFG: FormBuilder,
    private ngxService: NgxUiLoaderService,
    private mapService: MapService,
    private commonService: CommonService,    
    private router: Router,
    private mapBaseStylesFormFG: FormBuilder) { }
  dataSourceSection: boolean = true;
  baseStylesSection: boolean = false;
  mapPreviewSection: boolean = false;
  enableCreate: boolean = true;
  getMapParams: any = {};
  fireData: any;
  fireData2: any;
  mapDataSrcForm: FormGroup;
  mapBaseStylesForm: FormGroup;
  mapInfoForm: FormGroup;
  logoImgUpload: any = '';
  headerBackGround: any = '';
  dataSrcUpload: any = '';
  dataSrcUpload2: any;
  dataSrcUpload3: any;
  kmlFileName: string = '';
  kmlFileNameErr: string = '';
  headerColor: any = '';
  clientLogo: any;
  fireLayers: any;
  selectedMapView: any = "";
  incidentDetailsArr = [];
  incidentDetailsObj = {};
  selectedIncidentsTypes: any;
  selectedIncidents: any;
  selectedViewTypes: any;

  @ViewChild(FirelineComponent) private firelineChild: FirelineComponent;

  ngOnInit(): void {
    this.ngxService.start();
    // this.logoImgUpload = this.clientData.client.logo;    
    this.mapDataSrcForm = this.mapParamsFormFG.group({      
      IncidentTypes: [null],
      uploadDataFile: [''],
      kmlName: [''],
      file: [null]
    });

    this.mapBaseStylesForm = this.mapBaseStylesFormFG.group({
      mapViewTypes: ["", [Validators.required]],
      mapType: ["", [Validators.required]],
      logo: new FormControl(''),
      headerBg: [this.clientData.client.color],
    });

    this.mapInfoForm = this.mapParamsFormFG.group({
      description: [""],
      mapName: ["", Validators.required]
    });

    // get IncidentTypes
    this.commonService.clientData().subscribe((res) => {   
      if (res) {
        this.incidenetTypes = res.data_types;
        this.clientLogo = res.client.logo;
        this.ngxService.stop();
      } else {
        console.log("error in the response", res);
      }
    });
    this.kmlFileName = '';

    // map data src validation
    this.mapDataSrcForm.valueChanges.subscribe((res) => {
      const validateIncidents = ( typeof res.IncidentTypes == 'object' ? Object.keys(res.IncidentTypes).length != 0 : (typeof res.IncidentTypes == 'undefined' ? false : true) );
      if (res.kmlName != "" && res.uploadDataFile != "") {
        this.dataSorcesValidation = false;
      } else if (validateIncidents) {
        this.dataSorcesValidation = false;
      } else if ((res.kmlName != "" || res.uploadDataFile == "") || (res.kmlName == "" || res.uploadDataFile != "")) {
        this.dataSorcesValidation = true;
      } else {
        this.dataSorcesValidation = true;
      }
    });
  }

  uploadDataFiles(event) {
    // console.log(event.target.files[0].size/1000000 + ' MB');
    // console.log(this.kmlFileNameErr)
    this.dataSorcesValidation = true;
    if (event.target.files && event.target.files[0]) {
      // Checking kml size
      var totalBytes = event.target.files[0].size;
      if(totalBytes/1000000 > 5){        
        this.kmlFileName = '';
        this.kmlFileNameErr = "Maximum upload file size is 5MB"
        return;
      }
      if (event.target.files[0].name.split('.')[1] == 'kml') {
        let inputFile = event.target.files[0];
        this.dataSrcUpload3 = event.target.files[0];
        this.kmlFileNameErr = '';
        this.kmlFileName = 'File: ' + event.target.files[0].name;
        var fileReader = new FileReader();
        fileReader.onload = async (e) => {
          let result = await this.extractGoogleCoords(e.target.result);
          this.dataSrcUpload = result;
          this.dataSrcUpload2 = e.target.result;
          this.mapDataSrcForm.patchValue({
            file: result
          });
          this.dataSorcesValidation = false;
          //this.logoImgUpload = event.target.result;
        }
        fileReader.readAsText(inputFile);
      } else {
        this.dataSorcesValidation = true;
        this.kmlFileName = '';
        this.kmlFileNameErr = 'Only upload KML file ( **' + event.target.files[0].name.split('.')[1] + ' is not allowed )';
        return false;
      } 
    }
  }

  async extractGoogleCoords(plainText) { 
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(plainText, "text/xml");
    return xmlDoc;
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      //this.clientLogo = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.logoImgUpload = event.target.result;
      }
    }
  }

  submitDSF(): void {
    this.ngxService.start();
    this.selectedIncidentsTypes = this.mapDataSrcForm.value;
    this.selectedIncidents = this.mapDataSrcForm.get('IncidentTypes').value;

    if (this.mapDataSrcForm.get('kmlName').value == "" || this.mapDataSrcForm.get('uploadDataFile').value == "") {
      this.mapDataSrcForm.patchValue({
        uploadDataFile: [""],
        kmlName: [""]
      });
      this.dataSrcUpload = "";
      this.dataSrcUpload2 = "";
    }

    if (this.mapDataSrcForm.valid) {
      this.dataSourceSection = false;
      this.baseStylesSection = true;
    }
    this.ngxService.stop();  
  }

  submitBSF(): void {
    this.ngxService.start();
    if (this.mapBaseStylesForm.valid) {
      this.baseStylesSection = false;
      this.mapPreviewSection = true;
    }

    // debugger;
    this.logoImgUpload = (this.logoImgUpload == '' ? environment.baseUrl + this.clientLogo : this.logoImgUpload);
    this.headerColor = this.mapBaseStylesForm.get('headerBg').value;
    let filterSlugType;
    // console.log(this.selectedIncidentsTypes)
    if(this.selectedIncidentsTypes.IncidentTypes != undefined){
      filterSlugType = this.selectedIncidentsTypes.IncidentTypes.map(list => ({ 'name': list.name, 'slug': list.slug, 'transformation_type': list.transformation_type, "source": list.source}));
    }

    if (this.mapBaseStylesForm.valid && this.mapDataSrcForm.valid) {
      this.enableCreate = false;
      this.getMapParams = Object.assign({},
        {
          incidentTypes: filterSlugType,
          kmlName: this.selectedIncidentsTypes.kmlName,
          mapType: this.mapBaseStylesForm.controls['mapType'].value,
          logo: this.logoImgUpload,
          headerBg: this.mapBaseStylesForm.controls['headerBg'].value,
          mapViewTypes: this.mapBaseStylesForm.controls['mapViewTypes'].value,
          kmlSource: this.dataSrcUpload2,
        });
      // this.ngxService.stop();
    }
  }

  async generateMap() {
    this.ngxService.start();

    // Get Map thumbnail Image
    let thumbImg = await this.firelineChild.mapScreenShortEvent().then(res =>
      res
    ).then(tImg => {
      return tImg;
    }).catch(e => {
      console.error(e)
    });

    const setMapObj = Object.assign({
      meta: this.getMapParams,
      name: this.mapInfoForm.controls.mapName.value,
      description: this.mapInfoForm.controls.description.value,
      mapThumbImg: thumbImg,
      client: this.clientData.client.id
    });
    this.mapService.postMapDetails(setMapObj).subscribe(
      (data) => {
        this.router.navigateByUrl('/myMaps');
      },
      (error) => {
        console.log('error');
      }
    );
  }

  prev($event: any, hideEl: string): void {
    if (hideEl == "baseStylesSection") {
      this.baseStylesSection = true;
      this.mapPreviewSection = false;
      this.selectedMapView = "";
    } else {
      this.dataSourceSection = true;
      this.baseStylesSection = false;
      this.mapPreviewSection = false;
    }
    this.ngOnInit();
  }

  //get getFormControl() { return this.mapBaseStylesForm.controls }

  // get mapDataSrcControl() {
  //   return this.mapDataSrcForm.controls;
  // }

  onMapViewChange($event: any) {
    this.selectedMapView = $event;
  }

}