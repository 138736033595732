import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable, from, of } from 'rxjs';

import { map } from 'rxjs/operators';

import * as L from 'leaflet';

import * as esri from 'esri-leaflet';

declare let TopoJSON;


// import * as topojson from 'topojson-client';

@Injectable({
  providedIn: 'root'
})
export class TransmissionService4 {

  constructor(private httpClient: HttpClient) { }

  selectedLayer: any;
  transData: any;
  topData: any;

  createTransmissionLayer4(apiUrl) {
    return this.httpClient.get(apiUrl).pipe(
      map(
        res => {
          this.topData = res;
          this.selectedLayer = L.layerGroup();

          this.selectedLayer = L.geoJSON(this.topData, {
                    style: function(feature){
              return {
                color: "#ff5e00",
                radius: 8,
                opacity: 8,
                weight: 20,
                fillColor: '#ff5e00',
                fillOpacity: 0.8
              }
            },
            onEachFeature: function (feature, layer) {
                if (feature.properties) {
            var strPopup = "<h4 class=\"backdrop\">" + feature.properties.substnname + "</h4><table class=\"pws\">";
            if (feature.properties.cabletype != "") {
                strPopup = strPopup + "<tr><td>Type: </td><td><b>" + feature.properties.assettype + "</td></tr>";
            }
            if (feature.properties.owner != "") {
                strPopup = strPopup + "<tr><td>Owner: </td><td><b>" + feature.properties.owner + "</td></tr>";
            }
            if (feature.properties.voltage != "") {
                strPopup = strPopup + "<tr><td>Capacity: </td><td><b>" + feature.properties.voltage + "</b></td></tr>";
            }
            if (feature.properties.loaddate != "") {
                strPopup = strPopup + "<tr><td>Date: </td><td><b>" + feature.properties.loaddate + "</b></td></tr>";
            }
            if (feature.properties.STATUS != "") {
                strPopup = strPopup + "<tr><td>Status: </td><td><b>" + "Operational" + "</b></td></tr>";
            }
            strPopup = strPopup + "</table>";
            layer.bindPopup(strPopup);
        }
}

        })


          console.log("this.selectedLayer == ", this.selectedLayer);
          return this.selectedLayer //this.selectedLayer;
        }
      )
    );
  }


}
