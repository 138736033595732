import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable, from, of } from 'rxjs';

import { map } from 'rxjs/operators';

import * as L from 'leaflet';

import * as esri from 'esri-leaflet';

declare let TopoJSON;


// import * as topojson from 'topojson-client';

@Injectable({
  providedIn: 'root'
})
export class BoundariesService2 {

  constructor(private httpClient: HttpClient) { }

  selectedLayer: any;
  transData: any;
  topData: any;

  createBoundariesLayer2(apiUrl) {
    return this.httpClient.get(apiUrl).pipe(
      map(
        res => {
          this.topData = res;
          this.selectedLayer = L.layerGroup();

          this.selectedLayer = L.geoJSON(this.topData, {
                    //style: function(feature){
		style:<any> function style(featureStyle: any) {
    		return {
                color: featureStyle.properties['fill'],
                weight: 2,
                opacity: 1,
                fillColor: featureStyle.properties['fill'],
                fillOpacity: 0.7
              }
		
			  /*return  { fillColor: 'blue',
    color: 'white',
    weight: 2,
    opacity: 1,
    fillOpacity: 0.5
  }*/
		    },
            onEachFeature: function (feature, layer) {
        if (feature.properties) {
            if (feature.properties.description != "") {
                var strPopup = "<h4 class=\"backdrop\">" + feature.properties.description + "</h4>";
            }
            layer.bindPopup(strPopup);
        }
},
        })


          
          console.log(res, "res");
          console.log("this.selectedLayer == ", this.selectedLayer);
          return this.selectedLayer //this.selectedLayer;
        }
      )
    );
  }


}
