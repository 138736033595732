<div class="container-fluid">
    <div class="row shadow-sm fixed-top bg-white">
        <div class="col-lg-2">
            <div class="header-section">
                <div class="kartasoft-logo">
                    <!-- <i class="bi bi-geo-alt"></i> Kartasoft.co -->
                    <img class="logo_kartasoft" src="../../assets/images/logo_kartasoft.png" />
                </div>
            </div>
        </div>

        <div class="col-lg-10">
            <!-- top right header -->
            <div class="row">
                <div class="col-lg-12 header-section d-flex justify-content-end py-2 border-bottom">
                    <ul class="nav header-right-nav">
                        <li class="nav-item">
                            <a class="nav-link" (click)="openModal()"> <i class="bi bi-stack"></i> Company Details</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" (click)="logOut();" routerLinkActive="active"> <i class="bi bi-box-arrow-right"></i> Sign Out</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="row wrapper-pan mt-5">
        <!-- left nav -->
        <div class="col-lg-2 left-menu">
            <!-- <div class="header-section">
                <div class="kartasoft-logo">
                    <img class="logo_kartasoft" src="../../assets/images/logo_kartasoft.png" />
                </div>
            </div> -->
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 mt-5 static-nav" [ngClass]="{show: shouldShow, hide: !shouldShow}">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/dashBoard" routerLinkActive="active"> <i class="bi bi-grid"></i> Dashboard</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/myMaps" routerLinkActive="active"> <i class="bi bi-geo-alt"></i> My Maps</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/createMap" routerLinkActive="active"> <i class="bi bi-file-earmark-plus"></i> Create a Map</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/mapTemplates" routerLinkActive="active"> <i class="bi bi-stack"></i> Templates</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="/reportIssue" routerLinkActive="active"> <i class="bi bi-funnel"></i> Report an Issue</a>
                </li>-->
                <li class="nav-item">
                    <a class="nav-link" routerLink="/outageReports" routerLinkActive="active"> <i class="bi bi-pencil-square"></i> Customer Reports</a>
                </li>
                <li class="nav-item" *ngIf="clientData.client.enable_history">
                    <a class="nav-link" routerLink="/historicalMaps" routerLinkActive="active"> <i class="bi bi-clock-history"></i> Historical Map</a>
                </li>
                <li class="nav-item" *ngIf="clientData.client.enable_reports">
                    <a class="nav-link" routerLink="/reportsDownload" routerLinkActive="active"> <i class="bi bi-download"></i> Downloads</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/users" routerLinkActive="active"> <i class="bi bi-people-fill"></i> Users</a>
                </li>
                <li style="margin-top: 80px;">
                    <div class="row">
                        <div class="col-md-12">
                    <p class="copy-right" style="padding:10px 10px;word-wrap: break-word;color: #ffffff;font-size: 12px;text-align: center !important;bottom: 100px !important;">© 2019 - 2023 <a href="https://www.kartasoft.co/about" rel="noopener">Karta Software</a> Inc.&nbsp; | <a href="https://www.kartasoft.co/about" rel="noopener">Karta Software</a> Pty Ltd. ABN 30 642 168 732 | <a href="https://www.kartasoft.co/toc-2" rel="noopener" target="_blank">All rights reserved</a> | <a href="https://www.kartasoft.co/toc-2" rel="noopener" target="_blank">Various trademarks held by their respective owners</a> <span> | <a href="https://www.kartasoft.co/toc" rel="noopener" target="_blank">Terms &amp; Conditions</a> | <a data-insert="true" href="https://www.kartasoft.co/toc-1" rel="noopener" target="_blank">Privacy Policy&nbsp;</a></span></p>
                </div>
            </div>
                </li>
                <!-- <li>
                    <p class="copy-right" style="color: #ffffff;font-size: 12px;text-align: center !important;bottom: 100px !important;">© 2019 - 2022 <a href="https://www.kartasoft.co/about" rel="noopener">Karta Software</a> Inc.&nbsp; | <a href="https://www.kartasoft.co/about" rel="noopener">Karta Software</a> Pty Ltd. ABN 30 642 168 732 | <a href="https://www.kartasoft.co/toc-2" rel="noopener" target="_blank">All rights reserved</a> | <a href="https://www.kartasoft.co/toc-2" rel="noopener" target="_blank">Various trademarks held by their respective owners</a> <span> | <a href="https://www.kartasoft.co/toc" rel="noopener" target="_blank">Terms &amp; Conditions</a> | <a data-insert="true" href="https://www.kartasoft.co/toc-1" rel="noopener" target="_blank">Privacy Policy&nbsp;</a></span></p>
                </li> -->
                <!-- <p class="copy-right" style="position: absolute !important;color: #ffffff;font-size: 12px;text-align: center !important;bottom: 100px !important;"> -->
                    <!-- © 2019 - 2022 <a href="https://www.kartasoft.co/about" rel="noopener">Karta Software</a>Inc. | Karta Software Pty Ltd. ABN 30 642 168 732 | All rights reserved Various trademarks held by their respective owners | Terms & Conditions | Privacy Policy</p> -->
                    <!-- <p class="copy-right" style="position: absolute !important;color: #ffffff;font-size: 12px;text-align: center !important;bottom: 100px !important;">© 2019 - 2022 <a href="https://www.kartasoft.co/about" rel="noopener">Karta Software</a> Inc.&nbsp; | <a href="https://www.kartasoft.co/about" rel="noopener">Karta Software</a> Pty Ltd. ABN 30 642 168 732 | <a href="https://www.kartasoft.co/toc-2" rel="noopener" target="_blank">All rights reserved</a> | <a href="https://www.kartasoft.co/toc-2" rel="noopener" target="_blank">Various trademarks held by their respective owners</a> <span> | <a href="https://www.kartasoft.co/toc" rel="noopener" target="_blank">Terms &amp; Conditions</a> | <a data-insert="true" href="https://www.kartasoft.co/toc-1" rel="noopener" target="_blank">Privacy Policy&nbsp;</a></span></p> -->
            </ul>
        </div>

        <div class="col-lg-10 main-side">
            <!-- top right header -->
            <!-- <div class="row">
                <div class="col-lg-12 header-section d-flex justify-content-end my-2 border-bottom shadow-sm">
                    <ul class="nav header-right-nav">
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/dashBoard" routerLinkActive="active"> <i class="bi bi-stack"></i> Company Details</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" (click)="logOut();" routerLinkActive="active"> <i class="bi bi-box-arrow-right"></i> Sign Out</a>
                        </li>
                    </ul>
                </div>
            </div> -->
            <!-- main contents wrap -->
            <div class="row">
                <div class="col-lg-12 px-5">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Company Details Popup -->
<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':display}">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Company Details</h4>
                <button type="button"(click)="onCloseHandled()" aria-label="Close" class="btn-close close pull-right"><span _ngcontent-cpr-c77="" aria-hidden="true" class="visually-hidden">×</span></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12">
                        <p><span style="font-size: 16px !important;color: #2a4659 !important;font-weight: normal !important;margin-right: 8px;">Company Name:</span><span style="font-size: 14px !important;color: #000000 !important;font-weight: normal !important;">KARTA SOFTWARE PTY LIMITED</span></p>
                        <p><span style="font-size: 16px !important;color: #2a4659 !important;font-weight: normal !important;margin-right: 8px;">Address:</span><span style="font-size: 14px !important;color: #000000 !important;font-weight: normal !important;">307/199-201 Regent St Redfern, NEW SOUTH WALES, 2016 Australia</span></p>
                        <p><span style="font-size: 16px !important;color: #2a4659 !important;font-weight: normal !important;margin-right: 8px;">Company Number:</span><span style="font-size: 14px !important;color: #000000 !important;font-weight: normal !important;">ABN 30 642 168 732</span></p>
                        <p><span style="font-size: 16px !important;color: #2a4659 !important;font-weight: normal !important;margin-right: 8px;">Support:</span><span style="font-size: 14px !important;color: #000000 !important;font-weight: normal !important;">support@kartasoft.co</span></p>
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="onCloseHandled()" >Close</button>
            </div> -->
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>
