import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable, from, of } from 'rxjs';

import { map } from 'rxjs/operators';

import * as L from 'leaflet';
import { resolve } from 'dns';
import { rejects } from 'assert';

@Injectable({
  providedIn: 'root'
})
export class FireServiceService {

  createdFireLayer: any;
  selectedLayer: any;
  fireResp : any;


  constructor(private httpClient: HttpClient) { }
  createFireLayer(firJsonApi) {
    return this.httpClient.get(firJsonApi).pipe(
      map(
        res => {
          // fire data
          this.fireResp = res;
          this.selectedLayer = L.layerGroup();
          const fireIcon = new L.Icon({
            iconSize: [20, 32],
            iconAnchor: [10, 32],
            popupAnchor: [1, -12],
            iconUrl: 'assets/images/fire2-40.png'
          });
          const fireIconGreen = new L.Icon({
            iconSize: [20, 32],
            iconAnchor: [10, 32],
            popupAnchor: [1, -12],
            iconUrl: 'assets/images/fire2-green-40.png'
          });
          const fireIconBlue = new L.Icon({
            iconSize: [20, 32],
            iconAnchor: [10, 32],
            popupAnchor: [1, -12],
            iconUrl: 'assets/images/fire2-blue-40.png'
          });
          const infoIcon = new L.Icon({
            iconSize: [20, 20],
            iconAnchor: [10, 20],
            popupAnchor: [1, -12],
            iconUrl: '/assets/images/information.png'
          });

          const lineStyleFire = {
            "color": "#eeeeee",
            "weight": 1,
            "opacity": 0.65,
            "fillColor": '#f3ee67',
            "fillOpacity": 0.5
          };

          this.selectedLayer = L.geoJSON(this.fireResp, {
            onEachFeature: function (feature, layer) {
              if (feature.properties && feature.properties.body) {
                layer.bindPopup(feature.properties.body + "<dl class=\"dates\"><dt>Last Update<br/>(At Source)</dt><dd>" + new Date(feature.properties.sourceTimeStamp) + "</dd><dt>Last Checked</dt><dd>" + new Date(feature.properties.fetchTimeStamp) + "</dd></dl>");
              }
            },
            pointToLayer: function (feature, latlng) {
              let marker;
              let outputMarker = false;
              switch (feature.properties.type) {
                case "Bushfire":
                  marker = L.marker(latlng, { icon: fireIcon });
                  outputMarker = true;
                  break;
                case "Bush Fire":
                  marker = L.marker(latlng, { icon: fireIcon });
                  outputMarker = true;
                  break;
                case "Grass Fire":
                  marker = L.marker(latlng, { icon: fireIcon });
                  outputMarker = true;
                  break;
                case "Grass and Scrub Fire":
                  marker = L.marker(latlng, { icon: fireIcon });
                  outputMarker = true;
                  break;
                case "Vegetation Fire":
                  marker = L.marker(latlng, { icon: fireIcon });
                  outputMarker = true;
                  break;
                case "Burn Off":
                  marker = L.marker(latlng, { icon: fireIconGreen });
                  outputMarker = true;
                  break;
                case "Burn off":
                  marker = L.marker(latlng, { icon: fireIconGreen });
                  outputMarker = true;
                  break;
                case "Hazard Reduction":
                  marker = L.marker(latlng, { icon: fireIconGreen });
                  outputMarker = true;
                  break;
                case "Planned Burn":
                  marker = L.marker(latlng, { icon: fireIconGreen });
                  outputMarker = true;
                  break;
                case "Permit Burn":
                  marker = L.marker(latlng, { icon: fireIconGreen });
                  outputMarker = true;
                  break;
                case "Structure Fire":
                  marker = L.marker(latlng, { icon: fireIconBlue });
                  outputMarker = true;
                  break;
                case "Smoke Complaint/Illegal Burn":
                  marker = L.marker(latlng, { icon: fireIconBlue });
                  outputMarker = true;
                  break;
                case "Fire":
                  marker = L.marker(latlng, { icon: fireIconBlue });
                  outputMarker = true;
                  break;
                default:
                  marker = L.marker(latlng, { icon: infoIcon });
                  outputMarker = true;
              }
              if (outputMarker) {
                return marker;
              }
            },
            style: lineStyleFire
          });
          return this.selectedLayer;
        }
      )
    );
  }
}
