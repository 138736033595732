import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//routing components

import { CreateMapComponent } from './create-map/create-map.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MyMapsComponent } from './my-maps/my-maps.component';
import { EditmapComponent } from './editmap/editmap.component';
import { MaptemplatesComponent } from './maptemplates/maptemplates.component';
import { LoginComponent } from './login/login.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
// import { CustomerReportComponent } from './customer-reports/customerreports.component';
import { OutageReportComponent } from './outage-report/outage-report.component';
import { UsersComponent } from './users/users.component';
import { ViewPublicMapComponent } from './view-public-map/view-public-map.component';
import { HistoricalMapsComponent } from './historical-maps/historical-maps.component';
import { ReportDownloadsComponent } from './report-downloads/report-downloads.component';

// Autuguard Service
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'public-maps/:slugName', component: ViewPublicMapComponent },
  { path: 'myMaps/:slugName', component: ViewPublicMapComponent, canActivate: [AuthGuard] },
  // { path: 'editMaps/:id', component: EditmapComponent },
  {
    path: '',
    component: SideMenuComponent,
    children: [     
      { path: 'dashBoard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
      },
      { path: 'createMap',
       component: CreateMapComponent,
       canActivate: [AuthGuard]
      },
      { path: 'myMaps',
       component: MyMapsComponent,
       canActivate: [AuthGuard]
      },
      { path: 'mapTemplates',
       component: MaptemplatesComponent,
       canActivate: [AuthGuard]
      },
      { path: 'editMaps',
       component: EditmapComponent,
       canActivate: [AuthGuard]
      },
      // { path: 'customerReports', component: CustomerReportComponent },
      { path: 'outageReports',
       component: OutageReportComponent,
       canActivate: [AuthGuard]
      },
      { path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuard]
      },
      { path: 'editMaps/:slugName',
        component: EditmapComponent,       
        canActivate: [AuthGuard]
      },
      { path: 'historicalMaps',
        component: HistoricalMapsComponent,       
        canActivate: [AuthGuard]
      }, 
      { path: 'reportsDownload',
      component: ReportDownloadsComponent,       
      canActivate: [AuthGuard]
    },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const RoutingMenuComponentsList = [
  DashboardComponent, CreateMapComponent, MyMapsComponent, LoginComponent, EditmapComponent, OutageReportComponent,
  UsersComponent
];
