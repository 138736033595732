import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../app-config';

@Injectable({
  providedIn: 'root'
})
export class DashboardSerivice {

  constructor(private httpClient: HttpClient) { }

  public getDashboardData = (): Observable<any> => {
    return this.httpClient.get<any>(AppConfig.dashboardData);
  }


}
