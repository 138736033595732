import { Component, OnInit } from '@angular/core';

// Services
import { OutageService } from '../services/outage.service';
import { DashboardSerivice } from '../services/dashboard.service';

// Libs
import * as L from 'leaflet';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
declare let Chart: any; //declare chart

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  canvas: any;
  ctx: any;
  canvas2: any;
  ctx2: any;
  canvas3: any;
  logs;
  ctx3: any;
  recentOutage: any;
  public showRuleContent: boolean[] = [];
  randomColors = ["#e57373", "#ba68c8", "#90caf9", "#4db6ac", "#dce775", "#ffb74d", "#b0bec5", "#81c784"];
  colorsArray = [];
  powerstationArr = [];
  powerLabel: any;
  powerValues: any;
  totalPlannedOutages: number;
  totalUnPlannedOutages: number;
  totalPowerstations: any;
  outagesLabel = [];
  outagesArray: any;
  chartOneCTX: any;
  chartTwoCTX: any;
  chartThreeCTX: any;
  oneAtATime = true;
  clientData = JSON.parse(localStorage.getItem("client"));

  constructor(
    private outageService: OutageService,
    private dashboardService: DashboardSerivice,
    private ngxService: NgxUiLoaderService,
  ) { }


  ngOnInit(): void {

    this.ngxService.start();

    // Geting Dashboard Data
    this.dashboardService.getDashboardData().subscribe(async data => {
      await this.chartOne(data);
      await this.chartTwo(data);
      // this.logs = data;
      var mapName = [];
      this.clientData.data_types.forEach(dataOne => {
        const findEmp = data.find(
            dataTwo => dataTwo.type === dataOne.slug
          );
        
        if (findEmp) {
          let message = findEmp.type == "fire-incidents" ? Object.keys(findEmp.meta).map(function(x){return findEmp.meta[x]["remarks"];}).join(' ,') : findEmp.remarks;
          mapName.push({
            msg: message.split(',').map((tag) => tag.trim()).filter((tag) => tag.length !== 0),
            name: dataOne.name,
            ...dataOne,
            ...findEmp
          });
        }
      });
      // console.log(mapName)
      this.logs = mapName;
      this.totalPowerstations = Object.values(data[0].meta).length;
      var array = []
      var planned = 0
      var unplanned = 0
      data.forEach(function (res) {
        if (res.type.split('-').pop() == 'outages')
          array.push(res);
        if (res.meta.Planned)
          planned += res.meta.Planned;
        if (res.meta.Unplanned)
          unplanned += res.meta.Unplanned;
      });
      this.outagesArray = array;
      this.totalPlannedOutages = planned;
      this.totalUnPlannedOutages = unplanned;
    });


    this.outageService.outageList().subscribe((res) => {
      if (res) {
        // console.log(res);
        this.recentOutage = res.reduce((r, a) => {
          r[a.postcode] = [...r[a.postcode] || [], a];
          return r;
        }, {});
        //  console.log("group", group);
        this.ngxService.stop();
        // this.recentOutage = res;        
      } else {
        console.log("error in the response", res);
      }
    });

    // Sample
    // this.canvas3 = document.getElementById('sample');
    // this.ctx3 = this.canvas3.getContext('2d');


  }

  chartOne(data) {
    var planned = 0
    var unplanned = 0;
    data.forEach(function (item) {
      if (item.meta.Planned)
        planned += item.meta.Planned;
      if (item.meta.Unplanned)
        unplanned += item.meta.Unplanned;
    })

    var plannedOutages = planned;
    var unPlannedOutages = unplanned;
    // Outages
    this.canvas = document.getElementById('outages');
    this.ctx = this.canvas.getContext('2d');
    this.chartOneCTX = new Chart(this.ctx, {
      type: 'doughnut',
      data: {
        labels: ['Planned', 'Unplanned'],
        datasets: [
          {
            label: 'Active Angular Vesrions',
            data: [plannedOutages, unPlannedOutages],
            backgroundColor: ['#69c0f0', '#f47ca1'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        legend: {
          display: true,
        },
        responsive: true,
        display: true,
        scales: {
          y: {
            ticks: {
              beginAtZero: true,
            },
            //   yAxes: [{
            //     ticks: {
            //         beginAtZero: true
            //     }
            // }]
          },
        },
      },
    });



  }

  chartTwo(data: any) {
    this.powerstationArr = data[0].meta;
    this.powerLabel = Object.keys(data[0].meta);
    this.powerValues = Object.values(data[0].meta);

    // Power Stations
    this.canvas2 = document.getElementById('power_stations');
    this.ctx2 = this.canvas2.getContext('2d');
    new Chart(this.ctx2, {
      type: 'polarArea',
      data: {
        labels: Object.keys(data[0].meta),
        datasets: [
          {
            label: 'Power Stations',
            data: Object.values(data[0].meta),
            backgroundColor: this.createColorArr(Object.values(data[0].meta).length),
            borderWidth: 1,
          },
        ],
      },
      options: {
        legend: {
          display: true,
        },
        responsive: true,
        display: true,
        scales: {
          y: {
            ticks: {
              beginAtZero: true,
            },
            // yAxes: [{
            //     ticks: {
            //         beginAtZero: true
            //     }
            // }]
          },
        },
      },
    });
  }

  chartThree() {
    // chart Three
    new Chart(this.ctx3, {
      type: 'pie',
      data: {
        labels: ['Angular 11', 'Angular 10', 'Angular 9'],
        datasets: [
          {
            label: 'Active Angular Vesrions',
            data: [85, 100, 60],
            backgroundColor: ['#69c0f0', '#f47ca1', 'orange'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        legend: {
          display: true,
        },
        responsive: true,
        display: true,
        scales: {
          y: {
            ticks: {
              beginAtZero: true,
            },
            // yAxes: [{
            //     ticks: {
            //         beginAtZero: true
            //     }
            // }]
          },
        },
      },
    });
  }

  createColorArr(arrayLen) {
    for (var i = 0; i < arrayLen; i++) {
      this.colorsArray.push(this.randomColors[i]);
      // this.colorsArray.push(this.randomColors[Math.floor(Math.random() * i)]);
    }
    return this.colorsArray;
  }

  toggle(index: any) {  
    if(!this.showRuleContent[index]){
      this.showRuleContent = [];
    }    
    this.showRuleContent[index] = !this.showRuleContent[index];
  }

  changeStations(stations: any) {
    console.log(stations);
  }

  changeOutages(outages) {
    var array = []
    this.outagesArray.forEach(function (res) {
      if (res.type == outages.target.value) {
        array.push(res);
      }
    });
    this.chartOneCTX.destroy()
    this.chartOne(array.length == 0 ? this.outagesArray : array);
  }

  plannedCount(itemVal: any) {
    var count = itemVal.reduce(function (n, val) {
      return n + (val.is_reported == false && val?.is_planned == true);
    }, 0);

    return count;
  }

  unPlannedCount(itemVal: any) {
    var count = itemVal.reduce(function (n, val) {
      return n + (val?.is_reported == false && val?.is_planned == false);
    }, 0);

    return count;
  }

  reportedCount(itemVal: any) {
    var count = itemVal.reduce(function (n, val) {
      return n + (val.is_reported === true);
    }, 0);

    return count;
  }
}
