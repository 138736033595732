import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PreviousRouteService } from '../services/previous-route.service';
import {Location} from '@angular/common'; 

import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

// Services;
import { MapService } from '../services/map.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-my-maps',
  templateUrl: './my-maps.component.html',
  styleUrls: ['./my-maps.component.css']
})
export class MyMapsComponent implements OnInit {

  public myMaps: any;
  bsModalRef: BsModalRef;
  public visibleTab: any;
  public mapAllData: any;
  mapUpdateSubscription: Subscription;

  constructor(
    private ngxService: NgxUiLoaderService,
    private modalService: BsModalService,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mapService: MapService,
    private previousRouteService: PreviousRouteService) { }

  tempData: any;
  mapUpdate: any;
  ngOnInit(): void {
    this.ngxService.start(); // start foreground spinner of the master loader with 'default' taskId
    this.visibleTab = 'all';
    const is_public = this.visibleTab == 'published' ? true : false;
    // Get Map Details
    this.mapService.getMapList().subscribe((res) => {   
      if (res) {
        this.mapAllData = res;
        this.myMaps = res;
        this.ngxService.stop();
      } else {
        console.log("error in the response", res);
      }
    });

    this.previousRouteService.currentMessage.subscribe(passData => this.tempData = passData);
    this.mapUpdateSubscription = this.previousRouteService.currentMapStatus.subscribe(mapData => this.mapUpdate = mapData );
    if (this.mapUpdate) {
      const slugName = this.mapUpdate['slugName'] != '' ? this.mapUpdate['slugName'] : '';
      this.editMaps(slugName);
      this.previousRouteService.reloadMyMap('');
      if (this.mapUpdateSubscription) {
        this.mapUpdateSubscription.unsubscribe();
      }
    }
  }

  elmentClicke(): void {
    alert("clicked");
  }

  goToCreate(): void {
    this.router.navigate(['createMap']);
  }

  editMaps(slugName): void {    
    this.router.navigate(['editMaps', slugName]);
  }

  viewMap(slugName) {
    window.open('myMaps/' + slugName, '_blank');
  }

  viewPublicMap(slug) {
    const publicURL = JSON.parse(localStorage.getItem('client'));
    window.open('https://'+ publicURL.client.public_url + '.kartasoft.co/public-maps/'+ slug, '_blank');
  }

  deleteMaps(mapId){
    // if(confirm("Are you sure to delete a map")) {
      this.ngxService.start();
      // Get Map Details
      this.mapService.deleteMap(mapId).subscribe((res) => {   
        this.ngxService.stop();
        this.ngOnInit();      
      if (res) {      
        // alert("test")
        this.ngOnInit();      
      } else {
        console.log("error in the response", res);
      }
    });    
    // }    
  }

  showTabs(tabs) {
    this.visibleTab = tabs;
    const is_public = tabs == 'published' ? true : false;
    // alert(tabs);
    if(tabs != 'all'){
      this.myMaps = this.mapAllData.filter(function(hero) {
        return hero.is_public == is_public;
      });
    }else{
      this.myMaps = this.mapAllData;
    }  
  }

  publishMap(slug){
    const mapData = {
      is_public: true,
    };

    const initialState = {
      title: 'Confirm',
      contents: 'you want to publish a map',
      confirmAction: 'confirm',
      saveBtnLable: 'Yes, publish '
    };

    this.bsModalRef = this.modalService.show(ConfirmModalComponent, { initialState });
    this.bsModalRef.content.confirmationDetails.subscribe(result => {                  
      this.ngxService.start();
      if (result) {        
        this.mapService.patchMapDetails(mapData, slug).subscribe(
          (data) => {
            // console.log('suc');
            // this.visibleTab = 'published';
            this.ngOnInit();
          },
          (error) => {
            console.log('error');
          }
        );
      }
    });
  }

  unpublishMap(slug){
    const mapData = {
      is_public: false,
    };

    const initialState = {
      title: 'Confirm',
      contents: 'you want to unpublish a map',
      confirmAction: 'confirm',
      saveBtnLable: 'Yes, unpublish '
    };

    this.bsModalRef = this.modalService.show(ConfirmModalComponent, { initialState });
    this.bsModalRef.content.confirmationDetails.subscribe(result => {                  
      this.ngxService.start();
      if (result) {        
        this.mapService.patchMapDetails(mapData, slug).subscribe(
          (data) => {
            console.log('suc');  
            // this.visibleTab = 'drafts';      
            this.ngOnInit();
          },
          (error) => {
            console.log('error');
          }
        );
      }
    });
  }

}
