import { Component, OnInit, AfterViewInit, Input, ElementRef, Renderer2, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import * as L from 'leaflet';
import * as esri from 'esri-leaflet';
import "leaflet.locatecontrol";
import "leaflet-geonames";
import 'leaflet';
import 'leaflet-velocity';
declare var L: any;// Declare leaflet lib and plugin

import "esri-leaflet-geocoder";
import "leaflet-easybutton";
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/images/marker-icon-2x.png";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { kml } from "@tmcw/togeojson";
import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter';
import { map } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';
declare var V: any;
import * as topojson from 'topojson-client';

// Services
import { FireServiceService } from '../services/fire-service.service';
import { AusgrideService } from '../services/ausgride.service';
import { TransmissionService } from '../services/transmission.service';
import { TransmissionService2 } from '../services/transmission2.service';
import { TransmissionService3 } from '../services/transmission3.service';
import { TransmissionService4 } from '../services/transmission4.service';
import { PowerStationService } from '../services/powerstation.service';
import { PipelinesService } from '../services/pipelines.service';
import { LiveOutageService } from '../services/liveoutage.service';
import { BoundariesService } from '../services/boundaries.service';
import { CommonService } from '../services/common.service';
import { BomService } from './../services/bom.service';
import { IncidentTransportService } from '../services/incidenttransport.service';
import { MajorEventTransportService } from './../services/majoreventtransport.service';
import { RisingMainService } from './../services/risingmain.service';
import { OmanMainService } from './../services/omanmain.service';
import { BoundariesService2 } from '../services/boundaries2.service';
import { OmanOilGasService } from '../services/omanoilgas.service';

declare var myExternalJs: any;


declare var V: any; // Declare leaflet lib and plugin

@Component({
  selector: 'app-fireline',
  templateUrl: './fireline.component.html',
  styleUrls: ['./fireline.component.css']
})
export class FirelineComponent implements OnInit, AfterViewInit {

  @Input() mapDetails: any;
  @Input() processFileData: any;
  @Input() fromTemplates: any;

  public $: any;
  public layerControl: any;
  public map;
  public fireData: any;
  public selectedLayer;
  public selectedLayer2;
  public selectedLayer3;
  public selectedLayer4;
  public selectedLayer5;
  public kmlSelectedLayer4: any = "";
  public addLayerToMap: any = "";
  public addLayerToMapTopoJson: any = "";
  public genericSelectedLayer4: any = "";
  public jsonResult: any;
  public overlayLayers = {};
  public validLayers = [];
  public simpleMapScreenshoters: any;
  currentMapViewUrl: any;
  currentMapView: any;
  public initMap() {
    // debugger;
    this.ngxsService.start();
    //Satellite
    var mbSatelliteUrl = "https://api.mapbox.com/styles/v1/kartasoftsolutions/cksio94n5a7zt17pj91gbx9y3/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoia2FydGFzb2Z0c29sdXRpb25zIiwiYSI6ImNrc2lsNnptZjBnbGEyd3BlZTY0ZTF6eWMifQ.zTXl6RtSImuNwejn9heQ5Q";
    //Streets
    var mbStreetsUrl = "https://api.mapbox.com/styles/v1/kartasoftsolutions/cksio9pwn6m8f17nxqg99w0r9/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoia2FydGFzb2Z0c29sdXRpb25zIiwiYSI6ImNrc2lsNnptZjBnbGEyd3BlZTY0ZTF6eWMifQ.zTXl6RtSImuNwejn9heQ5Q";
    //Terrain
    var mbTerrainUrl = "https://api.mapbox.com/styles/v1/kartasoft/ckdfp3qwc0oae1immsjbg71cc/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoia2FydGFzb2Z0IiwiYSI6ImNrZDlzdnZyZTBtZjYyd21rOHNmeGoxdGwifQ.MnmnZdxvkYNz6zIzxR120w";

    // const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //   maxZoom: 11,
    //   minZoom: 3,
    //   attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    // });

    let lyStreets = L.tileLayer(mbStreetsUrl);
    let lyrTerrain = L.tileLayer(mbTerrainUrl);
    let lyrSatellite = L.tileLayer(mbSatelliteUrl);

    // Set Map styles based on selection
    //   switch ( this.mapDetails.mapViewTypes ) {
    //     case 'Street':
    //       this.currentMapViewUrl = mbStreetsUrl;
    //       this.currentMapView = lyStreets;
    //         break;
    //     case 'Terrain':
    //       this.currentMapViewUrl = mbTerrainUrl;
    //       this.currentMapView = lyrTerrain;
    //         break;
    //     case 'Satellite':
    //       this.currentMapViewUrl = mbSatelliteUrl;
    //       this.currentMapView = lyrSatellite;
    //         break;
    //     default:
    //       this.currentMapViewUrl = mbStreetsUrl;
    //       this.currentMapView = lyStreets;
    //         break;
    //  }
    this.currentMapViewUrl = this.mapDetails.mapViewTypes == 'Street' ? mbStreetsUrl : this.mapDetails.mapViewTypes == 'Terrain' ? mbTerrainUrl : mbSatelliteUrl;
    this.currentMapView = this.mapDetails.mapViewTypes == 'Street' ? lyStreets : this.mapDetails.mapViewTypes == 'Terrain' ? lyrTerrain : lyrSatellite;

    var map_center = [-27.7978, 134.9605];

/*    console.log(this);
    console.log(this.mapDetails);
    if (this.mapDetails.mapCenter) {
    console.log("Map center:");

    var mapCenterArray = this.mapDetails.mapCenter.split(",");
    
    // Convert latitude and longitude strings to numbers
    var latitude = parseFloat(mapCenterArray[0]);
    var longitude = parseFloat(mapCenterArray[1]);

    map_center = [latitude, longitude];
    console.log("Map center:", map_center);

    } else {
      map_center = [-27.7978, 134.9605];
      console.log("HERE");
    }
*/
    this.map = L.map('map', {
      center: map_center,
      zoom: 5,
      layers: [this.currentMapView]
    });

    let mapTiles;
    let gasPipes;

    // var markers = new L.MarkerClusterGroup();

    // debugger;

    if (this.mapDetails) {
      for (let objType of Object.keys(this.mapDetails)) {
        // console.log(this.mapDetails.incidentTypes);
        if (objType == 'incidentTypes' && this.mapDetails.incidentTypes != undefined) {
          this.mapDetails.incidentTypes.forEach(srcView => {
            // KML Layer
            if (srcView.transformation_type == 'kml') {
              // alert("kml");
              this.createKMLLayers(srcView.source, srcView.name).then((res) => res).then(async (kmlPromise) => {
                this.kmlSelectedLayer4 = kmlPromise;
                this.layerControl.addOverlay(this.kmlSelectedLayer4, srcView.name);
                if (this.fromTemplates === undefined) {
			this.kmlSelectedLayer4.addTo(this.map);
		}
		this.ngxsService.stop();
              });
            }
            // Wind Velocity
            else if (srcView.transformation_type == 'wind') {
              // Read JSON DATA and use it for velocity layer
              // debugger;
              this.httpClient.get(srcView.source).subscribe(data => {
                // console.log('velocity data', data);
                const velocity = L.velocityLayer({
                  displayValues: true,
                  displayOptions: {
                    velocityType: "Global Wind",
                    displayPosition: "bottomleft",
                    displayEmptyString: "No wind data"
                  },
                  data: data,
                  maxVelocity: 12
                });
                this.layerControl.addOverlay(velocity, "Wind Velocity");
		if (this.fromTemplates === undefined) {
			velocity.addTo(this.map);
                }
                this.ngxsService.stop();
              });
            }
            // Transmission Layer
            else if (srcView.transformation_type == 'transmission') {

              L.TopoJSON = L.GeoJSON.extend({
                addData: function (data) {
                  var geojson, key;
                  if (data.type === "Topology") {
                    // console.log(data)
                    for (key in data.objects) {
                      if (data.objects.hasOwnProperty(key)) {
                        geojson = topojson.feature(data, data.objects[key]);
                        L.GeoJSON.prototype.addData.call(this, geojson);
                      }
                    }
                    return this;
                  }
                  L.GeoJSON.prototype.addData.call(this, data);
                  return this;
                }
              });

              L.topoJson = function (data, options) {
                return new L.TopoJSON(data, options);
              };
              //create an empty geojson layer
              //with a style and a popup on click
              this.addLayerToMapTopoJson = L.topoJson(null, {
                style: function (feature) {
                  return {
                    color: "#990000",
                    opacity: 1,
                    weight: 2,
                    fillColor: '#990000',
                    fillOpacity: 0.8
                  }
                },
                onEachFeature: this.onEachTransmissionFeature
              })

              //define a function to get and parse geojson from URL
              async function getGeoData(url) {
                let response = await fetch(url);
                let data = await response.json();
                return data;
              }
              //fetch the geojson and add it to our geojson layer
              getGeoData(srcView.source).then(async (data) => {
                await this.addLayerToMapTopoJson.addData(data)
                this.layerControl.addOverlay(this.addLayerToMapTopoJson, srcView.name);
		if (this.fromTemplates === undefined) {
                	this.addLayerToMapTopoJson.addTo(this.map);
		}
                this.ngxsService.stop();
              });
            }
            // Transmission Layer 2
            else if (srcView.transformation_type == 'transmission2') {
              this._transmissionService2.createTransmissionLayer2(srcView.source).subscribe(async res => {
                //alert("trans");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
              	if (this.fromTemplates === undefined) {  
			this.addLayerToMap.addTo(this.map);
		}
		this.ngxsService.stop();
              });
            }

            // Transmission_National_Substations
            else if (srcView.transformation_type == 'transmission3') {
              this._transmissionService3.createTransmissionLayer3(srcView.source).subscribe(async res => {
                //alert("trans");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
			this.addLayerToMap.addTo(this.map);
		}
		this.ngxsService.stop();
              });
            }
            // Transmission_Transgrid_Substations_NSW
            else if (srcView.transformation_type == 'transmission4') {
              this._transmissionService4.createTransmissionLayer4(srcView.source).subscribe(async res => {
                //alert("trans");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
			this.addLayerToMap.addTo(this.map);
		}
		this.ngxsService.stop();
              });
            }
            // Transport major event
            else if (srcView.transformation_type == 'incidenttransport') {
              this._incidenttransportService.createIncidentTransport(srcView.source).subscribe(async res => {
                //alert("trans");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
			this.addLayerToMap.addTo(this.map);
		}
		this.ngxsService.stop();
              });
            }
            // Transport incident
            else if (srcView.transformation_type == 'majoreventtransport') {
              this._majoreventtransportService.createMajorEventTransport(srcView.source).subscribe(async res => {
                //alert("trans");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
			this.addLayerToMap.addTo(this.map);
		}
		this.ngxsService.stop();
              });
            }
	    // sydney rising main 
	    else if (srcView.transformation_type == 'risingmain') {
              this._risingmainService.createRisingMain(srcView.source).subscribe(async res => {
                //alert("trans");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
                        this.addLayerToMap.addTo(this.map);
                }
                this.ngxsService.stop();
              });
            }
            else if (srcView.transformation_type == 'omanmain') {
              this._omanmainService.createOmanMain(srcView.source).subscribe(async res => {
                //alert("trans");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
                        this.addLayerToMap.addTo(this.map);
                }
                this.map.setView([21.4735, 55.9754], 6);
                this.ngxsService.stop();
              });
            }
            // GeoJson Layer
            else if (srcView.transformation_type == 'geojson') {
              //  console.log(this.requestDataAndWait(srcView.source));
              this._boundariesService.createGeoJsonLayer(srcView.source).subscribe(async res => {
                //  alert("geoJson");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
			this.addLayerToMap.addTo(this.map);
		}
                // this.addLayerToMap.addTo(markers);
                this.ngxsService.stop();
              });
            }
	    // Boundaries 2
	    else if (srcView.transformation_type == 'boundary2') {
              //  console.log(this.requestDataAndWait(srcView.source));
              this._boundariesService2.createBoundariesLayer2(srcView.source).subscribe(async res => {
                //  alert("geoJson");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
                        this.addLayerToMap.addTo(this.map);
                }
                // this.addLayerToMap.addTo(markers);
                this.ngxsService.stop();
              });
            }
            // Ausgrid Layer
            else if (srcView.transformation_type == 'ausgrid') {
              // debugger;
              this._ausgridservice.createAusGrideLayer(srcView.source).subscribe(async res => {
                // alert("ausgrid");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
			this.addLayerToMap.addTo(this.map);
		}
                this.ngxsService.stop();
              });
            }
            // Endeavour
            else if (srcView.transformation_type == 'endeavour') {
              this._commonService.createEndavour(srcView.source).subscribe(async res => {
                // alert("ausgrid");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
			this.addLayerToMap.addTo(this.map);
		}
		this.ngxsService.stop();
              });
            }
            // Essential Current
            else if (srcView.transformation_type == 'essential-current') {
              this._commonService.createEssentialCurrent(srcView.source).subscribe(async res => {
                // alert("ausgrid");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
			this.addLayerToMap.addTo(this.map);
		}
		this.ngxsService.stop();
              });
            }
            // Essential Future
            else if (srcView.transformation_type == 'essential-future') {
              this._commonService.createEssentialFuture(srcView.source).subscribe(async res => {
                // alert("ausgrid");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
			this.addLayerToMap.addTo(this.map);
		}
		this.ngxsService.stop();
              });
            }
            // Fire Layer
            else if (srcView.transformation_type == 'fire') {
              this._fireservice.createFireLayer(srcView.source).subscribe(async res => {
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
                  this.addLayerToMap.addTo(this.map);
                }
                this.ngxsService.stop();
              });
            }
            // Live Outages Layer
            else if (srcView.transformation_type == 'live-outages') {
              this._liveOutageService.createLiveOutagesLayer(srcView.source).subscribe(async res => {
                // alert("live-outages");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
			this.addLayerToMap.addTo(this.map);
		}
		this.ngxsService.stop();
              });
            }
            // Power Station
            else if (srcView.transformation_type == 'power-stations') {
              this._powerStationService.createPowerStationLayer(srcView.source).subscribe(async res => {
                // alert("power-stations");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
			this.addLayerToMap.addTo(this.map);
		}
                this.ngxsService.stop();
              });
            }
            // Boundaries
            else if (srcView.transformation_type == 'boundaries') {
              this._boundariesService.createBoundariesLayer(srcView.source).subscribe(async res => {
                // alert("boundaries");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
			this.addLayerToMap.addTo(this.map);
		}
		this.ngxsService.stop();
              });
            }
            // NSW Gas pipelines
            else if (srcView.transformation_type == 'gas') {
              this._pipelinesService.createPipeLineLayer(srcView.source).subscribe(async res => {
                //alert("trans");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
                  this.addLayerToMap.addTo(this.map);
                }
                this.ngxsService.stop();
              });
            }
            // Oil Layer
            else if (srcView.transformation_type == 'oil') {

              const oilPipes = esri.featureLayer({
                url: srcView.source,

                style: function (feature) {
                  return { color: '#65b5cf', weight: 4 };
                }
              });

              oilPipes.bindPopup(function (e) {

                var featureProperties = e['feature'].properties;

                // Build the popup content with a table
                var popupContent = "<h4 class=\"backdrop\">" + featureProperties.name + "</h4><table class=\"pws\">";

                // Add rows for each property you want to display
                if (featureProperties.objectid !== undefined && featureProperties.objectid !== "") {
                  popupContent += "<tr><td>Object ID:</td><td>" + featureProperties.objectid + "</td></tr>";
                }
                if (featureProperties.featuretype !== undefined && featureProperties.featuretype !== "") {
                  popupContent += "<tr><td>Feature Type:</td><td>" + featureProperties.featuretype + "</td></tr>";
                }
                if (featureProperties.description !== undefined && featureProperties.description !== "") {
                  popupContent += "<tr><td>Description:</td><td>" + featureProperties.description + "</td></tr>";
                }
                if (featureProperties.class !== undefined && featureProperties.class !== "") {
                  popupContent += "<tr><td>Class:</td><td>" + featureProperties.class + "</td></tr>";
                }
                if (featureProperties["sdedist.spool.NationalOnshoreOilPipelines_New.fid"] !== undefined && featureProperties["sdedist.spool.NationalOnshoreOilPipelines_New.fid"] !== "") {
                  popupContent += "<tr><td>Spool FID:</td><td>" + featureProperties["sdedist.spool.NationalOnshoreOilPipelines_New.fid"] + "</td></tr>";
                }
                if (featureProperties.owner !== undefined && featureProperties.owner !== "") {
                  popupContent += "<tr><td>Owner:</td><td>" + featureProperties.owner + "</td></tr>";
                }
                if (featureProperties.length_km !== undefined && featureProperties.length_km !== "") {
                  popupContent += "<tr><td>Length (km):</td><td>" + featureProperties.length_km + "</td></tr>";
                }
                if (featureProperties.diameter_mm !== undefined && featureProperties.diameter_mm !== "") {
                  popupContent += "<tr><td>Diameter (mm):</td><td>" + featureProperties.diameter_mm + "</td></tr>";
                }
                if (featureProperties.construction !== undefined && featureProperties.construction !== "") {
                  popupContent += "<tr><td>Construction:</td><td>" + featureProperties.construction + "</td></tr>";
                }
                if (featureProperties.state !== undefined && featureProperties.state !== "") {
                  popupContent += "<tr><td>State:</td><td>" + featureProperties.state + "</td></tr>";
                }
                if (featureProperties.operationalstatus !== undefined && featureProperties.operationalstatus !== "") {
                  popupContent += "<tr><td>Operational Status:</td><td>" + featureProperties.operationalstatus + "</td></tr>";
                }
                if (featureProperties.licencenumber !== undefined && featureProperties.licencenumber !== "") {
                  popupContent += "<tr><td>License Number:</td><td>" + featureProperties.licencenumber + "</td></tr>";
                }
                if (featureProperties.product !== undefined && featureProperties.product !== "") {
                  popupContent += "<tr><td>Product:</td><td>" + featureProperties.product + "</td></tr>";
                }
                if (featureProperties.revised !== undefined && featureProperties.revised !== "") {
                  var revisedDate = new Date(parseInt(featureProperties.revised));
                  popupContent += "<tr><td>Revised:</td><td>" + revisedDate.toLocaleDateString() + "</td></tr>";
                }
                if (featureProperties["st_length(shape)"] !== undefined && featureProperties["st_length(shape)"] !== "") {
                  popupContent += "<tr><td>Length (Shape):</td><td>" + featureProperties["st_length(shape)"] + "</td></tr>";
                }

                popupContent += "</table>";

                return popupContent;

              });
              this.overlayLayers[srcView.name] = oilPipes;
              if (this.fromTemplates === undefined) {
	      	oilPipes.addTo(this.map);
	      }
              this.ngxsService.stop();
            }
            // Water Layer
            else if (srcView.transformation_type == 'water') {
              // water pipes
              const waterPipes = esri.featureLayer({
                url: srcView.source,
                //maxZoom: 15,
                style: function (feature) {
                  return { color: '#6563c4', weight: 2 };
                }
              });

              waterPipes.bindPopup(function (e) {
                return "<h4>" + e['feature'].properties.name + "</h4>";
              });

              this.overlayLayers[srcView.name] = waterPipes;
              if (this.fromTemplates === undefined) {
	      	waterPipes.addTo(this.map);
	      }
              this.ngxsService.stop();
              //water pipes emds

            }
            // Water Treatement
            else if (srcView.transformation_type == 'water-treatment') {
              //water treatment starts
              const wtIcon = new L.Icon({
                iconSize: [20, 20],
                iconAnchor: [10, 20],
                popupAnchor: [1, -12],
                iconUrl: 'https://app.kartasoft.co/images/watertreatment.png'
              });

              const waterTreatment = esri.featureLayer({
                url: srcView.source,
                //maxZoom: 15,
                pointToLayer: function (geojson, latlng) {
                  return L.marker(latlng, {
                    icon: wtIcon
                  });
                }
              });

              waterTreatment.bindPopup(function (e) {
                return "<h4>Water Treatment Plant - " + e['feature'].properties.name + "</h4><p>" + e['feature'].properties.operator + "</p>";
              });

              this.overlayLayers[srcView.name] = waterTreatment;
              if (this.fromTemplates === undefined) {
	      	waterTreatment.addTo(this.map);
	      }
              this.ngxsService.stop();
              //water treatment ends

            }
            // Liquid Fuel
            else if (srcView.transformation_type == 'liquid-fuel') {
              //Liquid Fuel start
              const lficon = L.icon({
                iconSize: [20, 20],
                iconAnchor: [10, 20],
                popupAnchor: [1, -12],
                iconUrl: 'https://app.kartasoft.co/images/liquidfuel.png'
              });

              const liquidFuel = esri.featureLayer({
                url: srcView.source,
                //maxZoom: 15,
                pointToLayer: function (geojson, latlng) {
                  return L.marker(latlng, {
                    icon: lficon
                  });
                }
              });

              liquidFuel.bindPopup(function (e) {
                return "<h4>Liquid Fuel Depot - " + e['feature'].properties.name + "</h4><p>" + e['feature'].properties.operator + "</p>";
              });

              this.overlayLayers[srcView.name] = liquidFuel;
              if (this.fromTemplates === undefined) {
	      	liquidFuel.addTo(this.map);
	      }
              this.ngxsService.stop();
              //Liquid Fuel ends

            }
            // Fuel Station
            else if (srcView.transformation_type == 'fuel-stations') {
              //Petrol Station starts
              const psIcon = new L.Icon({
                iconSize: [15, 15],
                iconAnchor: [8, 15],
                popupAnchor: [1, -12],
                iconUrl: 'https://app.kartasoft.co/images/petrolstation.png'
              });

              const petrolStations = esri.featureLayer({
                url: srcView.source,
                //maxZoom: 15,
                pointToLayer: function (geojson, latlng) {
                  return L.marker(latlng, {
                    icon: psIcon
                  });
                }
              });

              petrolStations.bindPopup(function (e) {
                return "<h4>Petrol Station - " + e['feature'].properties.name + "</h4><p>" + e['feature'].properties.owner + "</p>";
              });

              this.overlayLayers[srcView.name] = petrolStations;
              if (this.fromTemplates === undefined) {
	      	petrolStations.addTo(this.map);
	      }
              this.ngxsService.stop();
              //Petrol Station ends
            }
            // Fuel Station
            else if (srcView.transformation_type == 'bom') {
              this.bomService.createBomLayer(srcView.source).subscribe(async res => {
                //  alert("geoJson");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
			this.addLayerToMap.addTo(this.map);
		}
                // this.addLayerToMap.addTo(markers);
                this.ngxsService.stop();
              });
            }
            else if (srcView.transformation_type == 'bom-poly') {
              this.bomService.createBomPolyLayer(srcView.source).subscribe(async res => {
                //  alert("geoJson");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
			this.addLayerToMap.addTo(this.map);
		}
                // this.addLayerToMap.addTo(markers);
                this.ngxsService.stop();
              });
            }
            else if (srcView.transformation_type == 'bom-river') {
              this.bomService.createBomRiverLayer(srcView.source).subscribe(async res => {
                //  alert("geoJson");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
			this.addLayerToMap.addTo(this.map);
		}
                // this.addLayerToMap.addTo(markers);
                this.ngxsService.stop();
              });
            }
            else if (srcView.transformation_type == 'bom-flood') {
              this.bomService.createBomFloodLayer(srcView.source).subscribe(async res => {
                //  alert("geoJson");
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
			this.addLayerToMap.addTo(this.map);
		}
                // this.addLayerToMap.addTo(markers);
                this.ngxsService.stop();
              });
            }
            else if (srcView.transformation_type == 'flights') {
              this.bomService.createFlightsLayer(srcView.source).subscribe(async res => {
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {
			this.addLayerToMap.addTo(this.map);
		}
                this.ngxsService.stop();
              });
            }
            else if (srcView.transformation_type == 'radar') {

              myExternalJs.RadarMap();
              // this.addLayerToMap.addTo(this.map);
              L.control.radar({ opacity: 0.15 }).addTo(this.map);
              this.ngxsService.stop();
            }
            // oman Tempreature
            else if (srcView.transformation_type == 'temp') {
            const temp = L.tileLayer("https://{s}.tile.openweathermap.org/map/temp/{z}/{x}/{y}.png?appid=06aac0fd4ba239a20d824ef89602f311", {
              attribution: '&copy; <a href="https://openweathermap.org">OpenWeatherMap</a> contributors',
              format: `image/png`,
              opacity: 0.8,
              bounds: [[10.96,38.46], [33.29,75.64]]
            });
            
            this.overlayLayers[srcView.name] = temp;
            if (this.fromTemplates !== undefined) {
                temp.addTo(this.map);
              }
            this.ngxsService.stop();
            }
            // oman pressure
            else if (srcView.transformation_type == 'pressure') {
            const pressure = L.tileLayer("https://{s}.tile.openweathermap.org/map/pressure/{z}/{x}/{y}.png?appid=06aac0fd4ba239a20d824ef89602f311", {
              attribution: '&copy; <a href="https://openweathermap.org">OpenWeatherMap</a> contributors',
              format: `image/png`,
              opacity: 0.8,
              bounds: [[10.96,38.46], [33.29,75.64]]
            });
            
            this.overlayLayers[srcView.name] = pressure;
            if (this.fromTemplates !== undefined) {
                pressure.addTo(this.map);
              }
            this.ngxsService.stop();
            }
            // oman oil gas pipeline Layer 
            else if (srcView.transformation_type == 'omanoilgaspipe') {
              this._omanoilgasService.createOilGasLayer(srcView.source).subscribe(async res => {
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {  
      this.addLayerToMap.addTo(this.map);
    }
    this.ngxsService.stop();
              });
            }
            // oman oil gas fields Layer 
            else if (srcView.transformation_type == 'omanoilgasfield') {
              this._omanoilgasService.createOilGasFieldLayer(srcView.source).subscribe(async res => {
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {  
      this.addLayerToMap.addTo(this.map);
    }
    this.ngxsService.stop();
              });
            }
            // oman oil gas processing Layer 
            else if (srcView.transformation_type == 'omanoilgaspro') {
              this._omanoilgasService.createOilGasProLayer(srcView.source).subscribe(async res => {
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {  
      this.addLayerToMap.addTo(this.map);
    }
    this.ngxsService.stop();
              });
            }
            // oman oil gas observation Layer 
            else if (srcView.transformation_type == 'omanobservations') {
              this._omanoilgasService.createOilGasObsLayer(srcView.source).subscribe(async res => {
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {  
      this.addLayerToMap.addTo(this.map);
    }
    this.ngxsService.stop();
              });
            }
            // oman wadi Layer 
            else if (srcView.transformation_type == 'wadi') {
              this._omanoilgasService.createWadiLayer(srcView.source).subscribe(async res => {
                this.addLayerToMap = await res;
                this.layerControl.addOverlay(this.addLayerToMap, srcView.name);
                if (this.fromTemplates === undefined) {  
      this.addLayerToMap.addTo(this.map);
    }
    this.ngxsService.stop();
              });
            }
          });
        }

      };

      // KML File Process Data
      if (this.processFileData) {
        alert("process file");
        //this.createKMLLayers(this.processFileData, this.mapDetails['kmlName']);
        let convertedWithStyles1 = kml(this.processFileData);
        // console.log(convertedWithStyles1)
        this.selectedLayer4 = L.geoJSON(convertedWithStyles1, {
          onEachFeature: function onEachFeature(feature, layer) {
            // console.log(feature)
            if (feature.properties.description || feature.properties.name) {
              const popupText = feature.properties.description != undefined ? feature.properties.description : feature.properties.name;
              layer.bindPopup(popupText);
            }
          },
          // pointToLayer: function (feature, latlng) {
          //   const icon = feature.properties.icon != undefined ? feature.properties.icon : 'assets/images/kml_marker.png';
          //   // Set Marker Icon
          //   var fireIconGreen = new L.Icon({
          //     iconSize: [32, 32],
          //     markerColor: 'red',
          //     iconAnchor: [10, 32],
          //     popupAnchor: [1, -12],
          //     iconUrl: icon
          //   });
          //   return L.marker(latlng, { icon: fireIconGreen });
          // },
          style: <any>function style(featureStyle: any) {
            // console.log(featureStyle.properties['stroke-width']);
            var dict = {
              color: featureStyle.properties['stroke'],
              weight: featureStyle.properties['stroke-width'],
              opacity: featureStyle.properties['stroke-opacity'],
              fillColor: featureStyle.properties['fill'],
              fillOpacity: featureStyle.properties['fill-opacity']
            };

            if (featureStyle.properties['stroke'] == undefined) {
              delete dict.color;
            }
            if (featureStyle.properties['stroke-width'] == undefined) {
              delete dict.weight;
            }
            if (featureStyle.properties['stroke-opacity'] == undefined) {
              delete dict.opacity;
            }
            if (featureStyle.properties['fill'] == undefined) {
              delete dict.fillColor;
            }
            if (featureStyle.properties['fill-opacity'] == undefined) {
              delete dict.fillOpacity;
            }

            // console.log(dict);
            // return;
            return dict;
          }
        });
        this.overlayLayers[this.mapDetails['kmlName']] = this.selectedLayer4;
        if (this.fromTemplates === undefined) {
		this.selectedLayer4.addTo(this.map);
	}
        this.ngxsService.stop();
      }

      L.tileLayer(this.currentMapViewUrl, {
        attribution: '© <a href="https://www.mapbox.com/about/maps/" style="font-size: 12px !important;" target="_blank">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright" style="font-size: 12px !important;" target="_blank">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank" style="font-size: 12px !important;">Improve this map</a></strong>'
      }).addTo(this.map);

      this.map.attributionControl.setPrefix('© ' + (new Date()).getFullYear() + ' <a href="https://www.kartasoft.co/toc-2" style="font-size: 12px !important;" target="_blank">kartasoft.co</a>');


      // if (this.mapDetails.mapViewTypes == "Street") {
      //   mapTiles = L.tileLayer(mbStreetsUrl, {
      //     attribution: '&copy; <a href="https://kartasoft.co/">Kartasoft</a>'
      //   });
      // }
      // if (this.mapDetails.mapViewTypes == "Terrain") {
      //   mapTiles = L.tileLayer(mbTerrainUrl, {
      //     attribution: '&copy; <a href="https://kartasoft.co/">Kartasoft</a>'
      //   });
      // }
      // if (this.mapDetails.mapViewTypes == "Satellite") {
      //   mapTiles = L.tileLayer(mbSatelliteUrl, {
      //     attribution: '&copy; <a href="https://kartasoft.co/">Kartasoft</a>'
      //   });
      // }
    }
    var baseMaps = {
      "Satellite": lyrSatellite,
      "Street": lyStreets,
      "Terrain": lyrTerrain
    };

    
    var layerControlOptions = {
            hideSingleBase : true,
            autoZIndex : true,
            sortLayers: true,
    }

    if (Object.keys(this.mapDetails).length == 0) {
      this.ngxsService.stop();
    }

    // LAYER CONTROL
    // console.log('final layers', this.overlayLayers);

    this.layerControl = L.control.layers(baseMaps, this.overlayLayers, layerControlOptions).addTo(this.map);

    //Location Control

    L.control.locate({
      position: 'topleft',
      strings: {
        title: "Move to my location",
        popup: "You are within 1299 meters from this point",
        outsideMapBoundsMsg: "You seem located outside the boundaries of the map"
      }
    }).addTo(this.map);

    // leaflet-geosearch
    const providerSearch = new OpenStreetMapProvider();
    const searchControl = GeoSearchControl({
      provider: providerSearch,
      showMarker: true,
      showPopup: true,
      style: 'button',
      retainZoomLevel: false,
      animateZoom: true,
      autoClose: true,
      searchLabel: 'Enter address to search',
      notFoundMessage: 'Sorry, that address could not be found.',
      keepResult: true,
      updateMap: true
    });
    this.map.addControl(searchControl);
    //called on from the suggested option select
    this.map.on('geosearch/showlocation', function (e) {
    });

    // map refresh
    L.easyButton({
      position: 'topleft',
      leafletClasses: true,
      id: 'map-refresh-btn',
      states: [
        {
          stateName: 'refresh click',
          onClick: function (btn, map) { },
          title: 'Refresh with current data',
          icon: 'fa fa-refresh'
        }
      ]
    }).addTo(this.map);
    //get the el using id
    const buttonRefresh = L.DomUtil.get('map-refresh-btn');
    L.DomEvent.on(buttonRefresh, 'click', (ee) => {
      this.map.remove();
      this.initMap();
    });

    //kartasoft logo at the bottom right
    L.easyButton({
      position: 'bottomright',
      leafletClasses: true,
      id: 'kartasoft-watermark',
      states: [
        {
          stateName: 'ks-logo-blk',
          onClick: function (btn, map) { },
          title: 'Kartasoft',
          icon: '<img src="./assets/images/icon-white.png">'
        }
      ]
    }).addTo(this.map);
    // this.ngxsService.stop();
    //get the el using id
    const buttonKSLogo = L.DomUtil.get('kartasoft-watermark');
    L.DomEvent.on(buttonKSLogo, 'click', (ee) => {
      window.open("https://www.kartasoft.co/", "_blank")
    });

    // this.ngxsService.stop();
    // this.map.fitBounds([
    //   [114.2578125, -34.813803317113134],
    //   [145.8984375, -14.434680215297268]
    // ]);
    // this.map.fitBounds([
    //   [-38.1302, 144.3452
    //   ],
    //   [-37.5054, 145.4150
    //   ]
    // ]);
    //let group = L.featureGroup([this.selectedLayer, this.selectedLayer2]);
    //this.map.fitBounds(group.getBounds());
    //mapTiles.addTo(this.map);
    // }
  }

  // Create KML Layer
  async createKMLLayers(kmlData, name) {
    const kmlObj = await fetch(kmlData);
    const kmlObjText = await kmlObj.text();
    const parser = new DOMParser();
    const kmls = parser.parseFromString(kmlObjText, 'text/xml');

    let convertedWithStyles = await kml(kmls);
    // console.log(convertedWithStyles);
    let kmlSelectedLayer = L.geoJSON(convertedWithStyles, {
      onEachFeature: function onEachFeature(feature, layer) {
        // console.log(feature);
        if (feature.properties.description || feature.properties.name) {
          const popupText = feature.properties.description != undefined ? feature.properties.description : feature.properties.name;
          layer.bindPopup(popupText);
        }
      },
      // pointToLayer: function (feature, latlng) {
      //   const icon = feature.properties.icon != undefined ? feature.properties.icon : 'assets/images/kml_marker.png';
      //   // Set Marker Icon
      //   var fireIconGreen = new L.Icon({
      //     iconSize: [32, 32],
      //     markerColor: 'red',
      //     iconAnchor: [10, 32],
      //     popupAnchor: [1, -12],
      //     iconUrl: icon
      //   });
      //   return L.marker(latlng, { icon: fireIconGreen });
      // },
      style: <any>function style(featureStyle: any) {
        var dict = {
          color: featureStyle.properties['stroke'],
          weight: featureStyle.properties['stroke-width'],
          opacity: featureStyle.properties['stroke-opacity'],
          fillColor: featureStyle.properties['fill'],
          fillOpacity: featureStyle.properties['fill-opacity']
        };

        if (featureStyle.properties['stroke'] == undefined) {
          delete dict.color;
        }
        if (featureStyle.properties['stroke-width'] == undefined) {
          delete dict.weight;
        }
        if (featureStyle.properties['stroke-opacity'] == undefined) {
          delete dict.opacity;
        }
        if (featureStyle.properties['fill'] == undefined) {
          delete dict.fillColor;
        }
        if (featureStyle.properties['fill-opacity'] == undefined) {
          delete dict.fillOpacity;
        }
        return dict;

      }
    });
    // this.overlayLayers[name] = kmlSelectedLayer;
    // kmlSelectedLayer.addTo(this.map);
    return kmlSelectedLayer;
  }

  onEachTransmissionFeature(feature, layer) {
    if (feature.properties) {
      var strPopup = "<h4 class=\"backdrop\">" + feature.properties.NAME + "</h4><table class=\"pws\">";
      if (feature.properties.FEATURETYPE != "") {
        strPopup = strPopup + "<tr><td>Type: </td><td><b>" + feature.properties.FEATURETYPE + "</b> (" + feature.properties.CONSTRUCTIONTYPE + ")</td></tr>";
      }
      if (feature.properties.CAPACITY_kV != "") {
        strPopup = strPopup + "<tr><td>Capacity: </td><td><b>" + feature.properties.CAPACITY_kV + " kV</b></td></tr>";
      }
      if (feature.properties.STATUS != "") {
        strPopup = strPopup + "<tr><td>Status: </td><td><b>" + feature.properties.STATUS + "</b></td></tr>";
      }
      strPopup = strPopup + "</table>";
      layer.bindPopup(strPopup);
    }
  }

  async mapScreenShortEvent() {
    new SimpleMapScreenshoter();
    let pluginOptions = {
      cropImageByInnerWH: true,
      hidden: true,
      preventDownload: true,
      hideElementsWithSelectors: ['.leaflet-control-container'],
      mimeType: 'image/png'
    }
    this.simpleMapScreenshoters = L.simpleMapScreenshoter(pluginOptions).addTo(this.map);
    let format = 'image';
    let myMapsScreenShot = await this.simpleMapScreenshoters.takeScreen(format, pluginOptions).then(blob => blob)
      .then(blobImg => {
        return blobImg;
      }).catch(e => {
        console.error(e)
      });
    return myMapsScreenShot;
  }

  constructor(
    private httpClient: HttpClient,
    private ngxsService: NgxUiLoaderService,
    public _fireservice: FireServiceService,
    public _ausgridservice: AusgrideService,
    public _transmissionService: TransmissionService,
    public _transmissionService2: TransmissionService2,
    public _transmissionService3: TransmissionService3,
    public _transmissionService4: TransmissionService4,
    public _powerStationService: PowerStationService,
    public _pipelinesService: PipelinesService,
    public _liveOutageService: LiveOutageService,
    public _boundariesService: BoundariesService,
    public _commonService: CommonService,
    public bomService: BomService,
    public _incidenttransportService: IncidentTransportService,
    public _majoreventtransportService: MajorEventTransportService,
    public _risingmainService: RisingMainService,
    public _omanmainService: OmanMainService,
    public _boundariesService2: BoundariesService2,
    public _omanoilgasService: OmanOilGasService,
  ) { }

  ngAfterViewInit() {
    //debugger
    // this.initMap();
  }

  ngOnInit(): void {
    // this.ngxsService.start();
    this.initMap();
    // const layersArray = [
    //   this._fireservice.createFireLayer(),
    //   this._ausgridservice.createAusGrideLayer(),
    //   this._transmissionService.createTransmissionLayer(),
    //   this._powerStationService.createPowerStationLayer(),
    //   this._liveOutageService.createLiveOutagesLayer(),
    //   this._boundariesService.createBoundariesLayer()
    // ];
    // forkJoin(layersArray).subscribe(results => {
    //   console.log(results);
    //   this.initMap(results[0], results[1], results[2], results[3], results[4], results[5]);
    // });
    // fetch('../../assets/mapdata/examples.kml')
    //   .then(res => res.text())
    //   .then(kmltext => {
    //     // Create new kml overlay
    //     const parser = new DOMParser();
    //     const kmls = parser.parseFromString(kmltext, 'text/xml');
    //   });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['mapDetails'].currentValue) {
      // calling the submit data method if the "isSubmitted" value changes to "true" from parent component.
      if (this.map && this.map.remove) {
        // debugger;
        this.map.off();
        this.map.remove();
        this.initMap();
      }
      // }
    }
  }

}

