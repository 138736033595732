import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../app-config';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(private httpClient: HttpClient) { }

  public getMapList = (): Observable<any> => {
    return this.httpClient.get<any>(AppConfig.maps);
  }

  public templatesList = (): Observable<any> => {
    return this.httpClient.get<any>(AppConfig.templates);
  }

  public getSingleMapList = (mapId): Observable<any> => {
    return this.httpClient.get<any>(AppConfig.maps+mapId+'/');
  }

  public getSingleTempList = (mapId): Observable<any> => {
    return this.httpClient.get<any>(AppConfig.templates+mapId+'/');
  }

  public postMapDetails = (userData): Observable<any> => {
    return this.httpClient.post<any>(AppConfig.maps, userData );
  }

  public putMapDetails = (userData, mapId): Observable<any> => {
    return this.httpClient.put<any>(AppConfig.maps+mapId+'/', userData );
  }

  public patchMapDetails = (mapData, mapId): Observable<any> => {
    return this.httpClient.patch<any>(AppConfig.maps+mapId+'/', mapData);
  }

  public deleteMap = (mapId): Observable<any> => {
    return this.httpClient.delete<any>(AppConfig.maps+mapId+'/');
  }

}
