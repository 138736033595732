import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common';


import { AuthenticationInterceptorService } from '../app/services/authentication-interceptor.service';
import { AppRoutingModule, RoutingMenuComponentsList } from './app-routing.module';
import { AppComponent } from './app.component';
import { FirelineComponent } from './fireline/fireline.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CheckbBoxChildrenDirective } from './directives/checkb-box-children.directive';
import { CheckBoxSelectGroupDirective } from './directives/check-box-select-group.directive';
import { CheckboxtreePipe } from './pipes/checkboxtree.pipe';
import { AuthenticationService } from './services/authentication.service';
import { AuthGuardService } from './services/auth-guard.service';

//ngx-packages
import { NgSelectModule } from '@ng-select/ng-select';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AccordionModule } from 'ngx-bootstrap/accordion';


import { TabsModule } from 'ngx-bootstrap/tabs';
import { MaptemplatesComponent } from './maptemplates/maptemplates.component';
import { EditmapComponent } from './editmap/editmap.component';
import { LoginComponent } from './login/login.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { OutageReportComponent } from './outage-report/outage-report.component';
import { UsersComponent } from './users/users.component';
import { ViewPublicMapComponent } from './view-public-map/view-public-map.component';

import { NgxUiLoaderModule } from "ngx-ui-loader";
import { ReportIssueFormComponent } from './report-issue-form/report-issue-form.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { HistoricalMapsComponent } from './historical-maps/historical-maps.component';
import { ReportDownloadsComponent } from './report-downloads/report-downloads.component';

@NgModule({
  declarations: [
    AppComponent,
    FirelineComponent,
    DashboardComponent,
    RoutingMenuComponentsList,
    CheckbBoxChildrenDirective,
    CheckBoxSelectGroupDirective,
    CheckboxtreePipe,
    MaptemplatesComponent,
    EditmapComponent,
    LoginComponent,
    SideMenuComponent,
    OutageReportComponent,
    UsersComponent,
    ViewPublicMapComponent,
    ReportIssueFormComponent,
    ConfirmModalComponent,
    HistoricalMapsComponent,
    ReportDownloadsComponent
  ],
  imports: [
    NgxUiLoaderModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    NgSelectModule,
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),    
    AccordionModule.forRoot(),
  ],
  providers: [DatePipe, AuthenticationService,AuthGuardService, { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptorService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
