<div class="container cust-confirm-modal" tabindex="-1">
    <div class="row m-b-20">
      <div class="col-lg-12">
        <h4 class="modal-title float-start"></h4>
        <i class="bi bi-x-circle close float-end mt-2" *ngIf="confirmAction == 'confirm'" aria-hidden="true"
          (click)="this.bsModalRef.hide()"></i>
        <i class="bi bi-x-circle close float-end mt-2" *ngIf="confirmAction != 'confirm'" aria-hidden="true"
          (click)="confirmSave()"></i>
      </div>
    </div>
  
    <div class="row">
      <div class="col-lg-12">
        <div class="body-content d-flex">
          <!-- <div class="circle bg-filled-warning" *ngIf="confirmAction == 'confirm'"><i class="bi bi-exclamation-triangle-fill"></i></div>
          <div class="circle bg-filled-success" *ngIf="confirmAction == 'success'"><i class="bi bi-exclamation-triangle-fill"></i></div> -->
          <!-- <div class="circle bg-filled-error" *ngIf="confirmAction == 'error'"><i class="bi bi-x"></i></div> -->
          <div>
            <h4 class="m-b-0">{{ title }}</h4>
            <p class="pt-3">{{ contents }}</p>
          </div>
        </div>
      </div>
    </div>
  
    <div class="row">
      <div class="col-lg-12" *ngIf="confirmAction == 'confirm'">
        <div class="modal-footer border-0 pb-3">
          <button type="button" class="btn btn-outline me-2" (click)="bsModalRef.hide()">Cancel</button>
          <button type="button" class="btn btn-filled me-2" (click)="confirmSave()">{{saveBtnLable ? saveBtnLable :
            "Save"}}</button>
        </div>
      </div>
  
      <!-- <div class="col-lg-12 p-0" *ngIf="confirmAction != 'confirm'">
        <div class="modal-footer border-0 p-0">
          <button type="button" class="btn primary" (click)="SuccessAlert()">Ok</button>
        </div>
      </div> -->
    </div>
  </div>