<div class="row my-3">
    <h3>Templates</h3>
</div>
<div class="m-b-30" id="map_templates">
  <div class="row">
    <div class="col-12 col-lg-4 mt-5" *ngFor="let listItem of myMaps; let i = index">
      <div class="card">
	      <!-- <img [src]="myMapsImages[0].list[i%6].img" class="card-img-top" [attr.alt]="myMapsImages[0].list[i%6].img"> -->
	      <img [src]="listItem?.meta?.thumbnail" class="card-img-top" [attr.alt]="listItem.name">
        <div class="card-body">
          <h5 class="card-title">{{ listItem.name }}</h5>
          <p class="card-text">{{ listItem.created_at }}</p>
          <p class="card-text">{{ listItem.remarks }}</p>
        </div>
        <div class="card-footer text-muted bg-transparent">
          <div class="d-block float-end">
              <button class="btn btn-primary me-3 px-4" (click)="editMaps(listItem.id)" [attr.id]="i">Edit</button>
              <!-- <button class="btn btn-danger px-3" (click)="deleteMaps(listItem.id)">Delete</button> -->
            </div>
        </div>
      </div>
    </div>
    <div class="text-center" *ngIf="myMaps?.length == 0">
      <span><i class="bi bi-map-fill"></i></span><br />
      <span>No Maps Found</span>
    </div>
</div>
