<div class="profile-stepper d-flex justify-content-center mt-5">
    <div class="progress">
        <div class="circle" [style.background-color]="!mapDataSrcForm.valid ? '' : '#2a4659'"> <span class="label">1</span>
            <div class="title">Data Library</div>
        </div>
        <span class="bar" [style.background-color]="!mapDataSrcForm.valid ? '' : '#2a4659'"></span>

        <div class="circle" [style.background-color]="!mapBaseStylesForm.valid ? '' : '#2a4659'"> <span class="label">2</span>
            <div class="title">Base Map Style</div>
        </div>
        <span class="bar" [style.background-color]="!mapBaseStylesForm.valid ? '' : '#2a4659'"></span>

        <div class="circle" [style.background-color]="enableCreate ? '': '#2a4659'"> <span class="label">3</span>
            <div class="title">Generate</div>
        </div>
    </div>
</div>
<form [formGroup]="mapDataSrcForm" *ngIf="dataSourceSection">
    <div class="map-data-source">
        <div class="row">
            <div class="col-lg-12 mb-4">
                <h3>Map Data Sources<i class="fa fa-info-circle" aria-hidden="true" tooltip="Choose a data source from the library or load a new data source via the 'KML File'. You can load one KML file at a time" style="margin-left: 8px;"></i></h3>
                <span>Select at least one data source</span>
            </div>
        </div>
    
        <div class="row mb-3">
            <div class="col-lg-6">
                <div class="form-group">
                    <label class="form-label" for="kml_name">Data Library</label>
                    <ng-select [items]="incidenetTypes" [hideSelected]="true" [multiple]="true" bindLabel="name" formControlName="IncidentTypes"
                                        multiple="true" [(ngModel)]="selectedIncidents" placeholder="Data Library">
                                </ng-select>
                    <div *ngIf="!(mapDataSrcForm.get('IncidentTypes').dirty && mapDataSrcForm.get('IncidentTypes').value !='')">
                        <!-- <small>At least one data type must be selected</small> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-2">
                    <div class="form-group">
                        <label class="form-label" for="kml_name">KML Data Name</label>
                        <input type="text" class="form-control" id="kml_name" formControlName="kmlName" placeholder="KML Data Name">
                    <div class="text-danger" *ngIf="(mapDataSrcForm.get('kmlName').value == '') && (mapDataSrcForm.get('uploadDataFile').value != '')"><small>Please name this KML data source</small></div>
                    </div>
                </div>
                <div class="mt-2">
                    <label class="form-label">Upload KML File</label><span class="m-2">( Max: 5MB )</span>
                    <div class="form-group mt-3">
                        <div class="input-group upload-img-group mb-1 px-2 py-2 rounded-pill bg-white shadow-sm">
                            <input id="upload" type="file" (change)="uploadDataFiles($event)" formControlName="uploadDataFile" class="form-control border-0">
                            <label id="upload-label" for="upload" class="font-weight-bold text-muted">Choose file</label>
                            <div class="input-group-append">
                                <label for="upload" class="btn btn-light m-0 rounded-pill upload-icon-append px-4"> <i class="bi bi-upload"></i></label>
                            </div>
                        </div>
                    </div>
                    <span class="text-success" *ngIf="kmlFileName && (mapDataSrcForm.get('uploadDataFile').value != '')"><small>{{ kmlFileName }}</small></span>
                    <span class="text-danger" *ngIf="kmlFileNameErr"><small>{{ kmlFileNameErr }}</small></span>
                    <div class="text-danger" *ngIf="(mapDataSrcForm.get('kmlName').value != '') && (mapDataSrcForm.get('uploadDataFile').value == '')"><small>Upload the KML file</small></div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-lg-6">
            </div>

            <div class="col-lg-6 text-end">
                <button class="btn btn-primary" (click)="submitDSF()" [disabled]="dataSorcesValidation">Next</button>
                <p *ngIf="dataSorcesValidation">(Please select at least one data source)</p>                
            </div>
        </div>

    </div>
</form>
<form [formGroup]="mapBaseStylesForm" *ngIf="baseStylesSection">
    <div class="map-style">
        <div class="row">
            <div class="col-lg-12">
                <h3 class="mb-3">Base Map Style</h3>
            </div>
        </div>
    
        <div class="row mb-3">
            <div class="col-lg-6">
                <label class="form-label">Select Map View</label>
                <div class="form-group mt-3">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="mapViewTypes" name="mapViewTypes" id="Street" value="Street" (change)="onMapViewChange($event.target.value)">
                        <label class="form-check-label" for="Street">
                          Street <span *ngIf="selectedMapView == 'Street'">(Default)</span>
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="mapViewTypes" name="mapViewTypes" id="Terrain" value="Terrain" (change)="onMapViewChange($event.target.value)">
                        <label class="form-check-label" for="Terrain">
                          Terrain <span *ngIf="selectedMapView == 'Terrain'">(Default)</span>
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input cust-radio" type="radio" formControlName="mapViewTypes" name="mapViewTypes" id="Satellite" value="Satellite" (change)="onMapViewChange($event.target.value)">
                        <label class="form-check-label" for="Satellite">
                          Satellite <span *ngIf="selectedMapView == 'Satellite'">(Default)</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <label class="form-label">Select Map Type</label>
                <div class="form-group mt-3">
                    <div class="form-check">
                        <input class="form-check-input" disabled type="radio" formControlName="mapType" name="mapType" id="googleMap" value="google map">
                        <label class="form-check-label" for="googleMap">
                          Google Map
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="mapType" name="mapType" id="mapBox" value="map box">
                        <label class="form-check-label" for="mapBox">
                          MapBox
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input cust-radio" disabled type="radio" formControlName="mapType" name="mapType" id="opsm" value="open street map">
                        <label class="form-check-label" for="opsm">
                          Open Street Map
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-lg-6">
                <label class="form-label">Upload Company Logo</label>
                <div class="form-group mt-3">
                    <div class="input-group upload-img-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                        <input id="upload" type="file" (change)="onSelectFile($event)" formControlName="logo" class="form-control border-0" accept="image/png, image/gif, image/jpeg">
                        <label id="upload-label" for="upload" class="font-weight-bold text-muted">Choose file</label>
                        <div class="input-group-append">
                            <label for="upload" class="btn btn-light m-0 rounded-pill upload-icon-append px-4"> <i class="bi bi-upload"></i></label>
                        </div>
                    </div>
                </div>
                <div class="image-area mt-4">
                    <img id="imageResult" [src]="logoImgUpload" alt="" class="img-fluid rounded shadow-sm mx-auto d-block" height="60">
                </div>
            </div>
            <div class="col-lg-6">
                <label class="form-label">Select Company Primary Color</label>
                <div class="form-group mt-3">
                    <input type="color" class="form-control form-control-color" id="hbg-color" value="#4a7d9e" title="Click to pick" formControlName="headerBg">
                    <label for="hbg-color" class="form-label"></label>
                </div>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-lg-6">
                <button class="btn btn-primary" (click)="prev($event, 'dataSourceSection')">Prev</button>
            </div>

            <div class="col-lg-6 text-end">
                <button class="btn btn-primary" (click)="submitBSF()" [disabled]="!mapBaseStylesForm.valid">Next</button>
            </div>
        </div>
    </div>
</form>
<div class="map-preview" *ngIf="mapPreviewSection">
    <div class="row">
        <div class="col-lg-12">
            <h3 class="mb-3">Map</h3>
        </div>
    </div>

    <div class="row m-0" [style.background-color]="headerColor">
        <div class="col-xs-12 col-lg-12 p-0">
            <div class="client-logo-pan">
                <img [src]="logoImgUpload" height="60">
            </div>
        </div>
        <!-- <div class="col-xs-12 col-lg-6 d-flex justify-content-end align-items-center">
            <div class="mr-5">
                <a class="text-light m-r-5" href="javascript:void(0)" > About </a>
            </div>
            <div class="ml-5">
                <a class="text-light m-l-5" href="javascript:void(0)" > Legends </a>
            </div>
        </div> -->
    </div>

    <div class="row">
        <div class="col-lg-12">
            <app-fireline [mapDetails]="getMapParams" [incidentLayersArr]="incidentDetailsArr" [incidentLayersObj]="incidentDetailsObj" [processFileData]="dataSrcUpload"></app-fireline>
        </div>
    </div>
    <form [formGroup]="mapInfoForm">
        <div class="row mt-5">
            <div class="col-lg-6">
                <div class="form-group">
                    <label class="form-label" for="map_name">Map Name</label>
                    <input type="text" class="form-control" id="map_name" formControlName="mapName" placeholder="Map name">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="form-group">
                    <label class="form-label" for="map_desc">Description</label>
                    <textarea class="form-control" formControlName="description" id="map_desc" min="10" max="200" placeholder="About the map"></textarea>
                </div>
            </div>
        </div>
    </form>

    <div class="row my-5">
        <div class="col-lg-6">
            <button class="btn btn-primary" (click)="prev($event, 'baseStylesSection')">Prev</button>
        </div>

        <div class="col-lg-6 text-end">
            <button class="btn btn-primary" style="margin-right: 10px;" [disabled]="enableCreate" [disabled]="!mapInfoForm.valid" (click)="generateMap()">Save</button>
            <button class="btn btn-primary" [disabled]="enableCreate" [disabled]="!mapInfoForm.valid" (click)="generateMap()">Save and Publish</button>
        </div>
    </div>
</div>