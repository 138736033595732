import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

// import * as L from 'leaflet';

declare let L : any; //

import 'leaflet-rotatedmarker';


import 'leaflet.markercluster';

import { kml } from "@tmcw/togeojson";

// import * as WindBarb from '../../assets/windBarb.js';


@Injectable({
  providedIn: 'root'
})
export class OmanOilGasService {

  constructor(private httpClient: HttpClient) { }

  selectedLayer: any;
  pwrData: any;

  createOilGasLayer(apiUrl) {
    exports();
    // console.log(apiUrl);
    let liveData;

    return this.httpClient.get(apiUrl).pipe(
      map((res) => {
      liveData = res;
          this.selectedLayer = L.layerGroup();

          this.selectedLayer = L.geoJSON(liveData, {
                    style: function(feature){
              return {
                color: "#f23838",
                opacity: 2,
                weight: 2,
                fillColor: '#f23838',
                fillOpacity: 0.8
              }
            },
        });
    return this.selectedLayer;
  })
    );
  }

    createOilGasFieldLayer(apiUrl) {
    exports();
    // console.log(apiUrl);
    let liveData;

    return this.httpClient.get(apiUrl).pipe(
      map((res) => {
      liveData = res;
          this.selectedLayer = L.layerGroup();

          this.selectedLayer = L.geoJSON(liveData, {
                    style: function(feature){
              return {
                color: "#23402c",
                opacity: 2,
                weight: 2,
                fillColor: '#74d693',
                fillOpacity: 0.8
              }
            },
            onEachFeature: function (feature, layer) {
        if (feature.properties) {
        if (feature.properties.Name != "") {
                var strPopup = "<h4 class=\"backdrop\">" + feature.properties.Name + "</h4><table class=\"pws\">";
            }
            strPopup = strPopup + "</table>";
            layer.bindPopup(strPopup);
        }
        },
        });
    return this.selectedLayer;
  })
    );
  }

    createOilGasProLayer(apiUrl) {
    exports();
    // console.log(apiUrl);
    let liveData;

    return this.httpClient.get(apiUrl).pipe(
      map((res) => {
      liveData = res;
          this.selectedLayer = L.layerGroup();
          var myRenderer = L.canvas({ padding: 0.5 }); 

          this.selectedLayer = L.geoJSON(liveData, {
                    pointToLayer: function (geoJsonPoint, latlng) {
    return L.circleMarker(latlng, {
      renderer: myRenderer,
      radius: 8,
      fillColor: '#74d693',
      color: '#000',
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8
    });

            },
            onEachFeature: function (feature, layer) {
        if (feature.properties) {
        if (feature.properties.Name != "") {
                var strPopup = "<h4 class=\"backdrop\">" + feature.properties.Name + "</h4><table class=\"pws\">";
            }
            strPopup = strPopup + "</table>";
            layer.bindPopup(strPopup);
        }
        },
        });
    return this.selectedLayer;
  })
    );
  }

  createOilGasObsLayer(apiUrl) {
    exports();
    // console.log(apiUrl);
    let liveData;

    return this.httpClient.get(apiUrl).pipe(
      map((res) => {

      this.selectedLayer = L.layerGroup();
      liveData = res;
      this.selectedLayer = L.geoJSON(liveData, {
        pointToLayer: function (feature, latlng) {
          var speed = feature.properties.wind_spd;
          var color = speed <= 1 ? '#469924' : speed >= 1.1 && speed <= 2.9 ? '#f3ed4d' : speed >= 3 && speed <= 3.9 ? '#ff9933' : speed >= 4 ? '#cc3300' : '#009933';
          var ico = new L.WindBarb.icon({deg: feature.properties.wind_dir_deg, speed: feature.properties.wind_spd,fillColor: color, strokeColorPath: color, strokeColorCircle: color, pointRadius: 6});
          return L.marker(latlng, { icon: ico });
        },
            onEachFeature: function (feature, layer) {
    if (feature.properties && Object.keys(feature.properties).length > 0) {
        
        if (feature.properties["Name"] && feature.properties["Name"] !== "") {
            var strPopup = "<h4 class=\"backdrop\">" + feature.properties["Name"] + "</h4><table class=\"pws\">";
        }

        for (var key in feature.properties) {
            if (key !== "Name" && feature.properties[key] !== "") {
                strPopup += "<tr><td>" + key + "</td><td>" + feature.properties[key] + "</td></tr>";
            }
        }
        
        strPopup += "</table>";
        layer.bindPopup(strPopup);
    }
},
        });
    return this.selectedLayer;
  })
    );
  }

  createWadiLayer(apiUrl) {
    exports();
    // console.log(apiUrl);
    let liveData;

    return this.httpClient.get(apiUrl).pipe(
      map((res) => {
      liveData = res;
          this.selectedLayer = L.layerGroup();

          this.selectedLayer = L.geoJSON(liveData, {
                    style: function(feature){
              return {
                color: "#a36808",
                opacity: 2,
                weight: 2,
                fillColor: '#f5b245',
                fillOpacity: 0.6
              }
            },
            onEachFeature: function (feature, layer) {
            var strPopup = "<h4 class=\"backdrop\">" + feature.properties["nam"] + "</h4>"
            layer.bindPopup(strPopup);
            },
        });
    return this.selectedLayer;
  })
    );
  }

}