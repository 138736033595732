import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { NgxUiLoaderService } from "ngx-ui-loader";

// Service
import { OutageService } from '../services/outage.service';

@Component({
  selector: 'app-report-issue-form',
  templateUrl: './report-issue-form.component.html',
  styleUrls: ['./report-issue-form.component.css']
})
export class ReportIssueFormComponent implements OnInit {

  public sideMenu: any;
  public outageFormInvalid: boolean = false;
  sucMsg: string = '';

  constructor(
    private mapParamsFormFG: FormBuilder,
    private ngxService: NgxUiLoaderService,
    private outageService: OutageService,
  ) { }

    // Outage Form Creation
    public outageForm = this.mapParamsFormFG.group({
      state: ['', [Validators.required]],
      postcode: ['', [Validators.required, Validators.maxLength(4), Validators.minLength(4)]],
      text: [''],
      start_time: [new Date()]
    });

  ngOnInit(): void {
  }

  showMenu(){
    this.sideMenu = '0px';
  }

  hideMenu(){
    this.sideMenu = '-400px';
  }

  submitOutage(){     
    this.outageFormInvalid = this.outageForm.invalid ? true : false;     
    // console.log(this.outageForm.value);
    // return;
    if (this.outageForm.valid == true) {                    
      this.ngxService.start();
      this.outageService.publicPostOutageDetails(this.outageForm.value).subscribe(          
        (data) => this.onSuccess(data),
        (error) => this.handleError(error)
      );      
    }
    // console.log(this.outageForm.value);
  }

  onSuccess(res: any) {      
    this.ngxService.stop();
    this.outageForm.reset();
    this.sucMsg = 'Report sent successfully'
    // console.log(res);
    // this.ngOnInit();
    setTimeout(() => {          
      // location.reload();
      this.sucMsg = '';
      // this.sideMenu = '-400px';
    }, 3000);
  }

  handleError(res: any) {
    // alert("err");
    this.ngxService.stop();
    console.log(res);
  }

  get getoutageFormControl() { return this.outageForm.controls; }

}
