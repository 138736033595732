import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../app-config';
import { DatePipe } from '@angular/common';

import { map } from 'rxjs/operators';
import * as L from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  selectedLayer: any;

  constructor(private httpClient: HttpClient) {}

  public clientData = (): Observable<any> => {
    return this.httpClient.get<any>(AppConfig.client);
  };

  createEndavour(apiUrl) {
    let liveData;
    return this.httpClient.get(apiUrl).pipe(
      map((res) => {
        // AusGride Current outages
        this.selectedLayer = L.layerGroup();

      var geojsonEndeavourCurrentMarkerOptions = {
          radius: 8,
          fillColor: "#a2ebe8",
          color: "#000",
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8
      };
      var lineStyleEndeavourCurrent = {
          "color": "#f03",
          "weight": 1,
          "opacity": 0.65,
          "fillColor": '#f03',
          "fillOpacity": 0.5
      };
      var geojsonEndeavourCurrentPlannedMarkerOptions = {
          radius: 8,
          fillColor: "#af02fa",
          color: "#000",
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8
      };
      var lineStyleEndeavourPlannedCurrent = {
          "color": "#af02fa",
          "weight": 1,
          "opacity": 0.65,
          "fillColor": '#af02fa',
          "fillOpacity": 0.5
      };
      liveData = res;
        this.selectedLayer = L.geoJSON(liveData, {
          onEachFeature: this.onEachEndeavourCurrentFeature,
          pointToLayer: function (feature, latlng) {
              if (feature.properties.outageType == "U") {
                  return L.circleMarker(latlng, geojsonEndeavourCurrentMarkerOptions);
              }
              else {
                  return L.circleMarker(latlng, geojsonEndeavourCurrentPlannedMarkerOptions);
              }
          },
          style: function (feature) {
              if (feature.properties.outageType == "U") {
                  return lineStyleEndeavourCurrent;
              }
              else {
                  return lineStyleEndeavourPlannedCurrent;
              }
          }
      })
        // end AusGride Current outages
        return this.selectedLayer;
      })
    );
  }

  createEssentialCurrent(apiUrl) {
    let liveData;
    return this.httpClient.get(apiUrl).pipe(
      map((res) => {
        // AusGride Current outages
        this.selectedLayer = L.layerGroup();

        var geojsonEssCurrentMarkerOptions = {
          radius: 8,
          fillColor: "#a2ebe8",
          color: "#000",
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8
      };
      var lineStyleEssCurrent = {
          "color": "#f03",
          "weight": 1,
          "opacity": 0.65,
          "fillColor": '#f03',
          "fillOpacity": 0.5
      };
      var geojsonEssCurrentPlannedMarkerOptions = {
          radius: 8,
          fillColor: "#af02fa",
          color: "#000",
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8
      };
      var lineStyleEssPlannedCurrent = {
          "color": "#af02fa",
          "weight": 1,
          "opacity": 0.65,
          "fillColor": '#af02fa',
          "fillOpacity": 0.5
      };
      liveData = res;
        this.selectedLayer = L.geoJSON(liveData, {
          onEachFeature: this.onEachEssentialFeature,
          pointToLayer: function (feature, latlng) {
              if (feature.properties.type == "Unplanned") {
                  return L.circleMarker(latlng, geojsonEssCurrentMarkerOptions);
              }
              else {
                  return L.circleMarker(latlng, geojsonEssCurrentPlannedMarkerOptions);
              }
          },
          style: function (feature) {
              if (feature.properties.type == "Unplanned") {
                  return lineStyleEssCurrent;
              }
              else {
                  return lineStyleEssPlannedCurrent;
              }
          }
      })
        // end AusGride Current outages
        return this.selectedLayer;
      })
    );
  }

  createEssentialFuture(apiUrl) {
    let liveData;
    return this.httpClient.get(apiUrl).pipe(
      map((res) => {
        // AusGride Current outages
        this.selectedLayer = L.layerGroup();

        var geojsonEssCurrentMarkerOptions = {
          radius: 8,
          fillColor: "#a2ebe8",
          color: "#000",
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8
      };
      var lineStyleEssCurrent = {
          "color": "#f03",
          "weight": 1,
          "opacity": 0.65,
          "fillColor": '#f03',
          "fillOpacity": 0.5
      };
      var geojsonEssFutureMarkerOptions = {
        radius: 8,
        fillColor: "#f5e042",
        color: "#000",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8
    };
    var lineStyleEssFuture = {
        "color": "#f5e042",
        "weight": 1,
        "opacity": 0.65,
        "fillColor": '#f5e042',
        "fillOpacity": 0.5
    };

      liveData = res;
        this.selectedLayer = L.geoJSON(liveData, {
          onEachFeature: this.onEachEssentialFeature,
          pointToLayer: function (feature, latlng) {
              return L.circleMarker(latlng, geojsonEssFutureMarkerOptions);
          },
          style: lineStyleEssFuture
      })
        // end AusGride Current outages
        return this.selectedLayer;
      })
    );
  }

  onEachEssentialFeature(feature, layer) {
    if (feature.properties && feature.properties.description) {
        layer.bindPopup("<div class=\"essential\">" + feature.properties.description + "<div><span>Source:</span><a href=\"https://www.essentialenergy.com.au/outages-and-faults/power-outages\" style=\"font-size: 12px;\">Essential Energy Outage</a></div></div>" );
    }
  }

  onEachEndeavourCurrentFeature(feature, layer) {
    if (feature.properties) {
        var type = "Planned";
        if (feature.properties.outageType == "U") {
            type = "Unplanned";
        }
        var strPopup = "<h4 class=\"backdrop\">Endeavour " + type + " Outage</h4><table class=\"pws\">";
        if (feature.properties.reason != "") {
            strPopup = strPopup + "<tr><td>Cause: </td><td><b>" + feature.properties.reason + "</b></td></tr>";
        }
        if (feature.properties.status != "") {
            strPopup = strPopup + "<tr><td>Status: </td><td><b>" + feature.properties.status + "</b></td></tr>";
        }
        if (feature.properties.numberCustomerAffected != "") {
            strPopup = strPopup + "<tr><td>Customers Affected: </td><td><b>" + feature.properties.numberCustomerAffected + "</b></td></tr>";
        }
        if (feature.properties.endDateTime != "") {
            strPopup = strPopup + "<tr><td>Est. Restoration: </td><td><b>" + new DatePipe('en-US').transform(feature.properties.endDateTime, 'dd/MM/yyyy') + "</b></td></tr>";
        }
        if (feature.properties.suburb != "") {
            strPopup = strPopup + "<tr><td>Suburb: </td><td><b>" + feature.properties.suburb + ", " + feature.properties.postcode + "</b></td></tr>";
        }
        if (feature.properties.streetName != "") {
            strPopup = strPopup + "<tr><td>Streets: </td><td><b>" + feature.properties.streetName + "</b></td></tr>";
        }
	var sourceLink = "https://www.endeavourenergy.com.au/outages/current-power-outages";
	var sourceText = "Source";

	strPopup = strPopup + "<tr><td>" + sourceText + ": </td><td><b><a href=\"" + sourceLink + "\" style=\"font-size: 12px;\">Endeavour Outages</a></b></td></tr>";
        strPopup = strPopup + "</table>";
        layer.bindPopup(strPopup);
    }
  }

}
