import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

// Service
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public username: any;
  public password: any;
  mandatoryMessage: boolean = false;
  validationMessage: boolean = false;
  
  constructor(
    private loginFormGroup: FormBuilder,
    private commonService: CommonService,   
    private router: Router,
    private authService: AuthenticationService,
  ) { }

  public loginForm = this.loginFormGroup.group({
    userName: [''],
    password: ['']
  });

  ngOnInit(): void {
  }

  logIn() {
    this.username = this.loginForm.value.userName;
    this.password = this.loginForm.value.password;
    if (!this.username || !this.password) {
      this.validationMessage = false;
      this.mandatoryMessage = true;
    }
    else {
      this.authService.login(this.username, this.password).subscribe((res) => {         
      if (true) {
        if (true) {    
          this.commonService.clientData().subscribe((res) => {   
            if (res) {        
              // console.log(res)
              localStorage.setItem("client", JSON.stringify(res));
              this.router.navigateByUrl('/myMaps');
            } else {
              console.log("error in the response", res);
            }
          });                             
        }
        else {            
          this.mandatoryMessage = false;
          this.validationMessage = true;
        }
      }
      },
      error => {
        this.mandatoryMessage = false;
        this.validationMessage = true;
      });
    }
  }

}
