import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable, from, of } from 'rxjs';

import { map } from 'rxjs/operators';

import * as L from 'leaflet';

import * as esri from 'esri-leaflet';

@Injectable({
  providedIn: 'root'
})
export class PowerStationService {

  constructor(private httpClient: HttpClient) { }

  selectedLayer: any;
  pwrData: any;

  createPowerStationLayer(apiUrl) {
      return this.httpClient.get(apiUrl).pipe(
        map(
          res => {
            this.pwrData = res;
            var geojsonPWSMarkerOptions = {
              radius: 5,
              fillColor: "#f3ee67",
              color: "#000",
              weight: 1,
              opacity: 1,
              fillOpacity: 0.8
            };

            var lineStylePWS = {
              "color": "#eeeeee",
              "weight": 1,
              "opacity": 0.65,
              "fillColor": '#f3ee67',
              "fillOpacity": 0.5
            };

            this.selectedLayer = L.geoJSON(this.pwrData, {
              onEachFeature: this.onEachPWSFeature,
              pointToLayer: function (feature, latlng) {
                var marker: any;
                var icon = "pws8";
                if (feature.properties.pwsPrimaryFuel || feature.properties.pwsPrimarySubFuel) {
                  if (feature.properties.pwsPrimaryFuel === "Biogas") { icon = "pws1"; }
                  if (feature.properties.pwsPrimaryFuel === "Renewable/ Biomass / Waste") { icon = "pws1"; }
                  if (feature.properties.pwsPrimaryFuel === "Coal") { icon = "pws3"; }
                  if (feature.properties.pwsPrimarySubFuel === "Black Coal") { icon = "pws3"; }
                  if (feature.properties.pwsPrimarySubFuel === "Coal Seam Methane") { icon = "pws4"; }
                  if (feature.properties.pwsPrimaryFuel === "Compressed Natural Gas") { icon = "pws4"; }
                  if (feature.properties.pwsPrimarySubFuel === "Waste Coal Mine Gas") { icon = "pws4"; }
                  if (feature.properties.pwsPrimarySubFuel === "Diesel") { icon = "pws8"; }
                  if (feature.properties.pwsPrimaryFuel === "Distillate") { icon = "pws8"; }
                  if (feature.properties.pwsPrimaryFuel === "Fuel Oil") { icon = "pws8"; }
                  if (feature.properties.pwsPrimaryFuel === "Gas") { icon = "pws4"; }
                  if (feature.properties.pwsPrimarySubFuel === "Natural Gas") { icon = "pws4"; }
                  if (feature.properties.pwsPrimaryFuel === "Solar") { icon = "pws11"; }
                  if (feature.properties.pwsPrimaryFuel === "Hydro") { icon = "pws12"; }
                  if (feature.properties.pwsPrimaryFuel === "Wind") { icon = "pws13"; }

                  var n = 0;
                  var x = 0;
                  var scada = 0;
                  if (feature.properties.pwsScada != 0) {
                    scada = feature.properties.pwsScada.toFixed(1);
                  }
                  if (feature.properties.pwsGenerators != 0) {
                    n = feature.properties.pwsGenerators;
                    x = feature.properties.pwsGeneratorsOnline;
                  }
                  
                  var svgurl = "https://api.kartasoft.co/api/pws_svg?icon=" + icon + "&total=" + n + "&online=" + x;
                  
                  var pwsIcon = new L.Icon({
                    iconSize: [32, 37],
                    iconAnchor: [16, 32],
                    popupAnchor: [1, -12],
                    iconUrl: svgurl
                  });

                  marker = L.marker(latlng, { icon: pwsIcon });
                  return marker;
                }
              },
              style: lineStylePWS
            });
            return this.selectedLayer;
          }
      )
    );
  }

  onEachPWSFeature(feature, layer) {
  if (feature.properties) {
    var strPopup = "<h4 class=\"backdrop\">" + feature.properties.pwsName + "</h4><table class=\"pws\">";
    if (feature.properties.pwsTechnologyType != "") {
      strPopup = strPopup + "<tr><td>Type: </td><td><b>" + feature.properties.pwsTechnologyType + "</b></td></tr>";
    }
    if (feature.properties.pwsTechnologySubType != "") {
      strPopup = strPopup + "<tr><td>Sub Type: </td><td><b>" + feature.properties.pwsTechnologySubType + "</b></td></tr>";
    }
    if (feature.properties.pwsPrimarySubFuel != "") {
      strPopup = strPopup + "<tr><td>Fuel: </td><td><b>" + feature.properties.pwsPrimaryFuel + " (" + feature.properties.pwsPrimarySubFuel + ")</b></td></tr>";
    }
    else {
      strPopup = strPopup + "<tr><td>Fuel: </td><td><b>" + feature.properties.pwsPrimaryFuel + "</b></td></tr>";
    }
    if (feature.properties.pwsGenerators != 0) {
      strPopup = strPopup + "<tr><td>Generators: </td><td><b>" + feature.properties.pwsGenerators + "</b></td></tr>";
      strPopup = strPopup + "<tr><td>Generators Online: </td><td><b>" + feature.properties.pwsGeneratorsOnline + "</b></td></tr>";
      strPopup = strPopup + "<tr><td>Scada Value: </td><td><b>" + feature.properties.pwsScada.toFixed(2) + "</b></td></tr>";
    }



    if (feature.properties.pwsMaxCapacity != "") {
      strPopup = strPopup + "<tr><td>Max Capacity: </td><td><b>" + feature.properties.pwsMaxCapacity + " MW</b></td></tr>";
    }
    if (feature.properties.pwsRegCapacity != "") {
      strPopup = strPopup + "<tr><td>Reg Capacity: </td><td><b>" + feature.properties.pwsRegCapacity + " MW</b></td></tr>";
    }
            
    if (feature.properties.pwsMaxRampUpRate != "") {
      strPopup = strPopup + "<tr><td>Ramp Up Rate: </td><td><b>" + feature.properties.pwsMaxRampUpRate + "</b> (max) <b>" + feature.properties.pwsMinRampUpRate + "</b> (min)</td></tr>";
    }
    if (feature.properties.pwsMaxRampUpRate != "") {
      strPopup = strPopup + "<tr><td>Ramp Down Rate: </td><td><b>" + feature.properties.pwsMaxRampDownRate + "</b> (max) <b>" + feature.properties.pwsMinRampDownRate + "</b> (min)</td></tr>";
    }



    if (feature.properties.pwsOwner != "") {
      strPopup = strPopup + "<tr><td>Owner: </td><td><b>" + feature.properties.pwsOwner + "</b></td></tr>";
    }
    if (feature.properties.pwsMarket != "") {
      strPopup = strPopup + "<tr><td>Pricing: </td><td><b>" + feature.properties.pwsMarket + "</b></td></tr>";
    }
    strPopup = strPopup + "</table>";

    layer.bindPopup(strPopup);
  }
}
}
