<div class="row my-3">
    <h3>Dashboard</h3>
</div>
<div class="row mb-4 dashboard-top-card">
    <div class="col-lg-3">
        <div class="card p-3 card-bot-col-1">
            <p class="txt-dash-top">Planned Outages</p>
            <p class="mt-2"><span class="card-header-p">Total: </span><span>{{totalPlannedOutages}}</span></p>
        </div>
    </div>
    <div class="col-lg-3">
        <div class="card p-3 card-bot-col-2">
            <p class="txt-dash-top">Unplanned Outages</p>
            <p class="mt-2"><span class="card-header-u">Total: </span><span>{{totalUnPlannedOutages}}</span></p>
        </div>
    </div>
    <div class="col-lg-3">
        <div class="card p-3 card-bot-col-3">
            <p class="txt-dash-top">Reported Outages</p>
            <p class="mt-2"><span class="card-header-r">Total: </span><span>16</span></p>
        </div>
    </div>
    <div class="col-lg-3">
        <div class="card p-3 card-bot-col-4">
            <p class="txt-dash-top">Power Stations</p>
            <p class="mt-2"><span class="card-header-s">Total: </span><span>{{totalPowerstations}}</span></p>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-5">
        <div class="card">
            <div class="card-header">
                <div class="d-flex bd-highlight">
                    <div class="p-2 flex-grow-1 bd-highlight">Outages</div>
                    <div class="bd-highlight">
                        <select class="form-control" (change)="changeOutages($event)">
                            <option selected value="All">All</option>
                            <option value="essential-energy-future-outages">Essential Energy Future</option>
                            <option value="essential-energy-current-outages">Essential Energy Current</option>
                            <option value="ausgrid-energy-current-outages">Ausgrid Energy Current</option>
                            <option value="endeavour-energy-current-outages">Endeavour Energy Current</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="card-body d-flex justify-content-center">
                <div class="chart-container">
                    <canvas id="outages" height="400" width="400"></canvas>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-7">
        <div class="card">
            <div class="card-header">
                <div class="d-flex bd-highlight">
                    <div class="p-2 flex-grow-1 bd-highlight">Power Stations</div>
                    <div class="bd-highlight">
                        <!-- <select class="form-control" (change)="changeStations($event)"> -->
                        <!-- <select class="form-control" #t (change)="changeStations(t.value)">
                                <option *ngFor="let powerStations of powerLabel; let i = index" value="{{powerStations}}">{{powerStations}}</option>
                            </select> -->
                    </div>
                </div>
            </div>
            <div class="card-body d-flex justify-content-center">
                <div class="chart-container">
                    <canvas id="power_stations" height="400" width="400"></canvas>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-lg-4">
        <div class="card">
            <div class="card-header">
                <div class="d-flex bd-highlight">
                    <div class="p-2 flex-grow-1 bd-highlight">Power Stations</div>
                    <div class="bd-highlight">
                        <select class="form-control">
                            <option selected value="All">All</option>
                            <option value="true">Planned</option>
                            <option value="false">Un Planned</option>
                        </select>
                    </div>
                  </div>
              </div>
              <div class="card-body d-flex justify-content-center">
                <div div class="chart-container" style="position: relative; height:100%; width:100%">
                    <canvas id="sample"></canvas>
                </div>
              </div>
        </div>        
    </div>         -->
</div>
<div class="row mt-3" id="recent-outages" *ngIf="clientData.client.slug == 'test'">
    <div class="col-lg-12">
        <h5 class="mb-3">Live Data Tracker</h5>
        <div class="card">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">S.no</th>
                        <th scope="col">Map Type</th>
                        <!-- <th scope="col">Date</th> -->
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let log of logs;  index as keyVal">
                        <th scope="row">{{keyVal+1}}</th>
                        <td>{{log.name}}</td>  
                        <!-- <td>{{log?.created_at | date:'medium'}}</td>                   -->
                        <td>
                            <span class="badge rounded-pill bg-success" *ngIf="log.status">Live</span>
                            <span class="badge rounded-pill bg-danger" *ngIf="!log.status" tooltip="{{log.msg}}">Delayed/Failed</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="row mt-3" id="recent-outages">
    <div class="col-lg-12">
        <h5 class="mb-3">Recently Updated Outages</h5>
        <!-- <accordion [closeOthers]="oneAtATime">
            <accordion-group *ngFor="let item of recentOutage | keyvalue; index as keyVal"
                heading="Posta;l Code: {{item.key}}" class="mb-2" [isOpen]="keyVal == 0 ? true : false">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">S.no</th>
                            <th scope="col">STATE</th>
                            <th scope="col">POSTAL CODE </th>
                            <th scope="col">IP ADDRESS </th>
                            <th scope="col">TYPE</th>
                            <th scope="col">START</th>
                            <th scope="col">LIKELY END</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let tableData of item.value; index as i">
                            <th scope="row">{{i+1}}</th>
                            <th>{{tableData.state}}</th>
                            <td>{{tableData.postcode}}</td>
                            <td>{{tableData.meta.ip}}</td>
                            <td *ngIf="tableData?.is_reported == true"><span
                                    class="badge rounded-pill bg-danger">Reported</span></td>
                            <td *ngIf="tableData?.is_reported == false && tableData?.is_planned == true"><span
                                    class="badge rounded-pill bg-primary">Planned</span></td>
                            <td *ngIf="tableData?.is_reported == false && tableData?.is_planned == false"><span
                                    class="badge rounded-pill bg-secondary">Unplanned</span></td>
                            <td>{{tableData?.start_time | date:'medium'}}</td>
                            <td>{{tableData?.end_time | date: 'MMM dd, yyyy HH:mm'}}</td>
                        </tr>
                    </tbody>
                </table>
            </accordion-group>
        </accordion> -->

        <div class="accordion" id="accordionExample">
            <div class="accordion-item" *ngFor="let item of recentOutage | keyvalue; index as keyVal">
                <h2 class="accordion-header">
                    <div class="accordion-button" (click)="toggle(keyVal)" [ngClass]="{'opened-accordian': showRuleContent[keyVal] == true}">
                        <div class="col-lg-5">
                            <h6>Postal Code: {{item.key}}</h6>
                        </div>
                        <div class="col-lg-7 d-flex justify-content-end">
                            <span class="planned-o">
                                Planned: <span class="badge rounded-pill bg-primary">{{plannedCount(item.value)}}</span>
                            </span>
                            <span class="mloutages-20 unplanned-o">
                                Unplanned: <span class="badge rounded-pill bg-secondary">{{unPlannedCount(item.value)}}</span>
                            </span>
                            <span class="mloutages-20 reported-o">
                                Reported: <span class="badge rounded-pill bg-danger">{{reportedCount(item.value)}}</span>                            
                            </span>
                            <!-- <span class="mloutages-20">
                                Total Outages: <span class="badge rounded-pill bg-dark">{{reportedCount(item.value)+unPlannedCount(item.value)+plannedCount(item.value)}}</span>                            
                            </span> -->
                        </div>                                                
                    </div>
                </h2>
                <div class="accordion-collapse collapse show" *ngIf="showRuleContent[keyVal]">
                    <div class="accordion-body">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">S.no</th>
                                    <th scope="col">STATE</th>
                                    <th scope="col">POSTAL CODE </th>
                                    <th scope="col">IP ADDRESS </th>
                                    <th scope="col">TYPE</th>
                                    <th scope="col">START</th>
                                    <th scope="col">LIKELY END</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let tableData of item.value; index as i">
                                    <th scope="row">{{i+1}}</th>
                                    <th>{{tableData.state}}</th>
                                    <td>{{tableData.postcode}}</td>
                                    <td>{{tableData.meta.ip}}</td>
                                    <td *ngIf="tableData?.is_reported == true"><span
                                            class="badge rounded-pill bg-danger">Reported</span></td>
                                    <td *ngIf="tableData?.is_reported == false && tableData?.is_planned == true"><span
                                            class="badge rounded-pill bg-primary">Planned</span></td>
                                    <td *ngIf="tableData?.is_reported == false && tableData?.is_planned == false"><span
                                            class="badge rounded-pill bg-secondary">Unplanned</span></td>
                                    <td>{{tableData?.start_time | date:'medium'}}</td>
                                    <td>{{tableData?.end_time | date: 'MMM dd, yyyy HH:mm'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>