import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable, from, of } from 'rxjs';

import { map } from 'rxjs/operators';

import * as L from 'leaflet';

import * as esri from 'esri-leaflet';

declare let TopoJSON;


// import * as topojson from 'topojson-client';

@Injectable({
  providedIn: 'root'
})
export class TransmissionService {

  constructor(private httpClient: HttpClient) { }

  selectedLayer: any;
  transData: any;
  topData: any;

  createTransmissionLayer(apiUrl) {
    return this.httpClient.get(apiUrl).pipe(
      map(
        res => {
          this.topData = res;
        //   L.topojson = L.GeoJSON.extend({
        //     addData: function (res) {
        //       var geojson, key;
        //       if (res.type === "Topology") {
        //         for (key in res.objects) {
        //           if (res.objects.hasOwnProperty(key)) {
        //             geojson = topojson.feature(res, res.objects[key]);
        //             L.GeoJSON.prototype.addData.call(this, geojson);
        //           }
        //         }
        //         return this;
        //       }
        //       L.GeoJSON.prototype.addData.call(this, res);
        //       return this;
        //     }
        //   });
        //   const topoLayer = new L.TopoJSON();
        //   console.log("this.topData == ", this.topData);
        //   this.selectedLayer = L.topojson(null, {
        //     style: function(feature){
        //       return {
        //         color: "#990000",
        //         opacity: 1,
        //         weight: 2,
        //         fillColor: '#990000',
        //         fillOpacity: 0.8
        //       }
        //     },
        //     onEachFeature: this.onEachTransmissionFeature,
        // })




          // this.transData = res;
          // var topJsonData = topojson.feature(this.transData, this.transData.objects);
          // console.log("topJsonData == ", topJsonData);
          
          console.log(res, "res");
          console.log("this.selectedLayer == ", this.selectedLayer);
          return this.selectedLayer //this.selectedLayer;
        }
      )
    );
  }

  onEachTransmissionFeature(feature, layer) {
    if (feature.properties) {
      var strPopup = "<h4 class=\"backdrop\">" + feature.properties.NAME + "</h4><table class=\"pws\">";
      if (feature.properties.FEATURETYPE != "") {
        strPopup = strPopup + "<tr><td>Type: </td><td><b>" + feature.properties.FEATURETYPE + "</b> (" + feature.properties.CONSTRUCTIONTYPE + ")</td></tr>";
      }
      if (feature.properties.CAPACITY_kV != "") {
        strPopup = strPopup + "<tr><td>Capacity: </td><td><b>" + feature.properties.CAPACITY_kV + " kV</b></td></tr>";
      }
      if (feature.properties.STATUS != "") {
        strPopup = strPopup + "<tr><td>Status: </td><td><b>" + feature.properties.STATUS + "</b></td></tr>";
      }
      strPopup = strPopup + "</table>";
      layer.bindPopup(strPopup);
    }
  }

}
