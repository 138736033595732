import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

// import * as L from 'leaflet';

declare let L : any; //

import 'leaflet-rotatedmarker';


import 'leaflet.markercluster';

import { kml } from "@tmcw/togeojson";

// import * as WindBarb from '../../assets/windBarb.js';


@Injectable({
  providedIn: 'root'
})
export class BomService {

  constructor(private httpClient: HttpClient) { }

  selectedLayer: any;
  pwrData: any;

  createBomLayer(apiUrl) {
    exports();
    // console.log(apiUrl);
    let liveData;

    return this.httpClient.get(apiUrl).pipe(
      map((res) => {
        // AusGride Current outages
        this.selectedLayer = L.layerGroup();
        liveData = res;
        this.selectedLayer = L.geoJSON(liveData, {
        onEachFeature: this.BOM_FEAT,
        pointToLayer: function (feature, latlng) {
          if(feature.properties.wind_spd){
            var speed = feature.properties.wind_spd;
            var color = speed <= 5 ? '#469924' : speed >= 6 && speed <= 15 ? '#f3ed4d' : speed >= 15 && speed <= 25 ? '#ff9933' : speed >= 25 ? '#cc3300' : '#009933';
          }else{
            var speed = feature.properties.wind_gust_spd;
            var color = speed <= 5 ? '#469924' : speed >= 6 && speed <= 15 ? '#f3ed4d' : speed >= 15 && speed <= 25 ? '#ff9933' : speed >= 25 ? '#cc3300' : '#009933';
          }
          var ico = new L.WindBarb.icon({deg: feature.properties.wind_dir_deg, speed: feature.properties.wind_spd,fillColor: color, strokeColorPath: color, strokeColorCircle: color, pointRadius: 6});
          return L.marker(latlng, { icon: ico });
        },

        //   style: function (feature) {
        //     if (feature.properties.type == 'Unplanned') {
        //       return lineStyleAusCurrent;
        //     } else {
        //       return lineStyleAusPlannedCurrent;
        //     }
        //   },
        });
        // end AusGride Current outages
        return this.selectedLayer;
      })
    );

  }

  createBomPolyLayer(apiUrl) {
    // console.log(apiUrl);
    let liveData;

    var fireIcon = new L.Icon({
      iconSize: [20, 32],
      iconAnchor: [10, 32],
      popupAnchor: [1, -12],
      iconUrl: 'assets/images/fire2-40.png'
  });

    return this.httpClient.get(apiUrl).pipe(
      map((res) => {
        // AusGride Current outages
        this.selectedLayer = L.layerGroup();
        liveData = res;
        this.selectedLayer = L.geoJSON(liveData, {
        onEachFeature: this.BOM_FEAT_POLY,
        pointToLayer: function (feature, latlng) {
          return L.marker(latlng, {icon: fireIcon});
        }

        //   style: function (feature) {
        //     if (feature.properties.type == 'Unplanned') {
        //       return lineStyleAusCurrent;
        //     } else {
        //       return lineStyleAusPlannedCurrent;
        //     }
        //   },
        });
        // end AusGride Current outages
        return this.selectedLayer;
      })
    );

  }

  createBomRiverLayer(apiUrl) {
    // console.log(apiUrl);
    let liveData;

    var fireIcon = new L.Icon({
      iconSize: [20, 32],
      iconAnchor: [10, 32],
      popupAnchor: [1, -12],
      iconUrl: 'assets/images/fire2-40.png'
  });

    return this.httpClient.get(apiUrl).pipe(
      map((res) => {
        this.selectedLayer = L.layerGroup();
        liveData = res;
        this.selectedLayer = L.geoJSON(liveData, {
        onEachFeature: this.BOM_FEAT_RIVER,
        pointToLayer: function (feature, latlng) {
          return L.marker(latlng, {icon: fireIcon});
        }
        });
        return this.selectedLayer;
      })
    );

  }

  createBomFloodLayer(apiUrl) {
    // console.log(apiUrl);
    let liveData;
    
    var floodIconWhite = new L.Icon({
      iconSize: [28, 32],
      iconAnchor: [10, 32],
      popupAnchor: [1, -12],
      iconUrl: 'assets/images/bom_flood_white.png'
    });

    var floodIconYellow = new L.Icon({
      iconSize: [28, 32],
      iconAnchor: [10, 32],
      popupAnchor: [1, -12],
      iconUrl: 'assets/images/bom_flood_yellow.png'
    });

    var floodIconOrange = new L.Icon({
      iconSize: [28, 32],
      iconAnchor: [10, 32],
      popupAnchor: [1, -12],
      iconUrl: 'assets/images/bom_flood_orange.png'
    });

    return this.httpClient.get(apiUrl).pipe(
      map((res) => {
        this.selectedLayer = L.layerGroup();
        liveData = res;
        this.selectedLayer = L.geoJSON(liveData, {
        onEachFeature: this.BOM_FEAT_FLOOD,
        pointToLayer: function (feature, latlng) {
          if(feature.properties.severity == "Moderate"){
            return L.marker(latlng, {icon: floodIconYellow});
          }else if(feature.properties.severity == "Major"){
            return L.marker(latlng, {icon: floodIconOrange});
          }else{
            return L.marker(latlng, {icon: floodIconWhite});
          }          
        }
        });
        return this.selectedLayer;
      })
    );

  }

  BOM_FEAT(feature, layer) {
    var wind;
    if (feature.properties) {
        if(feature.properties.wind_spd_kmh) {
            wind = "Wind: " + feature.properties.wind_spd_kmh + " km/h (" + feature.properties.wind_spd + " kts)";
            if(feature.properties.wind_dir != 'CALM') {
                wind = wind + ", from " + feature.properties.wind_dir;
            }
            if(feature.properties.maximum_gust_kmh) {
                wind = wind + " <br />Gusting to: "+feature.properties.maximum_gust_kmh+" km/h"
            }
        }
        var rainfall_24hr = ""
        if(feature.properties.rainfall_24hr) {
            rainfall_24hr = " <br />Rainfall (24h to 9am): "+feature.properties.rainfall_24hr;
        }
        layer.bindPopup(
            "<p><b><h4 class=\'advice\'>"+feature.properties.description+"</h4>"+
            "<h5>"+feature.properties.bom_id+"</h5>"+wind+
            " <br />Temperature: "+feature.properties.air_temperature+"°C"+
            " <br />Rainfall (since 9am): "+feature.properties.rainfall+rainfall_24hr+
            " <br />Station Height: "+feature.properties.station_height+ "m above sea level</p>"
        );
    }
  }

  BOM_FEAT_POLY(feature, layer) {
    if (feature.properties) {

        layer.bindPopup("<p><h4 class=\'advice\'>"+feature.properties.title+"</h4>"+feature.properties.text+"</p>"
        ,{maxWidth: 560}

        );
    }
  }

  BOM_FEAT_RIVER(feature, layer) {
    if (feature.properties) {

        layer.bindPopup("<p><h4 class=\'advice\'>"+feature.properties.title+"</h4></p><br><p>"+feature.properties.main_txt+"</p>"
        ,{maxWidth: 560}

        );
    }
  }

  BOM_FEAT_FLOOD(feature, layer) {
    if (feature.properties) {

        layer.bindPopup("<p><h4 class=\'advice\'>"+feature.properties.description+"</h4><h5>"+feature.properties.warning_title+"\n"+feature.properties.sub_title+"</h5></p>\n\n<p>"+feature.properties.footer+"</p>"
        ,{maxWidth: 560}

        );
    }
  }
  createFlightsLayer(apiUrl) {
    let liveData;

    var planeIcon = new L.Icon({
      iconUrl: 'assets/images/plane2.png',
      iconSize:     [38, 38], // size of the icon
      iconAnchor:   [25, 16], // point of the icon which will correspond to marker's location
      popupAnchor:  [-5, -10] // point from which the popup should open relative to the iconAnchor
    });

    return this.httpClient.get(apiUrl).pipe(
      map((res) => {
        // AusGride Current outages
        this.selectedLayer = L.layerGroup();
        liveData = res;
        this.selectedLayer = L.geoJSON(liveData, {
          onEachFeature: function (feature, layer) {
              if (feature.properties && feature.properties.body) {
                layer.bindPopup(feature.properties.body);
              }
          },
          pointToLayer: function (feature, latlng) {
            return L.marker(latlng, {rotationAngle: feature.properties.trak, icon:planeIcon});
          }
        });
        return this.selectedLayer;
      })
    );
  }

  checkWindBarbColor(speed: number){
    console.log(speed);
    // var color = speed <= 5 ? '#009933' : speed <= 6 && speed >= 15 ? '#ffff00' : speed <= 15 && speed >= 25 ? '#ff9933' : speed >= 25 ? '#cc3300' : '#add8e6';
    return '#ffffff';
  }
}
