import { Directive, QueryList, ContentChildren, ElementRef, ContentChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { merge, Subject } from 'rxjs';
import { CheckBoxSelectGroupDirective } from './check-box-select-group.directive';
import { takeUntil } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[appCheckbBoxChildren]'
})
export class CheckbBoxChildrenDirective {

  @ContentChildren(NgControl, { descendants: true }) childrenControls: QueryList<NgControl>;
  @ContentChild(CheckBoxSelectGroupDirective) selectGroup: CheckBoxSelectGroupDirective;

  ngAfterContentInit() {
    this.selectGroup.checkChanges$.pipe(
      untilDestroyed(this)
    ).subscribe((checked) => {
      this.childrenControls.forEach(({ control }) => control.patchValue(checked));
    });

    const changes = this.childrenControls.map(({ control }) => control.valueChanges);
    
    merge(...changes).pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      // every is more readbale
      this.selectGroup.checked = this.childrenControls.toArray().every(({ control }) => control.value);
      // this.selectGroup.checked = !(this.controls.some(c => !c.control.value));
    })
  }

}
