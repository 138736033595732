import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable, from, of } from 'rxjs';

import { map } from 'rxjs/operators';

import * as L from 'leaflet';

import * as esri from 'esri-leaflet';

declare let TopoJSON;


// import * as topojson from 'topojson-client';

@Injectable({
  providedIn: 'root'
})
export class PipelinesService {

  constructor(private httpClient: HttpClient) { }

  selectedLayer: any;
  transData: any;
  topData: any;

  createPipeLineLayer(apiUrl) {
    return this.httpClient.get(apiUrl).pipe(
      map(
        res => {
          this.topData = res;
          this.selectedLayer = L.layerGroup();
          var st1 = {
                color: "#65b5cf",
                opacity: 2,
                weight: 2,
                fillColor: '#65b5cf',
                fillOpacity: 0.8
              };
          var st2 = {
                color: "#f44336",
                opacity: 2,
                weight: 2,
		dashArray: "5, 10",
                fillColor: '#f44336',
                fillOpacity: 0.8
              };

          this.selectedLayer = L.geoJSON(this.topData, {
                    style: function(feature){
		if (feature.properties.FEATURE_TYPE == "Gas pipeline") {
                    return st1;
                }
                else {
                    return st2;
                }
            },
            onEachFeature: function (feature, layer) {
	if (feature.properties) {
            if (feature.properties.NAME != "") {
                var strPopup = "<h4 class=\"backdrop\">" + feature.properties.NAME + "</h4><table class=\"pws\">";
            }
	    var FEATURE_TYPE = "Gas pipeline";
            if (feature.properties.FEATURE_TYPE != FEATURE_TYPE) {
                strPopup = strPopup + "<tr><td>Feature Type: </td><td><b>" + feature.properties.FEATURE_TYPE + "</td></tr>";
            }
            if (feature.properties.FEATURE_TYPE == FEATURE_TYPE) {
                strPopup = strPopup + "<tr><td>Feature Type: </td><td><b>" + FEATURE_TYPE + "</td></tr>";
            }
            if (feature.properties.LENGTH != null) {
                strPopup = strPopup + "<tr><td>Length (km): </td><td><b>" + feature.properties.LENGTH + "</td></tr>";
            }
            if (feature.properties.OBJECTID != null) {
                strPopup = strPopup + "<tr><td>Id: </td><td><b>" + feature.properties.OBJECTID + "</td></tr>";
            }
            if (feature.properties.SOURCE != null) {
                strPopup = strPopup + "<tr><td>Source: </td><td><b>" + feature.properties.SOURCE + "</td></tr>";
            }
            if (feature.properties.LICENSE != null) {
                strPopup = strPopup + "<tr><td>License: </td><td><b>" + feature.properties.LICENSE + "</td></tr>";
            }
            if (feature.properties.OPERATIONAL_STATUS != null) {
                strPopup = strPopup + "<tr><td>Operational Status: </td><td><b>" + feature.properties.OPERATIONAL_STATUS + "</td></tr>";
            }
            strPopup = strPopup + "</table>";
            layer.bindPopup(strPopup);
        }
},
        })


          
          console.log(res, "res");
          console.log("this.selectedLayer == ", this.selectedLayer);
          return this.selectedLayer //this.selectedLayer;
        }
      )
    );
  }


}
