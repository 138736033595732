<div class="downloads-preview mb-3">
    <div class="row my-3">
        <div class="col-lg-12 mb-3">
            <h3>Downloads</h3>
        </div>
        <form [formGroup]="downloadsForm">
            <div class="row my-3">
                <div class="col-lg-3">
                    <div class="form-group">
                        <label>Reports *</label>
                        <select formControlName="type"  class="form-select" placeholder="Select">
                            <option value="">Select Report</option>
                            <option value="fire">Fire</option>
                            <option value="floodWarning">BOM Flood warning</option>
                            <option value="outages">Outages</option>
                        </select>
                        <div *ngIf="submitted">
                            <span class="show-errors  mt-1" *ngIf="f.type.errors?.required">* Please Select Report</span>
                        </div>
                    </div>
                </div>

                <div class="col-xs-4 col-12 col-lg-3">
                    <label>Start date *</label>
                    <input type="text" placeholder="Select Date" (ngModelChange)="setNewDate()" class="form-control"
                        [minDate]="minDate" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', showWeekNumbers:false}" formControlName="start_date">
                        <div *ngIf="submitted">
                            <span class="show-errors mt-1" *ngIf="f.start_date.errors?.required">* Please Select Start date</span>
                        </div>
                </div>

                <div class="col-xs-4 col-12 col-lg-3">
                    <label>End date *</label>
                    <input type="text" placeholder="Select Date" class="form-control" [minDate]="minsDate"
                        [maxDate]="maxsDate" bsDatepicker [bsValue]="bsValue" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', showWeekNumbers:false}" formControlName="end_date">
                        <div *ngIf="submitted">
                            <span class="show-errors mt-1" *ngIf="f.end_date.errors?.required">* Please Select End date</span>
                        </div>
                </div>
                <div class="col-xs-4 col-12 col-lg-3">
                    <button (click)="downloadList()" class="btn btn-primary mt-4">Download</button>
                </div>
            </div>
        </form>


        <div class="row align-items-center  mt-4">
            <hr>
            <div class="col-lg-9">
                <h4>Reports Download History</h4>
            </div>
            <div class="col-lg-3">
                <button (click)="refreshList()" class="btn btn-primary delete-btn"><i
                        class="bi bi-arrow-clockwise me-1"></i>Refresh</button>
            </div>
        </div>
        <div  *ngIf="List?.length > 0">
            <div class="col-lg-3 mt-2">
                <form [formGroup]="downloadsFilterForm">
                    <div class="form-group">
                        <!-- <label>Filter by Name</label> -->
                        <select formControlName="filerByName" id="filerByName"(change)="filterTable($event.target.value)"class="form-select"
                            placeholder="Select">
                            <option value="">Select Report</option>
                            <option value="fire">Fire</option>
                            <option value="floodWarning">BOM Flood warning</option>
                            <option value="outages">Outages</option>
                            <!-- <option value="all">All</option>
                            <option *ngFor="let lists of filteresList" [value]="lists.name">{{lists.name}}</option> -->
                        </select>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-lg-12  mt-4">
            <table class="table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th (click)="sort('type')" scope="col">Request Name &nbsp;<i class="bi bi-chevron-expand"></i></th>
                        <th scope="col" (click)="sortbyDate('created_at')">Requested on  &nbsp;<i class="bi bi-chevron-expand"></i></th>
                        <th scope="col">Status</th>
                        <th scope="col">File</th>
                    </tr>
                </thead>
                <!-- {{List | json}} -->
                <tbody *ngIf="List?.length > 0">
                    <tr *ngFor="let lists of List">
                        <td *ngIf="lists?.type == 'fire'">Fire ({{lists?.start_date|date:"MMM d, Y"}} - {{lists?.end_date|date:"MMM d, Y"}})</td>
                        <td *ngIf="lists?.type == 'floodWarning'">BOM Flood warning ({{lists?.start_date|date:"MMM d, Y"}} - {{lists?.end_date|date:"MMM d, Y"}})</td>
                        <td *ngIf="lists?.type == 'outages'">Outages ({{lists?.start_date|date:"MMM d, Y"}} - {{lists?.end_date|date:"MMM d, Y"}})</td>
                        <td>{{lists?.created_at|date:"MMM d, Y"}}</td>
                        <td>{{lists?.status_string}}</td>
                        <td *ngIf="lists?.file == ''"></td>
                        <td *ngIf="lists?.file == null"></td>
                        <td *ngIf="!lists?.file == ''"><a class="d-btn" href="{{lists?.file}}">Download</a></td>
                    </tr>
                </tbody>
                <tbody *ngIf="List?.length == 0">
                    <tr>
                        <td colspan="8" class="text-center">No Data Found</td>
                    </tr>
                </tbody>
            </table>


        </div>
    </div>
</div>
