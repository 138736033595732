import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';

import * as L from 'leaflet';

import { kml } from "@tmcw/togeojson";

@Injectable({
  providedIn: 'root'
})
export class LiveOutageService {

  constructor(private httpClient: HttpClient) { }

  selectedLayer: any;
  pwrData: any;

  createLiveOutagesLayer(apiUrl) {
    let liveData;

    return this.httpClient.get(apiUrl).pipe(
      map(
        res => {
          liveData = res;
          this.selectedLayer = L.geoJSON(liveData, {
            onEachFeature: function onEachFeature(feature, layer) {
              if (feature.properties && feature.properties.description) {
                layer.bindPopup(feature.properties.description);
              }
            },
            style:<any> function style(featureStyle: any) {
              return {
                color: featureStyle.properties['stroke'],
                weight: featureStyle.properties['stroke-width'],
                opacity: featureStyle.properties['stroke-opacity'],
                fillColor: featureStyle.properties['fill'],
                fillOpacity: featureStyle.properties['fill-opacity']
              }
            }
          });
          return this.selectedLayer;
        }
      )
    );
  }

  createKMLLayer(kmlURL) {
    console.log("kmlURL == ", kmlURL);
    return this.httpClient.get(kmlURL, { observe: 'body', responseType: 'text' });
  }
}
