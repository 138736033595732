import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreviousRouteService {

  constructor() { }

  private messageSource = new BehaviorSubject<any>('');
  currentMessage = this.messageSource.asObservable();

  sendMessage(passedData: any) {    
    this.messageSource.next(passedData);
  }

  private updateMyMaps = new BehaviorSubject<any>('');
  currentMapStatus = this.updateMyMaps.asObservable();

  reloadMyMap(mapData: any) {   
    this.updateMyMaps.next(mapData);
  }

}