import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';

import { environment } from '../../environments/environment';

import { NgxUiLoaderService } from "ngx-ui-loader";
import { combineLatest, forkJoin, Observable } from 'rxjs';

import { reduce, map } from 'rxjs/operators';

import * as L from 'leaflet';
import { Map } from 'leaflet';

//import * as leaftletKML from 'leaflet-kml';

//import * as jsonQuery from 'json-query';

import { filter, pairwise } from 'rxjs/operators';

import { FireServiceService } from '../services/fire-service.service';

// Services
import { PipelinesService } from '../services/pipelines.service';
import { AusgrideService } from '../services/ausgride.service';
import { PreviousRouteService } from '../services/previous-route.service';
import { MapService } from '../services/map.service';
import { OutageService } from '../services/outage.service';

@Component({
  selector: 'app-view-public-map',
  templateUrl: './view-public-map.component.html',
  styleUrls: ['./view-public-map.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ViewPublicMapComponent implements OnInit {
  previousUrl: string;
  showMap: boolean = false;
  showReportForm: boolean = false;
    constructor(private mapParamsFormFG: FormBuilder,
      private outageService: OutageService,
      private location: Location,
      private mapService: MapService,
      private ngxService: NgxUiLoaderService,
      private activatedRoute: ActivatedRoute,
      private httpClient: HttpClient,
      private router: Router,
      private previousRouteService:PreviousRouteService,
      public _fireservice: FireServiceService,
        private _pipelines: PipelinesService,
        private _ausGrideservice: AusgrideService) { }

      fileToUpload: any = '';
      enableCreate: boolean = true;
      getMapParams: any = {};
      editMapInfoForm: FormGroup;
      public outageFormInvalid: boolean = false;
      public sideMenu: any;
      clientData = JSON.parse(localStorage.getItem("client"));

      fromMymaps: any;
      //dropdown
      isCollapsed = false;
      incidentTypes: any = [];
      selectedIncidents = [];
      mapViewList: any = [];
      selectedMapView = [];
      mapTypeList: any = [];
      selectedMapType= [];
      mapAction: any;

      ngOnInit(): void {
        this.ngxService.start();
          this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
            // console.log(params);
            // this.location.replaceState("/pubic-map/"+params.get("id"));
              // this.fromMymaps = params.get("id");
              // console.log('list item data', params.get("id"));
                  // Get Map Details

            this.mapService.getSingleMapList(params.get("slugName")).subscribe((res) => {
              if (res) {
                this.showMap = true;
                if(res.meta.incidentTypes.map( item => { return item.slug }).indexOf('live-outages') !== -1) {
                  this.showReportForm = true;
                }
                this.getMapParams = {
                  "incidentTypes": res.data_types,
                    "mapType": "map box",
                    "logo": (res.meta.logo == "" ? environment.baseUrl + this.clientData.client.logo : res.meta.logo),
                    "headerBg": res.meta.headerBg,
                    "mapViewTypes": res.meta.mapViewTypes
                  }
                // this.ngxService.stop();
              } else {
                console.log("error in the response", res);
              }
            });
          });

        //data for dropdown
          this.incidentTypes = [
              { index: 1, id: 'Fire', name: 'Fire', img: '', type: 'Fire Incidents Data', isActive: true },
              { index: 2, id: 'Ausgrid',  name: 'Ausgrid', img: '', type: 'Outage Data', isActive: true },
              { index: 3, id: 'Endevour',  name: 'Endevour', img: '', type: 'Outage Data', isActive: true },
              { index: 4, id: 'Essential',  name: 'Essential', img: '', type: 'Outage Data', isActive: true },
              {
                  index: 5, id: 'Water', name: 'Water', img: '', type: 'Pipe Line',
                  isActive: true
              },
              { index: 6, id: 'GasandOil',  name: 'Gas and Oil', img: '', type: 'Pipe Line', isActive: true }
          ];
          this.selectedIncidents = ["fire-incidents", "ausgrid-energy-current-outages", "gas-pipelines"];

          this.mapViewList = [
              { value: "Terrain", label: 'Terrain' },
              { value: "Street", label: 'Street' },
              { value: "Satillite", label: 'Satillite' }
          ];

          this.mapTypeList = [
              { value: 'Google Map', label: 'Google Map', disabled: true },
              { value: 'MapBox', label: 'MapBox' },
              { value: 'Open Street Map', label: 'Open Street Map', disabled: true }
          ];

        this.editMapInfoForm = this.mapParamsFormFG.group({
            description: [""],
            mapName: ["", Validators.required],
            incidentTypeList: [null, [Validators.required]],
            mapView: [null, [Validators.required]],
            mapType: [null, [Validators.required]],
            logo: new FormControl('', Validators.required),
            headerBg: ["#4a7d9e"],
        });
    }

    onSelectFile(event) {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => { // called once readAsDataURL is completed
          this.fileToUpload = event.target.result;
        }
      }
    }

    generateMap(): void {
          // console.log(this.editMapInfoForm.value);
    }

    onMapViewChange($event: any) {
      this.selectedMapView = $event;
    }

  }
