import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OutageService } from '../services/outage.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService

@Component({
  selector: 'app-outage-report',
  templateUrl: './outage-report.component.html',
  styleUrls: ['./outage-report.component.css']
})
export class OutageReportComponent implements OnInit {

  @ViewChild('template') myModal : any;

  public outagesList: any;
  public outageAllData: any;
  modalRef?: BsModalRef;
  is_planned: any;
  public outageFormInvalid: boolean = false;
  public filterOutage = "All";
  editOutageId: any;
  editOutageModal: boolean = false;
  checkedTickets = [];
  viewOutage: boolean = true;

  constructor(
    private ngxService: NgxUiLoaderService,
    private outageService: OutageService,
    private modalService: BsModalService,
    private outageFormGroup: FormBuilder,
  ) { }

  // Outage Form Creation
  public outageForm = this.outageFormGroup.group({
    state: ['', [Validators.required]],
    postcode: ['', [Validators.required, Validators.maxLength(4), Validators.minLength(4)]],
    text: [''],
    start_time: [new Date().toISOString().substring(0, 16), [Validators.required]],
    end_time: [''],
    is_reported: [''],
    duration: ['', [Validators.required]],
    is_planned: ['', Validators.required],
  });

  ngOnInit(): void {
    this.ngxService.start();
    // Get Outage Details
    this.outageService.outageList().subscribe((res) => {   
      if (res) {
        this.ngxService.stop();
        this.outageAllData = res;
        this.outagesList = res;
        this.is_planned = 'true';
      } else {
        console.log("error in the response", res);
      }
    });

  }

  openModal(source) {
    this.editOutageModal = source == 'edit' ? true : false; 
    this.editOutageModal == false ? this.outageForm.reset(): '';    
    this.modalService.show(this.myModal, { class: 'modal-lg' });
  }

  closeModel(){
    this.modalService.hide();
  }

  submitOutage(){    
    this.outageFormInvalid = this.outageForm.invalid ? true : false; 
    const formValues = this.outageForm.controls;
    // this.outageForm.controls.start_time.setValue(formValues.date.value+'T'+formValues.time.value);
    this.outageForm.controls.is_reported.setValue(false); 
    this.outageForm.controls.end_time.setValue(formValues.start_time.value)
    // console.log(this.outageForm.value);
    // return;
    if (this.outageForm.valid == true) {   
      this.ngxService.start();                 
      this.outageService.postOutageDetails(this.outageForm.value).subscribe(
        (data) => this.onSuccess(data),
        (error) => this.handleError(error)
      );      
    }
    // console.log(this.outageForm.value);
  }

  editOutageForm(){
    this.outageFormInvalid = this.outageForm.invalid ? true : false; 
    const formValues = this.outageForm.controls;
    // this.outageForm.controls.start_time.setValue(formValues.date.value+'T'+formValues.time.value); 
    this.outageForm.controls.end_time.setValue(formValues.start_time.value);
    // console.log(this.outageForm.value);
    // return;
    if (this.outageForm.valid == true) {   
      this.ngxService.start();                 
      this.outageService.editOutageDetails(this.outageForm.value, this.editOutageId).subscribe(
        (data) => this.onSuccess(data),
        (error) => this.handleError(error)
      );      
    }
    // console.log(this.outageForm.value);
  }

  onSuccess(res: any) {
    this.ngxService.stop();
    // console.log(res);
    this.modalService.hide();
    this.ngOnInit();
  }

  handleError(res: any) {
    this.ngxService.stop();
    console.log(res);
  }

  clearOutage(id){    
    if(confirm("Are you sure to clear")) {
      this.ngxService.start();
      this.outageService.clearOutageDetails(id).subscribe(
        (data) => {
          this.ngxService.stop();
          this.ngOnInit();
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  editOutage(outageDetails, fromSrc){    
    if(fromSrc == 'view'){
      this.viewOutage = false;
      this.outageForm.disable();
    }else{
      this.viewOutage = true;
      this.outageForm.enable();
    }
    this.editOutageId = outageDetails.id;    
    // alert(outageDetails.is_planned)
    this.is_planned = outageDetails.is_planned;
    this.outageForm.patchValue({
      state: outageDetails.state,
      postcode: outageDetails.postcode,
      is_planned: outageDetails.is_planned,
      is_reported: outageDetails.is_reported,
      start_time: new Date(outageDetails.start_time).toISOString().substring(0, 16),
      duration: outageDetails.duration,
      text: outageDetails.text,
    });
    this.openModal('edit');
    console.log(outageDetails);
  }

  filterTable(searchValue){    
    const filter = searchValue == 'reported' ? true :searchValue == 'planned' ? true : searchValue == 'unplanned' ? true : searchValue == 'all' ? true : false;
    // alert(filter);
    // return;
    if(filter){
      if(searchValue == 'reported'){
        // alert(searchValue);
        this.outagesList = this.outageAllData.filter(function(hotel){
          return hotel.is_reported == true;              
        });
        // console.log('asdasdasd',this.outageAllData.filter(function(scope) {scope.is_reported = true;}));
      }else if(searchValue == 'planned'){
        // alert(searchValue);
        this.outagesList = this.outageAllData.filter(function(hotel){
          return hotel.is_reported == false && hotel.is_planned == true;
        });
      }else if(searchValue == 'unplanned'){
        // alert(searchValue);
        this.outagesList = this.outageAllData.filter(function(hotel){
          return hotel.is_reported == false && hotel.is_planned == false;
        });
      }else if(searchValue == 'all'){
        // alert(searchValue);
        this.outagesList = this.outageAllData;
      }
      return;
    }
    this.outagesList = this.outageAllData.filter(i => i.postcode.includes(searchValue.toLowerCase()) 
   || i.state.toLowerCase().includes(searchValue.toLowerCase()));
  }


  onCheck(evt) {
    if (!this.checkedTickets.includes(evt)) {
      this.checkedTickets.push(evt);
    } else {
      var index = this.checkedTickets.indexOf(evt);
      if (index > -1) {
        this.checkedTickets.splice(index, 1);
      }
    }
    // console.log(this.checkedTickets);
  }

  deleteAll(){
    this.ngxService.start();
    for(var i =0; i < this.checkedTickets.length; i++) {
      this.outageService.clearOutageDetails(this.checkedTickets[i]).subscribe(
        (data) => {
          this.ngxService.stop();
          this.ngOnInit();
          this.checkedTickets.length = 0;
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  refresh(){    
    this.ngOnInit();
  }

  get getoutageFormControl() { return this.outageForm.controls; }

}
