import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';



import { AppConfig } from './app-config';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    // private firebaseAuth: AngularFireAuth
    ) {
  }
  private loggedIn: boolean = false;
  public loggedRole: string;
  public setCurrentRole: string;
  public fireBaseIDToken: string;

  login(uName, Upassword) {
    var pwd = Upassword;
    var username = uName;
    localStorage.setItem('username', uName);
    return this.http.post<any>(AppConfig.createToken, { 'username': username, 'password': pwd }).pipe(
      map(
        userData => {
          var user = userData;
          this.loggedIn = true;
          this.loggedRole = user;
          localStorage.setItem('token', "Token " + user.token);
          return user;
        }
      )
    );
  }



  getToken() {
    return localStorage.getItem('token');
  }

  getClient() {
    return window.location.hostname.split('.')[0];
  }

  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('token');
    return (authToken !== null) ? true : false;
  }

  loggedOut() {
    localStorage.clear();
    sessionStorage.clear();
  }

}
