<div class="container-fluid" style="overflow: hidden;">
    <div class="map-preview public-map mb-3">
        <div class="row m-0 shadow-lg" [style.background-color]="getMapParams.headerBg">
            <div class="col-xs-12 col-lg-12 p-0" style="height: 60px;">
                <app-report-issue-form *ngIf="showReportForm"></app-report-issue-form>                
                <img [src]="getMapParams.logo" height="60">
            </div>
            <!-- <div class="col-xs-12 col-lg-6 d-flex justify-content-end align-items-center">
                <div class="mr-5">
                    <a class="text-light m-r-5" href="javascript:void(0)" > About </a>
                </div>
                <div class="ml-5">
                    <a class="text-light m-l-5" href="javascript:void(0)" > Legends </a>
                </div>
            </div> -->
        </div>        
        <div class="row">            
            <div class="col-lg-12">
                <app-fireline [mapDetails]="getMapParams" *ngIf="showMap"></app-fireline>
            </div>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>