<div class="container">
<div class="row">
    <div class="col-4 m-auto">
        <div class="text-center">
            <img class="logo_kartasoft" src="../../assets/images/logo_kartasoft.png" />
        </div>
        <div class="card mt-5">
            <div class="card-body">
                <h5 class="card-title text-center">Login</h5>
                <form [formGroup]="loginForm" (ngSubmit)="logIn()" novalidate class="text-center">
                    <div class="form-group">
                        <label class="form-label" for="useName"></label>
                        <input type="text" class="form-control" placeholder="User name" formControlName="userName"
                            autocomplete="off">
                    </div>
                    <div class="form-group mt-2">
                        <label class="form-label" for="password"></label>
                        <input type="password" class="form-control" placeholder="Password" formControlName="password"
                            autocomplete="off">
                    </div>
                    <button type="submit" class="btn btn-primary mt-4">Login</button>
                </form>
                <div *ngIf="mandatoryMessage" class="warningMessage text-center text-danger mt-2">
                    Please Enter User Name and Password.
                </div>
                <div *ngIf="validationMessage" class="warningMessage text-center text-danger mt-2">
                    Invalid User Name or Password.
                </div>
            </div>
        </div>
    </div>
</div>
</div>