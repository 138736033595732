<div class="map-preview mb-3">
  <form [formGroup]="historicalForm">
    <div class="row my-3">
      <div class="col-lg-12 mb-3">
        <h3>
          Historical Maps
          <i
            class="fa fa-info-circle"
            aria-hidden="true"
            tooltip="Shows the saved map data for a given date"
            style="margin-left: 8px"
          ></i>
        </h3>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <ng-select formControlName="mapName" placeholder="Select map">
            <ng-option *ngFor="let list of mapList" [value]="list">{{
              list.name
            }}</ng-option>
          </ng-select>
          <!-- <ng-select [items]="mapList" placeholder="Select Map"></ng-select> -->
        </div>
      </div>
      <div class="col-xs-4 col-12 col-md-4 form-group">
        <input
          type="text"
          placeholder="Select Date"
          class="form-control"
          bsDatepicker
          [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', showWeekNumbers: false }"
          formControlName="date"
        />
      </div>
      <div class="col-lg-4">
        <button
          class="btn btn-primary mt-1"
          style="margin-right: 10px"
          (click)="showMapDetails()"
        >
          Get Map
        </button>
      </div>
      <div class="col-lg-12">
        <span class="msg-error" *ngIf="!historicalForm.valid && showValidation"
          >Please select the map and date</span
        >
      </div>
    </div>
  </form>
  <div
    class="row mt-3 m-0 shadow-lg"
    [style.background-color]="
      getMapParams.headerBg ? getMapParams.headerBg : clientData.client.color
    "
  >
    <app-report-issue-form
      *ngIf="!isCollapsed && showReportForm"
    ></app-report-issue-form>
    <div class="col-xs-12 col-lg-12 p-0">
      <div class="client-logo-pan">
        <img
          [src]="
            getMapParams.logo
              ? getMapParams.logo
              : 'https://api.kartasoft.co/' + clientData.client.logo
          "
          height="60"
        />
      </div>
      <!-- <div class="client-logo-pan" *ngIf="!getMapParams.logo">
        <img
          [src]="'https://api.kartasoft.co/' + clientData.client.logo"
          height="60"
        />
      </div> -->
    </div>
    <!-- <div class="col-xs-12 col-lg-6 d-flex justify-content-end align-items-center">
        <div class="mr-5">
            <a class="text-light m-r-5" href="javascript:void(0)" > About </a>
        </div>
        <div class="ml-5">
            <a class="text-light m-l-5" href="javascript:void(0)" > Legends </a>
        </div>
    </div> -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <app-fireline [mapDetails]="getMapParams" *ngIf="showMap"></app-fireline>
    </div>
  </div>
</div>
