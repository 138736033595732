import { Component, OnInit, HostListener } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {

  public shouldShow = true;
  display = "none";
  clientData = JSON.parse(localStorage.getItem("client"));

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit(): void {
  }

  logOut(): void {
    // alert("logout");
    this.authService.loggedOut();    
    if (this.router) {
      this.router.ngOnDestroy();
    }
    window.location.href = environment.platformUrl;
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    // if (this.viewportScroller.getScrollPosition()[1] > 66) {
    //   // console.log("menu show");
    //   this.shouldShow = false;
    // } else {
    //   // console.log("menu hide");
    //   this.shouldShow = true;
    // }
  }

  openModal() {
    this.display = "block";
  }
  onCloseHandled() {
    this.display = "none";
  }

}