import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../app-config';

@Injectable({
  providedIn: 'root'
})

export class DownloadsService {

  constructor(private httpClient: HttpClient) { }

 
  public getdownloadsList = (): Observable<any> => {
    return this.httpClient.get<any>(AppConfig.downloads);
  }

  public DownloadListService = (Reportdata): Observable<any> => {
    return this.httpClient.post<any>(AppConfig.downloads, Reportdata );
  }
  // public DownloadListService = (list,dateFrom,dateTo,name): Observable<any> => {
  //   return this.httpClient.get<any>(AppConfig.downloads+"/?requestList="+list+"?&dateFrom="+dateFrom+"?&dateTo="+dateTo+"?&name="+name);
  // }
  
}
