import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable, from, of } from 'rxjs';

import { map } from 'rxjs/operators';

import * as L from 'leaflet';

import * as esri from 'esri-leaflet';

declare let TopoJSON;


// import * as topojson from 'topojson-client';

@Injectable({
  providedIn: 'root'
})
export class TransmissionService3 {

  constructor(private httpClient: HttpClient) { }

  selectedLayer: any;
  transData: any;
  topData: any;
  

  createTransmissionLayer3(apiUrl) {
    return this.httpClient.get(apiUrl).pipe(
      map(
        res => {
          this.topData = res;
          this.selectedLayer = L.layerGroup();
          var myRenderer = L.canvas({ padding: 0.5 });    

          this.selectedLayer = L.geoJSON(this.topData, {
               pointToLayer: function (geoJsonPoint, latlng) {
    return L.circleMarker(latlng, {
      renderer: myRenderer,
      radius: 8,
      fillColor: '#37ff00',
      color: '#000',
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8
    });

            },
            onEachFeature: function (feature, layer) {
        if (feature.properties) {
            var strPopup = "<h4 class=\"backdrop\">" + feature.properties.substnname + "</h4><table class=\"pws\">";
            if (feature.properties.cabletype != "") {
                strPopup = strPopup + "<tr><td>Type: </td><td><b>" + feature.properties.assettype + "</td></tr>";
            }
            if (feature.properties.owner != "") {
                strPopup = strPopup + "<tr><td>Owner: </td><td><b>" + feature.properties.owner + "</td></tr>";
            }
            if (feature.properties.voltage != "") {
                strPopup = strPopup + "<tr><td>Capacity: </td><td><b>" + feature.properties.voltage + " kV</b></td></tr>";
            }
            if (feature.properties.loaddate != "") {
                strPopup = strPopup + "<tr><td>Date: </td><td><b>" + feature.properties.loaddate + "</b></td></tr>";
            }
            if (feature.properties.STATUS != "") {
                strPopup = strPopup + "<tr><td>Status: </td><td><b>" + "Operational" + "</b></td></tr>";
            }
            strPopup = strPopup + "</table>";
            layer.bindPopup(strPopup);
        }
} 
        })


          
          console.log(res, "res");
          console.log("this.selectedLayer == ", this.selectedLayer);
          return this.selectedLayer //this.selectedLayer;
        }
      )
    );
  }

}
