import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../app-config';

@Injectable({
  providedIn: 'root'
})
export class OutageService {

  constructor(private httpClient: HttpClient) { }

  public outageList = (): Observable<any> => {
    return this.httpClient.get<any>(AppConfig.outages);
  }

  public postOutageDetails = (outagesData): Observable<any> => {
    return this.httpClient.post<any>(AppConfig.outages, outagesData );
  }

  public editOutageDetails = (outagesData, outageId): Observable<any> => {
    return this.httpClient.put<any>(AppConfig.outages+outageId+'/', outagesData );
  }

  public publicPostOutageDetails = (outagesData): Observable<any> => {
    return this.httpClient.post<any>(AppConfig.publicOutages, outagesData );
  }

  public clearOutageDetails = (id): Observable<any> => {           
    return this.httpClient.delete<any>(AppConfig.outages+id);
  }

}
