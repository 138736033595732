import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DownloadsService } from '../services/downloads.service';
import { map } from 'rxjs/operators';
import { create } from 'domain';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report-downloads',
  templateUrl: './report-downloads.component.html',
  styleUrls: ['./report-downloads.component.css']
})
export class ReportDownloadsComponent implements OnInit {

  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate: any;
  minDate = new Date();
  List: any = [];
  minsDate: any;
  maxsDate: any
  isStartDateUpdated = false
  filteresList: any[];
  submitted: boolean = false

  constructor(
    private ngxsService: NgxUiLoaderService,
    private downloadsFromGroup: FormBuilder,
    public datepipe: DatePipe,
    private DownloadsService: DownloadsService,
    private _router: Router,

  ) {
    this.minDate = new Date('01-01-2023');
  }
  // Outage Form Creation
  public downloadsForm = this.downloadsFromGroup.group({
    type: ['', [Validators.required]],
    start_date: ['', [Validators.required]],
    end_date: ['', [Validators.required]],
  });


  ngOnInit(): void {
    // Get downloads Details
    this.ngxsService.start();
    this.DownloadsService.getdownloadsList().subscribe((res) => {
      if (res) {
        console.log(res);
        this.List = res;
        this.filteresList = res;

        this.ngxsService.stop();
      } else {
        console.log("error in the response", res);
      }

      // Disable end date onload
      let ctrl = this.downloadsForm.get('end_date')
      ctrl.enabled ? ctrl.disable() : ctrl.enable
    });
  }
  setNewDate() {
    // set end date value for 2 weeks
    let start_date = this.downloadsForm.get('start_date').value;
    this.minsDate = new Date(start_date)
    let start = start_date.getDate() + 14;
    let year = start_date.getFullYear();
    let month = start_date.getMonth();
    this.maxsDate = new Date(year, month, start)
    // enable end date
    let ctrl = this.downloadsForm.get('end_date')
    ctrl.disabled ? ctrl.enable() : ctrl.disable
    // set start date value for end date
    this.bsValue = start_date 
  }
  downloadList() {
    this.submitted = true;
    if (this.downloadsForm.invalid) {
      return;
    }
    let s_date = this.downloadsForm.get('start_date').value;
    let e_date = this.downloadsForm.get('end_date').value;
    let dateFrom = this.datepipe.transform(s_date, 'yyyy-MM-dd');
    let dateTo = this.datepipe.transform(e_date, 'yyyy-MM-dd');
    this.downloadsForm.patchValue({
      start_date: dateFrom,
      end_date: dateTo
    });
    this.ngxsService.start();
    this.DownloadsService.DownloadListService(this.downloadsForm.value).subscribe((res) => {
      if (res) {
        console.log(res);
        this.List = res;
        this.filteresList = res;
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
        this.ngxsService.stop();
      } else {
        console.log("error in the response", res);
      }
  });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.downloadsForm.controls;
  }
  // Outage Form Creation
  public downloadsFilterForm = this.downloadsFromGroup.group({
    filerByName: [''],
  });

  filterTable(list: any) {
    // let list = this.downloadsForm.get('selectList').value;
    // let start_date = this.downloadsForm.get('start_date').value;
    // let end_date = this.downloadsForm.get('end_date').value;
    // let dateFrom = this.datepipe.transform(start_date, 'dd-MM-yyyy');
    // let dateTo = this.datepipe.transform(end_date, 'dd-MM-yyyy');
    // let name = this.downloadsFilterForm.get('filerByName').value;
    if(list ==  ''){
      this.List = this.filteresList;
      return;
    }
    this.List.forEach((type) => {
      const filter = type == list;
      if (filter) {
        if (list == type) {
          this.List = this.filteresList.filter(function () {
          });
        }
        return;
      }
      this.List = this.filteresList.filter(i => i.type.includes(list.toLowerCase())
        || i.type.toLowerCase().includes(list.toLowerCase()));
    });
  }
  refreshList() {
    this.ngxsService.start();
    this.DownloadsService.getdownloadsList().subscribe((res) => {
      if (res) {
        console.log(res);
        this.List = res;
        this.filteresList = res;
        this.downloadsFilterForm.patchValue({
          filerByName: [''],
        });
        this.ngxsService.stop();
      } else {
        console.log("error in the response", res);
      }
    });
  }

  isDesc: boolean = false
  sort(name: any) {
    this.isDesc = !this.isDesc
    let direction = this.isDesc ? 1 : -1;
    this.List.sort(function (a, b) {
      if (a[name] < b[name]) {
        return -1 * direction
      } else if (a[name] > b[name]) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }

  sortbyDate(created_at: any) {
    this.isDesc = !this.isDesc
    let direction = this.isDesc ? 1 : -1;
    this.List.sort(function (a, b) {
      if (a[created_at] < b[created_at]) {
        return -1 * direction
      } else if (a[created_at] > b[created_at]) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }
}
