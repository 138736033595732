import { environment } from '../environments/environment';

export const AppConfig = {

    createToken: environment.baseUrl + 'api-token-auth/',
    outages: environment.baseUrl + 'outages/',
    publicOutages: environment.baseUrl + 'report-outage/',
    users: environment.baseUrl + 'users/',
    maps: environment.baseUrl + 'maps/',
    templates: environment.baseUrl + 'templates/',
    client: environment.baseUrl + 'me/',
    dashboardData: environment.baseUrl + 'stats-track/',
    downloads: environment.baseUrl + 'report/',

}